import React, {useState} from "react";
import {Table} from "react-bootstrap";
import {makeStyles, TablePagination} from "@material-ui/core";
import {BiSort, BiSortUp, BiSortDown} from "react-icons/bi";


const useStyles = makeStyles({
    tableHead: {
        opacity: "0.8",
        userSelect: "none",
        cursor: "pointer",
        "&:hover": {
            opacity: "unset",
        }
    },
    tableHeadSelected: {
        opacity: "1",
        userSelect: "none",
        cursor: "pointer"
    },
    tableHeadInner: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
});

export const Icon = ({sortField, orderBy, fieldName}) => {
    if (sortField === fieldName && orderBy) {
        return <BiSortUp/>
    } else if (sortField === fieldName && !orderBy) {
        return <BiSortDown/>
    } else {
        return <BiSort/>
    }
};

const tableHeadList = [
    {
        id: "agentNumber",
        label: "Agent Code"
    },
    {
        id: "agentName",
        label: "Agent Full Name"
    },
    {
        id: "ticketId",
        label: "Ticket ID"
    },
    {
        id: "grossWinningAmount",
        label: "Gross winning amount"
    },
    {
        id: "netWinningAmount",
        label: "Net winning amount"
    },
    {
        id: "refundAmount",
        label: "Refund amount"
    },
    {
        id: "winningTax",
        label: "Winning tax"
    }
];

export default function ReportsTable({data, getData, orderBy, setOrderBy, sortField, setSortField, take, setTake, setSkip}) {
    const [page, setPage] = useState(0);

    const classes = useStyles();

    function handleSort(fieldName) {
        const order = fieldName === sortField && !orderBy
        setSortField(fieldName);
        setOrderBy(order);
        getData(fieldName, orderBy)
    }

    function handleChangePage(e, page) {
        setPage(page);
        const tempSkip = page > 0 ? page * take : 0;
        setSkip(tempSkip);
    }

    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                {tableHeadList.map(({id, label}) => (
                    <th key={id} onClick={() => handleSort(id)}
                        className={sortField === id ? classes.tableHeadSelected : classes.tableHead}>
                        <div className={classes.tableHeadInner}>
                            {label}
                            <Icon
                                sortField={sortField}
                                fieldName={id}
                                orderBy={orderBy}
                            />
                        </div>
                    </th>
                ))}
            </tr>
            </thead>
            <tbody>
            {data?.map(ticket => (
                <tr key={ticket?.ticketId}>
                    <td>{ticket?.agentNumber}</td>
                    <td>{ticket?.agentName}</td>
                    <td>{ticket?.ticketId}</td>
                    <td>{Number(ticket?.grossWinningAmount)?.toFixed(2)}</td>
                    <td>{ticket?.netWinningAmount}</td>
                    <td>{ticket?.refundAmount}</td>
                    <td>{ticket?.winningTax}</td>
                </tr>
            ))}

            </tbody>
            <tfoot>
            <tr>
                {tableHeadList.map(({id, label}) => (
                    <th key={id}>
                        {label}
                    </th>
                ))}
            </tr>
            <tr>
                <TablePagination
                    count={data?.count}
                    page={page}
                    rowsPerPage={take}
                    onChangeRowsPerPage={(e) => setTake(e.target.value)}
                    onChangePage={handleChangePage}
                />
            </tr>
            </tfoot>
        </Table>
    )
}