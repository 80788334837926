import React, {useState, useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {TextField} from "@material-ui/core";
import {useSelector} from "react-redux";

import {useFormik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import "../styles/Roles.css";

const initialValues = {
    name: "",
};

export default function Edit(props) {
    const token = useSelector((state) => state.auth.token);

    const {show, onHide, onGroups, skip, take, orderBy, sortField, filterInputs, id, onGroupChoose} = props;

    const [isError, setIsError] = useState("");

    const CreateSchema = Yup.object().shape({
        name: Yup.string().required("Rol adı daxil edin."),
    });

    const getInputClasses = (filedName) => {
        if (formik.touched[filedName] && formik.errors[filedName]) {
            return true;
        }
        if (formik.touched[filedName] && !formik.errors[filedName]) {
            return false;
        }

        return false;
    };
    const [isDisabled, setIsDisabled] = useState(true);
    const [permissions, setPermissions] = useState({});
    const [data, setData] = useState([]);
    const [inputValues, setInputValues] = useState({});
    const [ids, setIds] = useState({});

    const putRoles = (values, resetForm, setSubmitting) => {
        let arr = []
        for(let key in ids){
            if(ids[key] === true) {
                arr.push(key)
            }
        }
        const group = {
            id: id,
            name: values.name,
            permissionIds: arr
        };
        axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}roles/update`,
            data: group,
            headers: {
                Authorization: `bearer ${token}`,
            },
        })
            .then(() => {
                onGroups({...filterInputs, skip, take, orderBy, sortField});
                setSubmitting(false);
                resetForm();
                onHide(false);
            })
            .catch((err) => {
                let message =
                    typeof err.response !== "undefined"
                        ? err.response.data.message
                        : err.message;
                setIsError(message);
            });
    };


    const formik = useFormik({
        initialValues,
        validationSchema: CreateSchema,
        onSubmit: (values, {resetForm, setSubmitting}) => {
            putRoles(values, resetForm, setSubmitting);
        },
    });

    const resetForm = () => {
        onHide();
        formik.resetForm();
    };

    const getPermissions = () => {
        axios.get(`${process.env.REACT_APP_API_URL}roles/getPermissions`)
            .then(({data}) => {
                let obj = {};
                data.forEach(element => {
                    obj[element.id] = false
                });
                setPermissions(obj)
                const separateIt = (arr, size) => {
                    var newArr = [];
                    for (var i = 0; i < arr.length; i += size) {
                        var sliceIt = arr.slice(i, i + size
                            )
                        ;
                        newArr.push(sliceIt);
                    }
                    return newArr;
                }

                setData(separateIt(data, 3))
            })
            .catch(() => {
                console.log("error")
            })
    }


    const handleChange = (e, id) => {

        let tempObj = {...ids};
        tempObj[id] = e.target.checked;
        setIds(tempObj);

    };

    const CanBeSubmit = () => {
        let arr = Object.values(ids).filter(val => val !== false);
        if (arr.length) {
           setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }

    useEffect(() => {
        CanBeSubmit();
    }, [ids]);

    useEffect(() => {
        getPermissions();
        onGroupChoose()
            .then(({data}) => {

                // setPermissions(data.permissionIds);
                let obj = {}
                data.permissionIds.forEach(element => {
                    obj[element] = true
                });

                setInputValues(obj);
                formik.setFieldValue("name", data.name)
            })
            .catch(() => {
                console.log("error")
            })
    }, []);

    useEffect(() => {
        let obj = {...permissions, ...inputValues};
        setIds(obj)
    }, [permissions, inputValues]);
    //
    // useEffect(() => {
    //     console.log(ids)
    // }, [ids])
    return (
        <Modal
            size="lg"
            aria-labelledby="create"
            centered
            className="modal pr-0"
            show={show}
            onHide={resetForm}
        >
            <form onSubmit={formik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="create">Rolu yenilə</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    {isError && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block MuiFormLabel-root Mui-error">
                                                {isError}
                                            </div>
                                        </div>
                                    )}
                                    <TextField
                                        id="name"
                                        name="name"
                                        label="Rol adı"
                                        placeholder="Rol adı"
                                        className="w-100"
                                        error={getInputClasses("name")}
                                        {...formik.getFieldProps("name")}
                                    />
                                </div>
                                {formik.touched.name &&
                                formik.errors.name ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.name}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            {data.map((perm, index) => (
                                <div className="col-12 col-md-12 col-lg-12 d-flex flex-wrap mb-2" key={index}>
                                    {perm.map((innerPerm) => (
                                            <div className="col-4 col-md-4 col-lg-4" key={innerPerm.id}>
                                                <div className="permission px-2 h-100">
                                                    <input id={innerPerm.id}
                                                           checked={ids[innerPerm.id] || false}
                                                           type="checkbox" onChange={(e) => handleChange(e, innerPerm.id)}/>
                                                    <label htmlFor={innerPerm.id}>{innerPerm.description}</label>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            ))}


                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                            formik.resetForm();
                        }}
                    >
                        Bağla
                    </Button>
                    <Button disabled={isDisabled} type="submit">Yadda saxla</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
