import React from "react";
import { Modal, Button, Badge } from "react-bootstrap";
import PropTypes from "prop-types";
import AlertComponent from "../../../../_metronic/_helpers/AlertComponent";
import {lighten, makeStyles} from "@material-ui/core/styles";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Paper,
    CircularProgress,
} from "@material-ui/core";

const headRows = [
    {
        id: "productName",
        numeric: false,
        disablePadding: true,
        label: "Məhsulun adı",
    },
    {
        id: "circulationNumber",
        numeric: false,
        disablePadding: true,
        label: "Tur",
    },
    {
        id: "columnPrice",
        numeric: false,
        disablePadding: true,
        label: "Sütun dəyəri",
    },
    {
        id: "ticketNumber",
        numeric: false,
        disablePadding: true,
        label: "Bilet nömrəsi",
    },
    {
        id: "price",
        numeric: false,
        disablePadding: true,
        label: "Ödənilmiş məbləğ",
    },
    {
        id: "createdDate",
        numeric: false,
        disablePadding: true,
        label: "Yaradılma tarixi",
    },
    {
        id: "winningAmount",
        numeric: false,
        disablePadding: true,
        label: "Uduş məbləği",
    },
];

function EnhancedTableHead() {
    return (
        <TableHead>
            <TableRow>
                {headRows.map((row, index) => (
                    <TableCell key={index} align={row.numeric ? "right" : "left"}>
                        {row.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        justifyContent: "flex-start",
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    actions: {
        color: theme.palette.text.secondary,
    },
    circulationInfo: {
        marginLeft: theme.spacing(2)
    },
    title: {
        flex: "0 0 auto",
    },
    dense: {
        margin: theme.spacing(2),
    },
}));

const EnhancedTableToolbar = ({groups}) => {
    const classes = useToolbarStyles();

    return (
        <Toolbar>
            <div className={classes.circulationInfo}>
                <span className="mr-4">Oynanılmış biletlərin sayı: <b>{groups.totalCount}</b></span>
                <span>Qazanan biletlərin sayı: <b>{groups.wonCount}</b></span>
            </div>
        </Toolbar>
    )
}

EnhancedTableHead.propTypes = {
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3),
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: "auto",
    },
    progresRoot: {
        width: "100%",
        padding: "32px 16px",
    },
    progress: {
        margin: "auto",
        display: "block",
        color: "#e2241b",
    },
}));

export default function Results(props) {
    const { onHide, groups, isLoading, isError, show } = props;
    const classes = useStyles();


    return (
        <Modal show={show} onHide={onHide} aria-labelledby="delete" centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title id="delete">Turun nəticələri</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={classes.root}>
                        <Paper className={classes.paper}>
                            <EnhancedTableToolbar groups={groups}/>
                            {isLoading && (
                                <div className={classes.progresRoot}>
                                    <CircularProgress className={classes.progress}/>
                                </div>
                            )}
                            <div className={classes.tableWrapper}>
                                {groups.wonTickets.length ? (
                                        <Table className={classes.table} aria-labelledby="tableTitle">
                                            <EnhancedTableHead rowCount={groups.wonTickets.length}/>
                                            <TableBody>
                                                {groups.wonTickets.map((row, index) => {
                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                            <TableCell component="th" id={labelId} scope="row">
                                                                {row.productName}
                                                            </TableCell>
                                                            <TableCell component="th" id={labelId} scope="row">
                                                                {row.circulationNumber}
                                                            </TableCell>
                                                            <TableCell component="th" id={labelId} scope="row">
                                                                {row.columnPrice}
                                                            </TableCell>
                                                            <TableCell component="th" id={labelId} scope="row">
                                                                {row.ticketNumber}
                                                            </TableCell>
                                                            <TableCell component="th" id={labelId} scope="row">
                                                                <Badge variant="info">{row.price} AZN</Badge>
                                                            </TableCell>
                                                            <TableCell component="th" id={labelId} scope="row">
                                                                {row.createdDate}
                                                            </TableCell>
                                                            <TableCell component="th" id={labelId} scope="row">
                                                                <Badge variant="success">{row.winningAmount?.toFixed(2)} AZN</Badge>
                                                            </TableCell>

                                                            <TableCell
                                                                component="th"
                                                                id={labelId}
                                                                scope="row"
                                                                padding="none"
                                                            ></TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    )
                                    : (!isLoading && isError && (!isLoading && isError && (
                                        <AlertComponent variant="danger" message="Məlumat tapılmadı."/>)))}
                            </div>
                        </Paper>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => onHide(false)}>
                        Bağla
                    </Button>
                </Modal.Footer>
        </Modal>
    );
}
