import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles/index";
import {Modal, Button} from "react-bootstrap";
// eslint-disable-next-line no-restricted-imports
import TextField from "@material-ui/core/TextField";

// eslint-disable-next-line no-restricted-imports
import CircularProgress from "@material-ui/core/CircularProgress";

import {Select, MenuItem, FormControl, InputLabel} from "@material-ui/core";

import axios from "axios";
import {useFormik} from "formik";
import * as Yup from "yup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import RenderIf from "app/components/RenderIf";
import {DateTimePicker} from "app/components/DatePicker";
import dayjs from "dayjs";

const initialValues = {
    code: "",
    resultedDate: "",
    status: false,
    productId: "",
    circulationGames: [],
    isJackpot: true,
    jackpotAmount: 0
};

const useStyles = makeStyles({
    list: {
        width: 400,
        height: "100vh",
    },
    card: {
        margin: "30px",
    },
    pointer: {
        cursor: "pointer",
    },
    progresRoot: {
        width: "100%",
        padding: "32px 16px",
    },
    progress: {
        margin: "auto",
        display: "block",
        color: "#e2241b",
    },
});

export default function Edit(props) {
    const classes = useStyles();
    const {onHide, show, chooseGroupId, onGroupChoose, onGroups, skip, take, orderBy, sortField} = props;

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [products, setProducts] = useState([]);
    const [gameCount, setGameCount] = useState(0);


    const onGetProducts = () => {
        axios.get(`products`)
            .then(({data: {data}}) => {
                setProducts(data)
            })
            .catch(() => {
                console.log("error")
            })
    }


    const onPutCirculation = (values, resetForm, setSubmitting) => {
        const circulation = {
            ...values,
            resultedDate: values.resultedDate,
            circulationGames: values.circulationGames.map(game => ({
                ...game, startDate:  game.startDate
            })),
            id: chooseGroupId,
        };

        axios
            .put(
                `circulations`,
                circulation
            )
            .then(() => {
                onGroups({skip, take, orderBy, sortField});
                setSubmitting(false);
                resetForm();
                onHide();
            })
            .catch((err) => {
                let message =
                    typeof err.response !== "undefined"
                        ? err.response.data.message
                        : err.message;
                setIsError(message);
            });
    }


    const EditSchema = Yup.object().shape({
        code: Yup.number().typeError("Yalnız rəqəm daxil edilə bilər.").required("Kod tələb olunur.").test('length', "1 - 6 simvol aralığında tur nömrəsi daxil edin.", (value) => {
            const regex = /\b[1-9]\d{0,5}\b/;
            return regex.test(value)
        }),
        resultedDate: Yup.string().required("Bitmə tarixi daxil edin."),
        status: Yup.boolean().required("Status daxil edin."),
        productId: Yup.number().typeError('Rəqəm daxil edin.').required("Məhsulu seçin."),
        jackpotAmount: Yup.number().typeError("Yalnız rəqəm daxil edilə bilər.").required("Cekpot məbləği daxil edin.")
    });

    const getInputClasses = (filedName) => {
        if (formik.touched[filedName] && formik.errors[filedName]) {
            return true;
        }
        if (formik.touched[filedName] && !formik.errors[filedName]) {
            return false;
        }

        return false;
    };

    const formik = useFormik({
        initialValues,
        validationSchema: EditSchema,
        onSubmit: (values, {resetForm, setSubmitting}) => {
            onPutCirculation(values, resetForm, setSubmitting);
        },
    });

    const {setFieldValue} = formik;

    const handleChangeCirculationGames = (e, index) => {
        const circulationGames = [...formik.values.circulationGames];
        circulationGames[index] = {
            ...circulationGames[index],
            [e.target.name]: e.target.value
        };
        formik.setFieldValue("circulationGames", circulationGames);
    }

    const handleChangeGameDate = (value, index) => {
        const circulationGames = [...formik.values.circulationGames];
        circulationGames[index] = {
            ...circulationGames[index],
            startDate: value
        };
        formik.setFieldValue("circulationGames", circulationGames);
    }

    useEffect(() => {
        setIsLoading(true);
        onGetProducts();
        onGroupChoose(chooseGroupId)
            .then(({data}) => {

                const circulationGames = data.circulationGames.map((g) => ({
                    id: g.id,
                    hostClubName: g.hostClubName,
                    guestClubName: g.guestClubName,
                    startDate: g.startDate,
                }))

                setFieldValue("code", data.code);
                setFieldValue("resultedDate", data.resultedDate);
                setFieldValue("status", data.status);
                setFieldValue("productId", data.productId);
                setFieldValue("jackpotAmount", data.circulationJackpot?.amount);
                setFieldValue("isJackpot", data.isJackpot);
                setFieldValue("circulationGames", circulationGames)
                setIsLoading(false);
                setIsError(false);

            })
            .catch(() => {
                setIsLoading(false);
                setIsError(true);
            });
    }, [chooseGroupId, onGroupChoose, setFieldValue]);

    useEffect(() => {
        const product = products.find(product => product.id === formik.values.productId);
        if(Boolean(product)) {
            setGameCount(product.gameCount);
        }
    }, [formik.values.productId, products])

    return (
        <Modal
            size="xl"
            onHide={onHide}
            show={show}
            aria-labelledby="edit"
        >
            {isLoading ? (
                <div className={classes.progresRoot}>
                    <CircularProgress className={classes.progress}/>
                </div>
            ) : !isLoading ? (
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title id="create">Turu yenilə</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-12 pl-4 pr-4">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        {isError && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block MuiFormLabel-root Mui-error">
                                                    {isError}
                                                </div>
                                            </div>
                                        )}
                                        <TextField
                                            id="code"
                                            name="code"
                                            label="Kod"
                                            placeholder="Kod"
                                            className="w-100"
                                            error={getInputClasses("code")}
                                            {...formik.getFieldProps("code")}
                                        />
                                    </div>
                                    {formik.touched.code &&
                                    formik.errors.code ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.code}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-6 col-md-6 col-lg-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        {isError && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block MuiFormLabel-root Mui-error">
                                                    {isError}
                                                </div>
                                            </div>
                                        )}
                                        <InputLabel className="w-100" htmlFor="resultedDate">Turun bitmə tarixi</InputLabel>
                                        <DateTimePicker
                                            showTime
                                            value={formik.values.resultedDate ? dayjs(formik.values.resultedDate) : ""}
                                            onChange={value => formik.setFieldValue("resultedDate", value)}
                                        />
                                    </div>
                                    {formik.touched.resultedDate &&
                                    formik.errors.resultedDate ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.resultedDate}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-6 col-md-6 col-lg-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        {isError && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block MuiFormLabel-root Mui-error">
                                                    {isError}
                                                </div>
                                            </div>
                                        )}
                                        <FormControl
                                            error={getInputClasses("point")}
                                            className="w-100"
                                            placeholder="Məhsul"
                                        >
                                            <InputLabel id="productId">Məhsul</InputLabel>
                                            <Select
                                                {...formik.getFieldProps("productId")}
                                                labelId="productId"
                                                id="productId"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>

                                                {products.map((product, index) => <MenuItem key={index}
                                                                                            value={product.id}>{product.name}</MenuItem>)}
                                            </Select>
                                            {formik.touched.productId && formik.errors.productId ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.errors.productId}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap flex-column">
                                        <InputLabel id="status">Cekpot</InputLabel>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={formik.values.isJackpot}
                                                    onChange={formik.handleChange}
                                                    color="secondary"
                                                    name="isJackpot"
                                                />
                                            }
                                            label={formik.values.isJackpot ? "Aktivdir" : "Deaktivdir"}
                                        />
                                    </div>
                                </div>
                                <RenderIf condition={formik.values.isJackpot}>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                            {isError && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block MuiFormLabel-root Mui-error">
                                                        {isError}
                                                    </div>
                                                </div>
                                            )}
                                            <TextField
                                                id="jackpotAmount"
                                                name="jackpotAmount"
                                                label="Cekpot məbləği"
                                                placeholder="Cekpot məbləği"
                                                className="w-100"
                                                error={getInputClasses("jackpotAmount")}
                                                {...formik.getFieldProps("jackpotAmount")}
                                            />
                                            {formik.touched.jackpotAmount && formik.errors.jackpotAmount ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.errors.jackpotAmount}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </RenderIf>
                                {Array.from({length: gameCount}).map((item, index) => {
                                    return (
                                        <div className="col-12 col-md-12 col-lg-12" key={index}>
                                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                                <TextField
                                                    name="hostClubName"
                                                    data-index-number={index}
                                                    value={formik.values.circulationGames[index]?.hostClubName}
                                                    label="Ev sahibi komanda"
                                                    onChange={(e) => handleChangeCirculationGames(e, index)}
                                                    className="w-100"
                                                />
                                                <TextField
                                                    name="guestClubName"
                                                    value={formik.values.circulationGames[index]?.guestClubName}
                                                    label="Qonaq komanda"
                                                    onChange={(e) => handleChangeCirculationGames(e, index)}
                                                    className="w-100"
                                                />
                                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                                    <DateTimePicker
                                                        showTime
                                                        value={formik.values.circulationGames[index]?.startDate ? dayjs(formik.values.circulationGames[index]?.startDate) : ""}
                                                        onChange={value => handleChangeGameDate(value, index)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                <span className="d-flex align-items-center">{gameCount > 0 && `Oyunların sayı: ${gameCount}`}</span>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="light"
                            onClick={() => {
                                onHide(false);
                                formik.resetForm();
                            }}
                        >
                            Bağla
                        </Button>
                        <Button type="submit">Yadda saxla</Button>
                    </Modal.Footer>
                </form>
            ) : null}
        </Modal>
    );
}
