import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles/index";
import {Modal, Button} from "react-bootstrap";
// eslint-disable-next-line no-restricted-imports
import TextField from "@material-ui/core/TextField";

// eslint-disable-next-line no-restricted-imports
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import {useFormik} from "formik";
import * as Yup from "yup";

const useStyles = makeStyles({
    list: {
        width: 400,
        height: "100vh",
    },
    card: {
        margin: "30px",
    },
    pointer: {
        cursor: "pointer",
    },
    progresRoot: {
        width: "100%",
        padding: "32px 16px",
    },
    progress: {
        margin: "auto",
        display: "block",
        color: "#e2241b",
    },
});

const initialValues = {
    id: "",
    name: "",
    columnPrice: "",
    gameCount: ""
};

export default function Edit(props) {
    const classes = useStyles();
    const {onHide, show, onGroups, chooseGroupId, onGroupChoose, skip, take, orderBy} = props;

    const onPutGroup = (values, resetForm, setSubmitting) => {
        const group = {
            id: values.id,
            name: values.name,
            columnPrice: values.columnPrice,
            gameCount: values.gameCount
        };
        axios
            .put(`${process.env.REACT_APP_API_URL}products`, group)
            .then(() => {
                onHide(false);
                resetForm();
                setSubmitting(false);
                onGroups({skip, take, orderBy});
            })
            .catch(
                ({
                     response: {
                         data: {checks},
                     },
                 }) => {
                    setIsError(true);
                    setErrorMessage(checks);
                    console.log("checks", checks);
                    console.log("errorMessage", errorMessage);
                }
            );
    };

    const EditSchema = Yup.object().shape({
        name: Yup.string().required("Məhsul adı daxil edin."),
        columnPrice: Yup.number().typeError("Rəqəm daxil edin.").required("Sütunlar üzrə əmsalı daxil edin."),
        gameCount: Yup.number().integer("Tam ədəd daxil edin").typeError("Rəqəm daxil edin.").required("Oyun sayı daxil edin."),
        firstPlaceRatio: Yup.number().typeError("Rəqəm daxil edin."),
        secondPlaceRatio: Yup.number().typeError("Rəqəm daxil edin."),
        thirdPlaceRatio: Yup.number().typeError("Rəqəm daxil edin."),
    });

    const getInputClasses = (filedName) => {
        if (formik.touched[filedName] && formik.errors[filedName]) {
            return true;
        }
        if (formik.touched[filedName] && !formik.errors[filedName]) {
            return false;
        }

        return false;
    };

    const formik = useFormik({
        initialValues,
        validationSchema: EditSchema,
        onSubmit: (values, {resetForm, setSubmitting}) => {
            onPutGroup(values, resetForm, setSubmitting);
        },
    });

    const {setFieldValue} = formik;

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});

    useEffect(() => {
        setIsLoading(true);
        onGroupChoose(chooseGroupId)
            .then((res) => {
                setFieldValue("id", res.data.id);
                setFieldValue("name", res.data.name);
                setFieldValue("columnPrice", res.data.columnPrice);
                setFieldValue("gameCount", res.data.gameCount);

                setIsLoading(false);
                setIsError(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                setIsError(true);
            });
    }, [chooseGroupId, onGroupChoose, setFieldValue]);

    return (
        <Modal
            size="md"
            onHide={onHide}
            show={show}
            aria-labelledby="edit"
            className="modal-right pr-0"
        >
            {isLoading ? (
                <div className={classes.progresRoot}>
                    <CircularProgress className={classes.progress}/>
                </div>
            ) : !isLoading ? (
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title id="edit">Məhsul məlumatlarını yenilə.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-12 pl-4 pr-4">
                            <div className="row">
                                <div className="col-6 col-md-6 col-lg-6 d-none">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        {isError && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block MuiFormLabel-root Mui-error">
                                                    {isError}
                                                </div>
                                            </div>
                                        )}
                                        <TextField
                                            id="id"
                                            name="id"
                                            label="ID"
                                            placeholder="ID"
                                            className="w-100 d-none"
                                            error={getInputClasses("id")}
                                            {...formik.getFieldProps("id")}
                                        />
                                    </div>
                                    {formik.touched.id &&
                                    formik.errors.id ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.id}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-6 col-md-6 col-lg-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        {isError && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block MuiFormLabel-root Mui-error">
                                                    {isError}
                                                </div>
                                            </div>
                                        )}
                                        <TextField
                                            id="name"
                                            name="name"
                                            label="Məhsulun adı"
                                            placeholder="Məhsulun adı"
                                            className="w-100"
                                            error={getInputClasses("name")}
                                            {...formik.getFieldProps("name")}
                                        />
                                    </div>
                                    {formik.touched.name &&
                                    formik.errors.name ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.name}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-6 col-md-6 col-lg-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        {isError && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block MuiFormLabel-root Mui-error">
                                                    {isError}
                                                </div>
                                            </div>
                                        )}
                                        <TextField
                                            id="gameCount"
                                            name="gameCount"
                                            label="Oyun sayı"
                                            placeholder="Oyun sayı"
                                            className="w-100"
                                            error={getInputClasses("gameCount")}
                                            {...formik.getFieldProps("gameCount")}
                                        />
                                    </div>
                                    {formik.touched.gameCount &&
                                    formik.errors.gameCount ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.gameCount}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-6 col-md-6 col-lg-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        {isError && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block MuiFormLabel-root Mui-error">
                                                    {isError}
                                                </div>
                                            </div>
                                        )}
                                        <TextField
                                            id="columnPrice"
                                            name="columnPrice"
                                            label="Sütun dəyəri"
                                            placeholder="Sütun dəyəri"
                                            className="w-100"
                                            error={getInputClasses("columnPrice")}
                                            {...formik.getFieldProps("columnPrice")}
                                        />
                                    </div>
                                    {formik.touched.columnPrice &&
                                    formik.errors.columnPrice ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.columnPrice}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="light"
                            onClick={() => {
                                onHide(false);
                                formik.resetForm();
                            }}
                        >
                            Bağla
                        </Button>
                        <Button type="submit">Yadda saxla</Button>
                    </Modal.Footer>
                </form>
            ) : null}
        </Modal>
    );
}
