import React, {useState} from "react";
import {Table, Button,} from "react-bootstrap";
import {makeStyles, TablePagination} from "@material-ui/core";
import {BiSort, BiSortUp, BiSortDown} from "react-icons/bi";

const useStyles = makeStyles({
    tableHead: {
        opacity: "0.8",
        userSelect: "none",
        cursor: "pointer",
        "&:hover": {
            opacity: "unset",
        }
    },
    tableHeadSelected: {
        opacity: "1",
        userSelect: "none",
        cursor: "pointer"
    },
    tableHeadInner: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
});

export const Icon = ({sortField, orderBy, fieldName}) => {
    if (sortField === fieldName && orderBy) {
        return <BiSortUp/>
    } else if (sortField === fieldName && !orderBy) {
        return <BiSortDown/>
    } else {
        return <BiSort/>
    }
};

const tableHeadList = [
    {
        id: "agentNumber",
        label: "Agent Code"
    },
    {
        id: "debitBalance",
        label: "Debit balance"
    },
    {
        id: "agentDebtCredit",
        label: "Agent debt credit"
    },
    {
        id: "dateTime",
        label: "Date time"
    }
];

export default function ReportsTable({data, orderBy, setOrderBy, sortField, setSortField, getExcel, take, setTake, setSkip, totalCount}) {
    const [page, setPage] = useState(0);

    const classes = useStyles();

    function handleSort(fieldName) {
        const order = fieldName === sortField && !orderBy
        setSortField(fieldName);
        setOrderBy(order);
    }

    function handleChangePage(e, page) {
        setPage(page);
        const tempSkip = page > 0 ? page * take : 0;
        setSkip(tempSkip)
    }

    return (
        <>
            <div className="d-flex justify-content-end mb-4">
                <Button onClick={getExcel} className="d-flex align-items-center" variant="outline-success">Download excel</Button>
            </div>
            <Table striped bordered hover>
                <thead>
                <tr>
                    {tableHeadList.map(({id, label}) => (
                        <th key={id} onClick={() => handleSort(id)}
                            className={sortField === id ? classes.tableHeadSelected : classes.tableHead}>
                            <div className={classes.tableHeadInner}>
                                {label}
                                <Icon
                                    sortField={sortField}
                                    fieldName={id}
                                    orderBy={orderBy}
                                />
                            </div>
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {data?.map((agent, index) => (
                    <tr key={index}>
                        <td>{agent?.agentNumber}</td>
                        <td>{agent?.debitBalance}</td>
                        <td>{agent?.agentDebtCredit}</td>
                        <td>{agent?.dateTime}</td>
                    </tr>
                ))}

                </tbody>
                <tfoot>
                <tr>
                    {tableHeadList.map(item => (
                        <th key={item.id}>{item.label}</th>
                    ))}
                </tr>
                <tr>
                    <TablePagination
                        count={totalCount}
                        page={page}
                        rowsPerPage={take}
                        onChangeRowsPerPage={(e) => setTake(e.target.value)}
                        onChangePage={handleChangePage}
                    />
                </tr>
                </tfoot>
            </Table>
        </>
    )
}