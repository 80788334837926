import React, { useEffect, useState } from "react";
import DataTables from "./components/DashboardTables";
import Edit from "./modals/Edit";
import EditPassword from "./modals/EditPassword";
import Delete from "./modals/Remove";
import Create from "./modals/Create";
import Filter from "./modals/Filter";
import Divider from "@material-ui/core/Divider/index";
import Switch from "@material-ui/core/Switch/index";
import FormControlLabel from "@material-ui/core/FormControlLabel/index";
import axios from "axios";
import { updateObject } from "../../../helpers/updateObject";
import { getUsers } from "./_redux/usersCrud";

function Dashboard() {
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [sortField, setSortField] = useState("record_date_time");
  const [orderBy, setOrderBy] = useState(false);
  const [groups, setGroups] = useState([]);
  const [chooseGroupId, setChooseGroupId] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [deleteGroupsIds, setDeleteGroupsIds] = useState([]);
  const [userId, setUserId] = useState(undefined);

  const [showCreate, setShowCreate] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showEditPass, setShowEditPass] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filterInputs, setFilterInputs] = useState({
    id: "",
    name: "",
    surname: "",
    lastlogindate: "",
    username: "",
    email: "",
    phonenumber: "",
    roleid: "",
  });

  const handleReset = () => {
    setFilterInputs({
      id: "",
      name: "",
      surname: "",
      lastlogindate: "",
      username: "",
      email: "",
      phonenumber: "",
      roleid: "",
    });
  };

  function handleChange() {
    setShowFilter((key) => !key);
  }

  const onGroups = (filterInputs, skip, take, orderBy) => {
    setIsLoading(true);

    const filterData = { ...filterInputs, skip, take, orderBy, sortField };
    getUsers(filterData)
      .then(({ data: { data, totalCount } }) => {
        setIsLoading(false);
        setGroups(data);
        setTotalCount(totalCount);
        setIsError(false);
      })
      .catch(() => {
        setIsLoading(false);
        setGroups([]);
        setIsError(true);
      });
  };

  const onGroupChoose = (id) => {
    return axios.get(`users/byId?id=${id}`);
  };
  const filterInputsUpdate = (e) => {
    const updateFiltersInput = updateObject(filterInputs, {
      [e.target.name]: e.target.value,
    });

    setFilterInputs(updateFiltersInput);
    setSkip(0);
  };

  useEffect(() => {
    const getAllTimer = setTimeout(() => {
      onGroups(filterInputs, skip, take, orderBy, sortField);
    }, 500);

    return () => {
      clearTimeout(getAllTimer);
    };
  }, [filterInputs, skip, take, orderBy, sortField]);

  return (
    <div className="row bg-white rounded">
      <div className="col-12 d-flex align-items-center py-4">
        <h1 className="display-4 mb-0">İstifadəçilər</h1>
        <FormControlLabel
          control={
            <Switch
              checked={showFilter}
              onChange={handleChange}
              color="secondary"
            />
          }
          label="Filter"
          className="ml-auto mb-0"
        />
      </div>
      <Divider className="w-100" />
      <DataTables
        groups={groups}
        onGroups={onGroups}
        isLoading={isLoading}
        isError={isError}
        setChooseGroupId={setChooseGroupId}
        setShowCreate={setShowCreate}
        setShowRemove={setShowRemove}
        setShowEdit={setShowEdit}
        setShowEditPass={setShowEditPass}
        setDeleteGroupsIds={setDeleteGroupsIds}
        deleteGroupsIds={deleteGroupsIds}
        sortField={sortField}
        setSortField={setSortField}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        totalCount={totalCount}
        skip={skip}
        take={take}
        filterInputs={filterInputs}
        setUserId={setUserId}
        setTake={setTake}
        setSkip={setSkip}
      />
      {showCreate && (
        <Create
          show={showCreate}
          onHide={setShowCreate}
          onGroups={onGroups}
          skip={skip}
          take={take}
          orderBy={orderBy}
        />
      )}
      {showRemove && (
        <Delete
          id={chooseGroupId}
          show={showRemove}
          onHide={setShowRemove}
          onGroups={onGroups}
          skip={skip}
          take={take}
          orderBy={orderBy}
          filterInputs={filterInputs}
          sortField={sortField}
        />
      )}
      {showEdit && (
        <Edit
          show={showEdit}
          onHide={setShowEdit}
          onGroups={onGroups}
          chooseGroupId={chooseGroupId}
          onGroupChoose={onGroupChoose}
          skip={skip}
          take={take}
          orderBy={orderBy}
          userId={userId}
        />
      )}
      {showEditPass && (
        <EditPassword
          show={showEditPass}
          onHide={setShowEditPass}
          onGroups={onGroups}
          chooseGroupId={chooseGroupId}
          onGroupChoose={onGroupChoose}
          skip={skip}
          take={take}
          orderBy={orderBy}
        />
      )}

      {showFilter && (
          <Filter
              show={showFilter}
              onHide={handleChange}
              filterInputsUpdate={filterInputsUpdate}
              filterInputs={filterInputs}
              resetFilter={handleReset}
          />
      )}
    </div>
  );
}

export default Dashboard;
