import React from "react";
import {Modal, Button} from "react-bootstrap";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField/index";
import Select from "@material-ui/core/Select";

export default function Filter(props) {
    const {onHide, show, filterInputsUpdate, filterInputs, resetFilter} = props;
    const [isActive, setIsActive] = React.useState(" ");
    const [open, setOpen] = React.useState(false);

    const handleReset = () => {
        resetFilter();
        setIsActive(" ");
    }

    function handleChange(event) {
        setIsActive(event.target.value);
        filterInputsUpdate(event);
    }

    function handleClose() {
        setOpen(false);
    }

    function handleOpen() {
        setOpen(true);
    }

    return (
        <Modal
            size="sm"
            show={show}
            onHide={onHide}
            aria-labelledby="create"
            centered
            className="modal-right pr-0"
        >
            <Modal.Header closeButton>
                <Modal.Title id="create">Agentlər filteri</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-12 pl-4 pr-4">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="name"
                                    name="name"
                                    label="Ad"
                                    placeholder="Ad"
                                    className="w-100"
                                    value={filterInputs.name}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="number"
                                    name="number"
                                    label="Nömrə"
                                    placeholder="Nömrə"
                                    className="w-100"
                                    value={filterInputs.number}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="deposit"
                                    name="deposit"
                                    label="Depozit"
                                    placeholder="Depozit"
                                    className="w-100"
                                    value={filterInputs.deposit}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="debt"
                                    name="debt"
                                    label="Borc"
                                    placeholder="Borc"
                                    className="w-100"
                                    value={filterInputs.debt}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <FormControl className="w-100">
                                <InputLabel htmlFor="role">Aktivlik statusu</InputLabel>
                                <Select
                                    open={open}
                                    onClose={handleClose}
                                    onOpen={handleOpen}
                                    value={isActive}
                                    onChange={handleChange}
                                    inputProps={{
                                        name: "isActive",
                                        id: "isActive",
                                    }}
                                >
                                    <MenuItem value="">Hamısı</MenuItem>
                                    <MenuItem value={true}>Aktiv</MenuItem>
                                    <MenuItem value={false}>Deaktiv</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleReset}>Sıfırla</Button>
            </Modal.Footer>
        </Modal>
    );
}
