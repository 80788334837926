import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import AlertComponent from "../../../../_metronic/_helpers/AlertComponent";
import {lighten, makeStyles} from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination/index";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import PlusIcon from "@material-ui/icons/Add";
import {format} from "date-fns";
import {ImKey} from "react-icons/im"
import {azAZ} from '@material-ui/core/locale';
import {shallowEqual, useSelector} from "react-redux";

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Toolbar,
    Paper,
    Tooltip,
    CircularProgress,
    createMuiTheme,
    ThemeProvider,
} from "@material-ui/core";
import axios from "axios";

const theme = createMuiTheme(azAZ);


const headRows = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Ad",
    },
    {
        id: "surname",
        numeric: false,
        disablePadding: true,
        label: "Soyad",
    },
    {
        id: "roleName",
        numeric: false,
        disablePadding: true,
        label: "Role name",
    },
    {
        id: "lastLoginDate",
        numeric: false,
        disablePadding: true,
        label: "Son giriş tarixi",
    },
    {
        id: "record_date_time",
        numeric: false,
        disablePadding: true,
        label: "Yaradılma tarixi",
    },
    {
        id: "createdByName",
        numeric: false,
        disablePadding: true,
        label: "Yaratmışdır",
    },
    {
        id: "updatedAt",
        numeric: false,
        disablePadding: true,
        label: "Yenilənmə tarixi",
    },
    {
        id: "updatedByName",
        numeric: false,
        disablePadding: true,
        label: "Yeniləmişdir",
    },
    {
        id: "userName",
        numeric: false,
        disablePadding: true,
        label: "İstifadəçi adı",
    },
    {
        id: "email",
        numeric: false,
        disablePadding: true,
        label: "E-poçt",
    },
    {
        id: "phoneNumber",
        numeric: false,
        disablePadding: true,
        label: "Telefon nömrəsi",
    },
    {
        id: "operations",
        numeric: false,
        disablePadding: true,
        label: "Əməliyyatlar",
    },
];

function EnhancedTableHead(props) {
    const {
        // onSelectAllClick,
        orderBy,
        sortField,
        onRequestSort,
        // numSelected,
        // rowCount
    } = props;

    return (
        <TableHead>
            <TableRow>
                {headRows.map((row, index) => {
                    if (row.id === "operations") {
                        return (
                            <TableCell key={index}
                                       align={row.numeric ? "right" : "left"}>
                                <div>
                                    {row.label}
                                </div>
                            </TableCell>
                        )
                    } else {
                        return (
                            <TableCell sortDirection={sortField === row.id ? "asc" : false} key={index}
                                       align={row.numeric ? "right" : "left"}>
                                <TableSortLabel active={sortField === row.id} direction={orderBy ? "asc" : "desc"}
                                                onClick={() => {
                                                    onRequestSort(row.id)
                                                }}>
                                    {row.label}
                                </TableSortLabel>
                            </TableCell>
                        )
                    }
                })}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        justifyContent: "flex-end",
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    // spacer: {
    //   flex: "1 1 100%",
    // },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: "0 0 auto",
    },
    dense: {
        margin: theme.spacing(2),
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const {numSelected, setShowCreate, Admin_Create} = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >

            <div className={classes.actions}>
                {Admin_Create && (
                    <Tooltip title="Yarat" placement="top">
                        <button
                            className="flex-shrink-0 btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary mr-2 h5 rounded-circle flex-shrink-0"
                            onClick={() => setShowCreate(true)}
                        >
                            <PlusIcon/>
                        </button>
                    </Tooltip>
                )}
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3),
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: "auto",
    },
    progresRoot: {
        width: "100%",
        padding: "32px 16px",
    },
    progress: {
        margin: "auto",
        display: "block",
        color: "#e2241b",
    },
}));

export default function EnhancedTable({
                                          groups,
                                          setUserId,
                                          onGroups,
                                          isLoading,
                                          isError,
                                          setChooseGroupId,
                                          setShowCreate,
                                          setShowRemove,
                                          setShowEdit,
                                          setShowEditPass,
                                          deleteGroupsIds,
                                          setDeleteGroupsIds,
                                          sortField,
                                          setSortField,
                                          orderBy,
                                          setOrderBy,
                                          totalCount,
                                          setTake,
                                          setSkip,
                                          skip,
                                          take,
                                          filterInputs,
                                      }) {
    const classes = useStyles();

    const {
        userId,
        Admin_Create,
        Admin_Update,
        Admin_Delete,
        Admin_ChangeActiveStatus,
        Admin_ChangePassword
    } = useSelector(({auth}) => ({
        userId: auth.user.id,
        Admin_Create: auth.permissions?.Admin_Create,
        Admin_Update: auth.permissions?.Admin_Update,
        Admin_Delete: auth.permissions?.Admin_Delete,
        Admin_ChangeActiveStatus: auth.permissions?.Admin_ChangeActiveStatus,
        Admin_ChangePassword: auth.permissions?.Admin_ChangePassword
    }), shallowEqual);

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = groups.map((n) => n.id);
            setDeleteGroupsIds(newSelecteds);
            return;
        }
        setDeleteGroupsIds([]);
    }

    const isSelected = (name) => deleteGroupsIds.indexOf(name) !== -1;

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (field) => {

        const isOrder = (field === sortField && !orderBy)

        setSortField(field);
        setOrderBy(isOrder)

    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
        setSkip(take * newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setTake(+event.target.value);
        setPage(0);
        setSkip(0);
    }

    const changeActiveStatus = (id) => {
        axios.put(`${process.env.REACT_APP_API_URL}users/changeActiveStatus?id=${id}`)
            .then(() => {
                onGroups(filterInputs, skip, take, orderBy);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    numSelected={deleteGroupsIds.length}
                    setShowCreate={setShowCreate}
                    Admin_Create={Admin_Create}
                />
                {isLoading && (
                    <div className={classes.progresRoot}>
                        <CircularProgress className={classes.progress}/>
                    </div>
                )}
                <div className={classes.tableWrapper}>
                    {groups.length
                        ?
                        (<Table className={classes.table} aria-labelledby="tableTitle">
                            <EnhancedTableHead
                                numSelected={deleteGroupsIds.length}
                                onSelectAllClick={handleSelectAllClick}
                                rowCount={groups.length}
                                onRequestSort={handleRequestSort}
                                sortField={sortField}
                                orderBy={orderBy}
                            />
                            <TableBody>
                                {groups.map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="default"
                                            >
                                                {row.name}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="default"
                                            >
                                                {row.surname}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="default"
                                            >
                                                {row.roleName}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="default"
                                            >
                                                {row.lastLoginDate ? format(new Date(row.lastLoginDate), 'dd.MM.yyy hh:mm') : "-"}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="default"
                                            >
                                                {row.createdAt ? format(new Date(row.createdAt), 'dd.MM.yyy hh:mm') : "-"}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="default"
                                            >
                                                {row.createdByName ? row.createdByName : "-"}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="default"
                                            >
                                                {row.updatedAt ? format(new Date(row.updatedAt), 'dd.MM.yyy hh:mm') : "-"}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="default"
                                            >
                                                {row.updatedByName ? row.updatedByName : "-"}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="default"
                                            >
                                                {row.userName}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="default"
                                            >
                                                {row.email}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="default"
                                            >
                                                {row.phoneNumber}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {userId === row.id ? "" :
                                                    Admin_ChangeActiveStatus ?
                                                        <Tooltip title={row.isActive ? "Deaktiv et" : "Aktivləşdir"}
                                                                 placement="top">
                                                            <button
                                                                aria-label="edit"
                                                                className="btn btn-sm btn-icon btn-bg-light btn-text-dark btn-hover-secondary mr-2"
                                                                onClick={() => {
                                                                    changeActiveStatus(row.id)
                                                                }}
                                                            >
                                                                {row.isActive ?
                                                                    <span
                                                                        className="active-user"><PowerSettingsNewIcon/></span> :
                                                                    <span
                                                                        className="deactive-user"><PowerSettingsNewIcon/></span>}
                                                            </button>
                                                        </Tooltip>
                                                        : ""
                                                }
                                                {Admin_ChangePassword && (
                                                    <Tooltip title="Şifrəni dəyiş" placement="top">
                                                        <button
                                                            aria-label="edit-password"
                                                            className="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning mr-2"
                                                            onClick={() => {
                                                                setChooseGroupId(row.id);
                                                                setShowEditPass(true);
                                                            }}
                                                        >
                                                            <ImKey/>
                                                        </button>
                                                    </Tooltip>
                                                )}

                                                {Admin_Update && (
                                                    <Tooltip title="Düzəliş et" placement="top">
                                                        <button
                                                            aria-label="edit"
                                                            className="btn btn-sm btn-icon btn-bg-light btn-text-dark btn-hover-dark mr-2"
                                                            onClick={() => {
                                                                setChooseGroupId(row.id);
                                                                setShowEdit(true);
                                                                setUserId(row.id)
                                                            }}
                                                        >
                                                            <EditIcon/>
                                                        </button>
                                                    </Tooltip>
                                                )}

                                                {userId === row.id ? "" :
                                                    Admin_Delete ? <Tooltip title="Sil" placement="top">
                                                        <button
                                                            aria-label="Delete"
                                                            className="btn btn-sm btn-icon btn-bg-light btn-text-danger btn-hover-danger"
                                                            onClick={() => {
                                                                setChooseGroupId(row.id);
                                                                setShowRemove(true);
                                                            }}
                                                        >
                                                            <DeleteIcon/>
                                                        </button>
                                                    </Tooltip> : ""}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>)
                        : !groups.length && !isLoading && !isError
                            ?
                            (<AlertComponent variant="info" message="Məlumat tapılmadı."/>)
                            : (!isLoading && isError && (<AlertComponent
                                variant="danger"
                                message="Şəbəkəylə bağlantı kəsildi."
                            />))
                    }
                </div>
                {totalCount > 10 &&
                    (<ThemeProvider theme={theme}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                "aria-label": "Previous Page",
                            }}
                            nextIconButtonProps={{
                                "aria-label": "Next Page",
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </ThemeProvider>)
                }
            </Paper>
        </div>
    );
}
