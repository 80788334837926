const actionTypes = {
    SetSnackbar: "Set Snackbar Action",
    SetOpen: "Set Open Action"
};

const initialState = {
    open: false,
    variant: "success",
    message: ""
}

export const snackbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SetSnackbar: {

            return {
                ...state, open: true, ...action.payload
            }
        }
        case actionTypes.SetOpen: {
            const {value} = action.payload;

            return {
                ...state, open: value
            }
        }
        default: {
            return state
        }
    }
};

export const actions = {
    setSnackbar: ({variant, message}) => ({
        type: actionTypes.SetSnackbar,
        payload: {variant, message}
    }),
    setOpen: (value) => ({
        type: actionTypes.SetOpen,
        payload: {value}
    })
}