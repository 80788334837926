import React from "react";
import {Modal, Button} from "react-bootstrap";
import TextField from "@material-ui/core/TextField/index";
import {InputLabel} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {DateTimePicker} from "app/components/DatePicker";
import dayjs from "dayjs";

export default function Filter({onHide, show, filterInputsUpdate, filterInputs, resetFilter, setFilterInputs}) {
    const [openIsActive, setOpenIsActive] = React.useState(false);
    const [openConfirmed, setOpenConfirmed] = React.useState(false);
    const [isActive, setIsActive] = React.useState(" ");
    const [confirmed, setConfirmed] = React.useState(" ");

    const handleReset = () => {
        resetFilter();
        setIsActive(" ");
        setConfirmed(" ");
    };

    const handleChange = (e, handlerFunc) => {
        handlerFunc(e.target.value);
        filterInputsUpdate(e);
    };

    function handleClose(handlerFunc) {
        handlerFunc(false);
    }

    function handleOpen(handlerFunc) {
        handlerFunc(true);
    }

    return (
        <Modal
            size="sm"
            show={show}
            onHide={onHide}
            aria-labelledby="create"
            centered
            className="modal-right pr-0"
        >
            <Modal.Header closeButton>
                <Modal.Title id="create">Müştərilər filteri</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-12 pl-4 pr-4">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    label="Telefon nömrəsi"
                                    placeholder="Telefon nömrəsi"
                                    className="w-100"
                                    value={filterInputs.phoneNumber}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <InputLabel htmlFor="expiredDate">Son giriş tarixi</InputLabel>
                                <DateTimePicker
                                    showTime
                                    value={filterInputs.lastLoginDate ? dayjs(filterInputs.lastLoginDate) : ""}
                                    onChange={val => setFilterInputs(prevState => ({
                                        ...prevState, lastLoginDate: val ? `${val.replace(" ", "T")}` : ""
                                    }))}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <InputLabel htmlFor="expiredDate">Qeydiyyat tarixi</InputLabel>
                                <DateTimePicker
                                    showTime
                                    value={filterInputs.registerDate ? dayjs(filterInputs.registerDate) : ""}
                                    onChange={val => setFilterInputs(prevState => ({
                                        ...prevState, registerDate: val ? `${val.replace(" ", "T")}` : ""
                                    }))}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <InputLabel htmlFor="expiredDate">Doğum tarixi</InputLabel>
                                <DateTimePicker
                                    value={filterInputs.birthDate ? dayjs(filterInputs.birthDate) : ""}
                                    onChange={val => setFilterInputs(prevState => ({
                                        ...prevState, birthDate: val ? `${val.replace(" ", "T")}` : ""
                                    }))}
                                    className="w-100"
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <FormControl className="w-100">
                                <InputLabel htmlFor="role">Aktivlik statusu</InputLabel>
                                <Select
                                    open={openIsActive}
                                    onClose={() => handleClose(setOpenIsActive)}
                                    onOpen={() => handleOpen(setOpenIsActive)}
                                    value={isActive}
                                    onChange={(e) => handleChange(e, setIsActive)}
                                    inputProps={{
                                        name: "isActive",
                                        id: "isActive",
                                    }}
                                >
                                    <MenuItem value={true}>Aktiv</MenuItem>
                                    <MenuItem value={false}>Deaktiv</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <FormControl className="w-100">
                                <InputLabel htmlFor="role">Təsdiqlənmə statusu</InputLabel>
                                <Select
                                    open={openConfirmed}
                                    onClose={() => handleClose(setOpenConfirmed)}
                                    onOpen={() => handleOpen(setOpenConfirmed)}
                                    value={confirmed}
                                    onChange={(e) => handleChange(e, setConfirmed)}
                                    inputProps={{
                                        name: "confirmed",
                                        id: "confirmed",
                                    }}
                                >
                                    <MenuItem value={true}>Təsdiqlənmiş</MenuItem>
                                    <MenuItem value={false}>Təsdiqlənməmiş</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleReset}>Sıfırla</Button>
            </Modal.Footer>
        </Modal>
    );
}
