import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles/index";
import {Modal, Button} from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import {useFormik} from "formik";
import * as Yup from "yup";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";

const useStyles = makeStyles({
    list: {
        width: 400,
        height: "100vh",
    },
    card: {
        margin: "30px",
    },
    pointer: {
        cursor: "pointer",
    },
    progresRoot: {
        width: "100%",
        padding: "32px 16px",
    },
    progress: {
        margin: "auto",
        display: "block",
        color: "#e2241b",
    },
});

const initialValues = {
    name: "",
    number: "",
    taxationNumber: "",
    deposit: "",
    weeklyDeposit: "",
    cityId: "",
    regionId: "",
};

export default function Edit(props) {
    const classes = useStyles();
    const {onHide, show, onGroups, chooseGroupId, onGroupChoose, skip, take, orderBy, sortField, filterInputs} = props;

    const onPutGroup = (values, resetForm, setSubmitting) => {
        const group = {
            id: chooseGroupId,
            name: values.name,
            number: values.number,
            taxationNumber: values.taxationNumber,
            weeklyDeposit: values.weeklyDeposit,
            deposit: values.deposit,
            regionId: values.regionId,
        };
        axios
            .put(`agent `, group)
            .then(() => {
                onHide(false);
                resetForm();
                setSubmitting(false);
                onGroups({skip, take, orderBy, sortField, ...filterInputs});
            })
            .catch(
                () => {
                    setIsError(true);
                }
            );
    };

    const getCities = () => {
        axios.get(`cities/filter`)
            .then(({data: {data}}) => {
                setCities(data)
            })
            .catch(() => {
                console.log("error")
            })
    }

    const getRegions = () => {
        axios.get(`regions/filter?CityId=${formik.values.cityId}`)
            .then(({data: {data}}) => {
                setRegions(data)
            })
            .catch((err) => {
                console.log(err)
            })
    };


    const EditSchema = Yup.object().shape({
        name: Yup.string().min(3, "Ən az 3 hərf olmalıdır.").max(255, 'Ən çox 255 hərf ola bilər.').required("Ad tələb olunur."),
        number: Yup.number().typeError('Sadəcə rəqəm daxil edə bilərsiniz.').required("Agent number tələb olunur."),
        taxationNumber: Yup.number().typeError('Sadəcə rəqəm daxil edə bilərsiniz.').required("Taxation number tələb olunur.").test('len', 'Mütləq 10 rəqəm daxil etməlisiniz.', (value) => {
            const regex = /^\d{10}$/;
            return regex.test(value)
        }),
        weeklyDeposit: Yup.number().typeError('Sadəcə rəqəm daxil edə bilərsiniz.').required("Həftəlik balans tələb olunur."),
        deposit: Yup.number().typeError('Sadəcə rəqəm daxil edə bilərsiniz.').required("Qalıq balans tələb olunur."),
        regionId: Yup.number().typeError('Sadəcə rəqəm daxil edə bilərsiniz.').required("Region ID tələb olunur."),
    });

    const getInputClasses = (filedName) => {
        if (formik.touched[filedName] && formik.errors[filedName]) {
            return true;
        }
        if (formik.touched[filedName] && !formik.errors[filedName]) {
            return false;
        }

        return false;
    };

    const formik = useFormik({
        initialValues,
        validationSchema: EditSchema,
        onSubmit: (values, {resetForm, setSubmitting}) => {
            onPutGroup(values, resetForm, setSubmitting);
        },
    });

    const {setFieldValue} = formik;

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [cities, setCities] = useState([])
    const [regions, setRegions] = useState([]);

    useEffect(() => {
        getRegions();
    }, [formik.values.cityId])

    useEffect(() => {
        setIsLoading(true);
        getCities();
        getRegions();
        onGroupChoose(chooseGroupId)
            .then((res) => {
                setFieldValue("name", res.data.name);
                setFieldValue("number", res.data.number);
                setFieldValue("taxationNumber", res.data.taxationNumber);
                setFieldValue("weeklyDeposit", res.data.weeklyDeposit);
                setFieldValue("deposit", res.data.deposit);
                setFieldValue("cityId", res.data.cityId)
                setFieldValue("regionId", res.data.regionId);
                setIsLoading(false);
                setIsError(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                setIsError(true);
            });
    }, [chooseGroupId, onGroupChoose, setFieldValue]);

    return (
        <Modal
            size="md"
            onHide={onHide}
            show={show}
            aria-labelledby="edit"
            className="modal-right pr-0"
        >
            {isLoading ? (
                <div className={classes.progresRoot}>
                    <CircularProgress className={classes.progress}/>
                </div>
            ) : !isLoading ? (
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title id="edit">Agent məlumatlarını yenilə.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-12 pl-4 pr-4">
                            <div className="row">
                                <div className="col-6 col-md-6 col-lg-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="name"
                                            name="name"
                                            label="Ad"
                                            placeholder="Ad"
                                            className="w-100"
                                            error={getInputClasses("name")}
                                            {...formik.getFieldProps("name")}
                                        />
                                    </div>
                                    {formik.touched.name &&
                                    formik.errors.name ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.name}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-6 col-md-6 col-lg-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="number"
                                            name="number"
                                            label="Agent number"
                                            placeholder="Agent number"
                                            className="w-100"
                                            error={getInputClasses("number")}
                                            {...formik.getFieldProps("number")}
                                        />
                                    </div>
                                    {formik.touched.number &&
                                    formik.errors.number ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.number}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="taxationNumber"
                                            name="taxationNumber"
                                            label="Taxation number"
                                            placeholder="Taxation number"
                                            className="w-100"
                                            error={getInputClasses("taxationNumber")}
                                            {...formik.getFieldProps("taxationNumber")}
                                        />
                                    </div>
                                    {formik.touched.taxationNumber &&
                                    formik.errors.taxationNumber ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.taxationNumber}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="weeklyDeposit"
                                            name="weeklyDeposit"
                                            label="Həftəlik balans"
                                            placeholder="Həftəlik balans"
                                            className="w-100"
                                            error={getInputClasses("weeklyDeposit")}
                                            {...formik.getFieldProps("weeklyDeposit")}
                                        />
                                    </div>
                                    {formik.touched.weeklyDeposit &&
                                    formik.errors.weeklyDeposit ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.weeklyDeposit}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="deposit"
                                            name="deposit"
                                            label="Qalıq balans"
                                            placeholder="Qalıq balans"
                                            className="w-100"
                                            error={getInputClasses("deposit")}
                                            {...formik.getFieldProps("deposit")}
                                        />
                                    </div>
                                    {formik.touched.weeklyDeposit &&
                                    formik.errors.weeklyDeposit ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.weeklyDeposit}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-6 col-md-6 col-lg-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <FormControl
                                            error={getInputClasses("cityId")}
                                            className="w-100"
                                            placeholder="cityId"
                                        >
                                            <InputLabel id="regionId">Şəhər</InputLabel>
                                            <Select
                                                {...formik.getFieldProps("cityId")}
                                                labelId="cityId"
                                                id="cityId"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {cities.map((city, index) => <MenuItem key={index}
                                                                                       value={city.id}>{city.name}</MenuItem>
                                                )}
                                            </Select>
                                            {formik.touched.cityId && formik.errors.cityId ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.errors.cityId}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="col-6 col-md-6 col-lg-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <FormControl
                                            error={getInputClasses("regionId")}
                                            className="w-100"
                                            placeholder="regionId"
                                        >
                                            <InputLabel id="regionId">Region</InputLabel>
                                            <Select
                                                {...formik.getFieldProps("regionId")}
                                                labelId="regionId"
                                                id="regionId"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {regions.map((region, index) => <MenuItem key={index}
                                                                                          value={region.id}>{region.name}</MenuItem>
                                                )}
                                            </Select>
                                            {formik.touched.regionId && formik.errors.regionId ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.errors.regionId}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </FormControl>
                                    </div>
                                </div>
                                {isError && (
                                    <div className="col-12 pt-4">
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block MuiFormLabel-root Mui-error">
                                                {isError}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="light"
                            onClick={() => {
                                onHide(false);
                                formik.resetForm();
                            }}
                        >
                            Bağla
                        </Button>
                        <Button type="submit">Yadda saxla</Button>
                    </Modal.Footer>
                </form>
            ) : null}
        </Modal>
    );
}
