import React, {useState, useEffect} from "react";
import axios from "axios";
import {FormControlLabel, Switch, TextField} from "@material-ui/core";
import AlertComponent from "_metronic/_helpers/AlertComponent";
import RenderIf from "app/components/RenderIf";
import Loading from "app/components/Loading";
import Table from "./components/Table";
import {objectToQueryString} from "../../../_metronic/_helpers";
import {Button} from "react-bootstrap";

const initialFilter = {
    agentId: "",
    agentStatus: ""
};

export default function TotalRemainingReport() {
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [orderBy, setOrderBy] = useState(false);
    const [sortField, setSortField] = useState("");
    const [hideTestAgents, setHideTestAgents] = useState(true);
    const [filterInputs, setFilterInputs] = useState(initialFilter);
    const [state, setState] = useState({
        isLoading: true,
        error: null,
        data: null,
    });

    async function getData() {
        try {
            setState(prevState => ({
                ...prevState, isLoading: true
            }))
            const query = objectToQueryString({skip, take, sortField, orderBy, hideTestAgents, ...filterInputs})
            const {data} = await axios({
                method: "GET",
                url: `reports/totalremaining?${query}`,
            });
            setState({
                isLoading: false,
                error: null,
                data
            })
        } catch (err) {
            setState({
                isLoading: false,
                error: err?.response?.data,
                data: null
            })
        }
    }

    async function getExcel() {
        try {
            const {data} = await axios({
                url: 'reports/totalremaining/Excel',
                params: {sortField, orderBy, hideTestAgents, ...filterInputs},
                method: 'GET',
                responseType: 'blob'
            })
            const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = 'TotalRemainingReport.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (err) {

        }
    }

    const filterInputsUpdate = (e) => {
        e.persist();
        setFilterInputs(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }));
        setSkip(0);
    }

    useEffect(() => {
        getData();
    }, [orderBy, sortField, skip, take, hideTestAgents, filterInputs])

    return (
        <div className="row bg-white rounded">
            <div className="col-12 py-4">
                <div className="col-12 d-flex flex-wrap align-items-center py-4">
                    <TextField
                        name="agentId"
                        placeholder="Agent number"
                        value={filterInputs.agentId}
                        onChange={filterInputsUpdate}
                        variant="outlined"
                        className="mx-2 my-2"
                    />
                    <Button onClick={() => setFilterInputs(initialFilter)} className="mx-2 my-2">
                        Sıfırla
                    </Button>
                </div>
                <div className="d-flex justify-content-between">
                    <FormControlLabel
                        control={
                            <Switch
                                checked={hideTestAgents}
                                onChange={() => setHideTestAgents(prevState => !prevState)}
                                color="secondary"
                            />
                        }
                        disabled={state.isLoading}
                        label="Test agentləri gizlə"
                        className="mb-0"
                    />
                    <div className="d-flex justify-content-end mb-4">
                        <Button onClick={getExcel} className="d-flex align-items-center" variant="outline-success">Download excel</Button>
                    </div>
                </div>
                <RenderIf condition={state.data?.totalRemainingDebitCredits?.length}>
                    <Table
                        data={state.data}
                        isLoading={state.isLoading}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                        sortField={sortField}
                        setSortField={setSortField}
                        getExcel={getExcel}
                        take={take}
                        setTake={setTake}
                        setSkip={setSkip}
                    />
                </RenderIf>
                <RenderIf condition={state.isLoading}>
                   <div className="d-flex justify-content-center">
                       <Loading/>
                   </div>
                </RenderIf>
                <RenderIf condition={!state.isLoading && !state.data?.totalRemainingDebitCredits?.length}>
                    <AlertComponent message="Məlumat tapılmadı" variant="info"/>
                </RenderIf>
                <RenderIf condition={!state.isLoading && state.error}>
                    <AlertComponent message="Xəta baş verdi" variant="danger"/>
                </RenderIf>
            </div>
        </div>
    )
}