import React, {useState, useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {TextField} from "@material-ui/core";
import {Select, MenuItem, FormControl, InputLabel} from "@material-ui/core";
import InputMask from "react-input-mask";

import {useFormik} from "formik";
import * as Yup from "yup";
import axios from "axios";

const initialValues = {
    name: "",
    number: "",
    phoneNumber: "",
    password: "",
    taxationNumber: "",
    weeklyDeposit: "",
    cityId: "",
    regionId: ""
};

export default function Create(props) {
    const {show, onHide, onGroups, skip, take, sortField, orderBy, cities, regions, getCities, getRegions, filterInputs} = props;
    const [isError, setIsError] = useState("");
    const [open, setOpen] = useState(false);
    const [openRegion, setOpenRegion] = useState(false);
    const [selectedCity, setSelectedCity] = useState("")
    const [selectedRegion, setSelectedRegion] = useState("")

    const CreateSchema = Yup.object().shape({
        name: Yup.string().min(3, "Ən az 3 hərf olmalıdır.").max(255, 'Ən çox 255 hərf ola bilər.').required("Ad tələb olunur."),
        number: Yup.number().typeError('Sadəcə rəqəm daxil edə bilərsiniz.').required("Agent number tələb olunur."),
        phoneNumber: Yup.number().typeError('Sadəcə rəqəm daxil edə bilərsiniz.').required("Telefon nömrəsi tələb olunur."),
        password: Yup.string()
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_-])(?=.{8,})/,
                "Şifrə böyük hərf, kiçik hərf, rəqəm və xarakterdən(!@#$%^&*()_-) ibarət olmalıdır."
            )
            .required("Şifrə tələb olunur"),
        taxationNumber: Yup.number().typeError('Sadəcə rəqəm daxil edə bilərsiniz.').required("Taxation number tələb olunur.").test('len', 'Mütləq 10 rəqəm daxil etməlisiniz.', (value) => {
            const regex = /^\d{10}$/;
            return regex.test(value)
        }),
        weeklyDeposit: Yup.number().typeError('Sadəcə rəqəm daxil edə bilərsiniz.').required("Həftəlik balans tələb olunur."),
        cityId: Yup.string().required("Şəhər tələb olunur."),
        regionId: Yup.string().required("Region tələb olunur."),
    });

    const getInputClasses = (filedName) => {
        if (formik.touched[filedName] && formik.errors[filedName]) {
            return true;
        }
        if (formik.touched[filedName] && !formik.errors[filedName]) {
            return false;
        }

        return false;
    };


    const onPostGroup = (values, resetForm, setSubmitting) => {
        const group = {
            name: values.name,
            number: values.number,
            phoneNumber: values.phoneNumber,
            password: values.password,
            taxationNumber: values.taxationNumber,
            weeklyDeposit: values.weeklyDeposit,
            deposit: values.weeklyDeposit,
            regionId: selectedRegion,
        };
        axios({
            method: "post",
            url: `agent`,
            data: group,
        })
            .then(() => {
                onGroups({skip, take, orderBy, sortField, ...filterInputs});
                setSubmitting(false);
                resetForm();
                onHide(false);
            })
            .catch((err) => {
                let message =
                    typeof err.response !== "undefined"
                        ? err.response.data.message
                        : err.message;
                setIsError(message);
            });
    };

    const formik = useFormik({
        initialValues,
        validationSchema: CreateSchema,
        onSubmit: (values, {resetForm, setSubmitting}) => {
            onPostGroup(values, resetForm, setSubmitting);
        },
    });

    const resetForm = () => {
        onHide();
        formik.resetForm();
    };

    function handleChange(event) {
        setSelectedCity(event.target.value);
        formik.setFieldValue("cityId", event.target.value)
    }

    function handleRegion(event) {
        setSelectedRegion(event.target.value);
        formik.setFieldValue("regionId", event.target.value)
    }

    function handleClose() {
        setOpen(false);
    }

    function handleOpen() {
        setOpen(true);
    }

    function handleOpenRegion() {
        setOpenRegion(true)
    }

    function handleCloseRegion() {
        setOpenRegion(false)
    }

    useEffect(() => {
        getCities();
        setIsError("");
    }, [show]);

    useEffect(() => {
        getRegions(selectedCity);
    }, [selectedCity]);

    return (
        <Modal
            size="md"
            aria-labelledby="create"
            centered
            className="modal pr-0"
            show={show}
            onHide={resetForm}
        >
            <form onSubmit={formik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="create">Agent yarat</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">
                            <div className="col-6 col-md-6 col-lg-6">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="name"
                                        name="name"
                                        label="Ad"
                                        placeholder="Ad"
                                        className="w-100"
                                        error={getInputClasses("name")}
                                        {...formik.getFieldProps("name")}
                                    />
                                </div>
                                {formik.touched.name &&
                                formik.errors.name ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.name}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-6 col-md-6 col-lg-6">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="number"
                                        name="number"
                                        label="Agent number"
                                        placeholder="Agent number"
                                        className="w-100"
                                        error={getInputClasses("number")}
                                        {...formik.getFieldProps("number")}
                                    />
                                </div>
                                {formik.touched.number &&
                                formik.errors.number ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.number}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-6 col-md-6 col-lg-6">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <InputMask
                                        mask="\9\94999999999"
                                        {...formik.getFieldProps("phone")}
                                        disabled={false}
                                        maskChar=""
                                        id="phoneNumber"
                                        type="number"

                                    >
                                        {() =>
                                            <TextField
                                                label="Telefon"
                                                name="phoneNumber"
                                                placeholder="Telefon"
                                                className="w-100"
                                                error={getInputClasses("phoneNumber")}
                                            />}
                                    </InputMask>
                                </div>
                                {formik.touched.phoneNumber &&
                                formik.errors.phoneNumber ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.phoneNumber}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-6 col-md-6 col-lg-6">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="password"
                                        name="password"
                                        label="Şifrə"
                                        placeholder="Şifrə"
                                        className="w-100"
                                        error={getInputClasses("password")}
                                        {...formik.getFieldProps("password")}
                                    />
                                </div>
                                {formik.touched.password &&
                                formik.errors.password ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.password}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="taxationNumber"
                                        name="taxationNumber"
                                        label="Taxation number"
                                        placeholder="Taxation number"
                                        className="w-100"
                                        error={getInputClasses("taxationNumber")}
                                        {...formik.getFieldProps("taxationNumber")}
                                    />
                                </div>
                                {formik.touched.taxationNumber &&
                                formik.errors.taxationNumber ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.taxationNumber}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="weeklyDeposit"
                                        name="weeklyDeposit"
                                        label="Həftəlik balans"
                                        placeholder="Həftəlik balans"
                                        className="w-100"
                                        error={getInputClasses("weeklyDeposit")}
                                        {...formik.getFieldProps("weeklyDeposit")}
                                    />
                                </div>
                                {formik.touched.weeklyDeposit &&
                                formik.errors.weeklyDeposit ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.weeklyDeposit}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-6 col-md-6 col-lg-6">
                                <FormControl className="w-100">
                                    <InputLabel htmlFor="regionId">Şəhər</InputLabel>
                                    <Select
                                        open={open}
                                        onClose={handleClose}
                                        onOpen={handleOpen}
                                        value={selectedCity}
                                        onChange={handleChange}
                                        inputProps={{
                                            name: "cityId",
                                            id: "cityId",
                                        }}
                                    >
                                        <MenuItem value="">Hamısı</MenuItem>
                                        {cities.map((city) => (
                                            <MenuItem key={city.id} value={city.id}>{city.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {formik.touched.cityId &&
                                formik.errors.cityId ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.cityId}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-6 col-md-6 col-lg-6">
                                <FormControl className="w-100">
                                    <InputLabel htmlFor="regionId">Region</InputLabel>
                                    <Select
                                        open={openRegion}
                                        onClose={handleCloseRegion}
                                        onOpen={handleOpenRegion}
                                        value={selectedRegion}
                                        onChange={handleRegion}
                                        inputProps={{
                                            name: "regionId",
                                            id: "regionId",
                                        }}
                                    >
                                        <MenuItem value="">Hamısı</MenuItem>
                                        {regions.map((region) => (
                                            <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {formik.touched.regionId &&
                                formik.errors.regionId ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.regionId}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            {isError && (
                                <div className="col-12 pt-4">
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block MuiFormLabel-root Mui-error">
                                            {isError}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                            formik.resetForm();
                        }}
                    >
                        Bağla
                    </Button>
                    <Button type="submit">Yarat</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
