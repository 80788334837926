import React from "react";
import { Modal, Button } from "react-bootstrap";

import axios from "axios";
import { useSelector } from "react-redux";

export default function CheckTickets(props) {
    const token = useSelector((state) => state.auth.token);

    const { onHide, setResults, setShowCheckGames, setShowResults, show, circulationId, onGroups, skip, take, orderBy, sortField } = props;

    const onCheckTickets = (e) => {
        e.preventDefault();

        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}checkTicket?circulationId=${circulationId}`,
            headers: {
                Authorization: `bearer ${token}`,
            },
        })
            .then(({data}) => {
                onHide();
                setShowCheckGames(false);
                setResults(data)
                setShowResults(true);
                onGroups({skip, take, orderBy, sortField})
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Modal show={show} onHide={onHide} aria-labelledby="check-tickets" centered>
            <form onSubmit={onCheckTickets}>
                <Modal.Header closeButton>
                    <Modal.Title id="check-tickets">Turu təsdiqlə</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">Turun nəticələrini təsdiqləməyə əminsiniz?</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => onHide(false)}>
                        Ləğv
                    </Button>
                    <Button type="submit">Təsdiqlə</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
