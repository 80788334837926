import React from "react";
import {Modal, Button} from "react-bootstrap";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField/index";
import Select from "@material-ui/core/Select";

export default function Filter(props) {
    const {onHide, show, filterInputsUpdate, filterInputs, resetFilter} = props;
    const [isDeleted, setIsDeleted] = React.useState("false");
    const [open, setOpen] = React.useState(false);

    const handleReset = () => {
        resetFilter();
        setIsDeleted("false");
    }

    function handleChange(event) {
        setIsDeleted(event.target.value);
        filterInputsUpdate(event);
    }

    function handleClose() {
        setOpen(false);
    }

    function handleOpen() {
        setOpen(true);
    }

    return (
        <Modal
            size="sm"
            show={show}
            onHide={onHide}
            aria-labelledby="create"
            centered
            className="modal-right pr-0"
        >
            <Modal.Header closeButton>
                <Modal.Title id="create">Məhsul filteri.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-12 pl-4 pr-4">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="name"
                                    name="name"
                                    label="Məhsulun adı"
                                    placeholder="Məhsulun adı"
                                    className="w-100"
                                    value={filterInputs.name}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="columnPrice"
                                    name="columnPrice"
                                    label="Sütun dəyəri"
                                    placeholder="Sütun dəyəri"
                                    className="w-100"
                                    value={filterInputs.columnPrice}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <FormControl className="w-100">
                                    <InputLabel htmlFor="isDeleted">Məhsulun statusu</InputLabel>
                                    <Select
                                        open={open}
                                        onClose={handleClose}
                                        onOpen={handleOpen}
                                        value={isDeleted}
                                        onChange={handleChange}
                                        inputProps={{
                                            name: "isDeleted",
                                            id: "isDeleted",
                                        }}
                                    >
                                        <MenuItem value="all">Hamısı</MenuItem>
                                        <MenuItem value="true">Silinən</MenuItem>
                                        <MenuItem value="false">Aktiv</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleReset}>
                    Sıfırla
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
