import React, {useState, useEffect} from "react";
import axios from "axios";
import AlertComponent from "_metronic/_helpers/AlertComponent";
import RenderIf from "app/components/RenderIf";
import Loading from "app/components/Loading";
import Table from "./components/Table";
import {
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    FormControlLabel, Switch, TextField
} from "@material-ui/core";
import {Button} from "react-bootstrap";
import {objectToQueryString} from "../../../_metronic/_helpers";
import {DateTimePickerOutlined} from "../../components/DatePicker";
import dayjs from "dayjs";

const date = new Date();

const initialStartDate = new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString().split('T')[0] + "T00:00";
const initialEndDate = date.toISOString().split('T')[0] + "T23:59";

const initialFilter = {
    createdAgentId: "",
    paidAgentId: "",
    ticketId: "",
    circulationId: "",
    voidTicketId: "",
    programType: "",
    betStatus: "",
    ticketStatus: "",
    stakeAmount: "",
    times: ""
};

export default function TicketReport() {
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [orderBy, setOrderBy] = useState(false);
    const [sortField, setSortField] = useState("creationDateTime");
    const [filter, setFilter] = useState("");
    const [statusId, setStatusId] = useState();
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);
    const [isPaid, setIsPaid] = useState();
    const [hideTestAgents, setHideTestAgents] = useState(true);
    const [state, setState] = useState({
        isLoading: false,
        error: null,
        data: null,
    });
    const [filterInputs, setFilterInputs] = useState(initialFilter);

    async function getData() {
        const query = objectToQueryString({
            skip,
            take,
            sortField,
            orderBy,
            filter,
            statusId,
            startDate,
            endDate,
            isPaid,
            hideTestAgents,
            ...filterInputs
        });

        setState((prevState) => ({
            ...prevState,
            isLoading: true,
        }));
        try {
            const {data} = await axios({
                method: "GET",
                url: `reports/ticketreport?${query}`,
            });
            setState({
                isLoading: false,
                error: null,
                data,
            });
        } catch (err) {
            setState({
                isLoading: false,
                error: err?.response?.data,
                data: null,
            });
        }
    }

    async function getExcel() {
        const query = objectToQueryString({
            orderBy,
            sortField,
            startDate,
            endDate,
            filter,
            statusId,
            hideTestAgents,
            ...filterInputs
        });

        try {
            const {data} = await axios({
                url: `reports/ticketreport/excel?${query}`,
                method: "GET",
                responseType: "blob",
            });
            const blob = new Blob([data], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "TicketReport.xlsx";
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (err) {
        }
    }

    function handleChange(event) {
        setFilter(event.target.value);
    }

    function handleChangeId(e) {
        setStatusId(e.target.value);
    }

    function handleChangeStartDate(e) {
        setStartDate(e.replace(" ", "T").slice(0, e.length - 3));
    }

    function handleChangeEndDate(e) {
        setEndDate(e.replace(" ", "T").slice(0, e.length - 3));
    }

    function handleChangePaidStatus(e) {
        setIsPaid(e.target.value);
    }

    function handleReset() {
        setFilterInputs(initialFilter);
        setStartDate(initialStartDate);
        setEndDate(initialEndDate);
    }

    const filterInputsUpdate = (e) => {
        e.persist();
        setFilterInputs(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }));
        setSkip(0);
    }

    useEffect(() => {
        getData();
    }, [
        orderBy,
        sortField,
        skip,
        take,
        filter,
        statusId,
        startDate,
        endDate,
        isPaid,
        hideTestAgents
    ]);

    return (
        <div className="row bg-white rounded">
            <div className="col-12 py-4 overflow-auto">
                <div className="col-12 d-flex flex-wrap align-items-center py-4">
                    <div className="d-flex flex-column align-items-start mr-2 mb-2">
                        <InputLabel>
                            Created agent number
                        </InputLabel>
                        <TextField
                            name="createdAgentId"
                            placeholder="Created agent number"
                            value={filterInputs.createdAgentId}
                            onChange={filterInputsUpdate}
                            variant="outlined"
                        />
                    </div>
                    <div className="d-flex flex-column align-items-start mr-2 mb-2">
                        <InputLabel>
                            Paid agent number
                        </InputLabel>
                        <TextField
                            name="paidAgentId"
                            placeholder="Paid agent number"
                            value={filterInputs.paidAgentId}
                            onChange={filterInputsUpdate}
                            variant="outlined"
                        />
                    </div>
                    <div className="d-flex flex-column align-items-start mr-2 mb-2">
                        <InputLabel>
                            Ticket ID
                        </InputLabel>
                        <TextField
                            name="ticketId"
                            placeholder="Ticket ID"
                            value={filterInputs.ticketId}
                            onChange={filterInputsUpdate}
                            variant="outlined"
                        />
                    </div>
                    <div className="d-flex flex-column align-items-start mr-2 mb-2">
                        <InputLabel>
                            Circulation ID
                        </InputLabel>
                        <TextField
                            name="circulationId"
                            placeholder="Circulation ID"
                            value={filterInputs.circulationId}
                            onChange={filterInputsUpdate}
                            variant="outlined"
                        />
                    </div>
                    <div className="d-flex flex-column align-items-start mr-2 mb-2">
                        <InputLabel>
                            Bron ticket ID
                        </InputLabel>
                        <TextField
                            name="voidTicketId"
                            placeholder="Bron ticket ID"
                            value={filterInputs.voidTicketId}
                            onChange={filterInputsUpdate}
                            variant="outlined"
                        />
                    </div>
                    <div className="d-flex flex-column align-items-start mr-2 mb-2">
                        <InputLabel>
                            Program type
                        </InputLabel>
                        <TextField
                            name="programType"
                            placeholder="Program type"
                            value={filterInputs.programType}
                            onChange={filterInputsUpdate}
                            variant="outlined"
                        />
                    </div>
                    <div className="d-flex flex-column align-items-start mr-2 mb-2">
                        <InputLabel>
                            Stake amount
                        </InputLabel>
                        <TextField
                            name="stakeAmount"
                            placeholder="Stake amount"
                            value={filterInputs.stakeAmount}
                            onChange={filterInputsUpdate}
                            variant="outlined"
                        />
                    </div>
                    <div className="d-flex flex-column align-items-start mr-2 mb-2">
                        <InputLabel>
                            Times
                        </InputLabel>
                        <TextField
                            name="times"
                            placeholder="Times"
                            value={filterInputs.times}
                            onChange={filterInputsUpdate}
                            variant="outlined"
                        />
                    </div>
                    <Button onClick={handleReset} className="mx-2 my-2">
                        Sıfırla
                    </Button>
                </div>
                <div
                    style={{gap: "8px"}}
                    className="d-flex align-items-center pb-5 justify-content-between"
                >
                    <div className="col-2 col-md-2 col-lg-2">
                        <FormControl className="w-100">
                            <InputLabel>Filter</InputLabel>
                            <Select value={filter} onChange={handleChange}>
                                <MenuItem value={""}>All</MenuItem>
                                <MenuItem value={1}>Placement</MenuItem>
                                <MenuItem value={2}>Settlement</MenuItem>
                                <MenuItem value={3}>Payment</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-1 col-md-1 col-lg-1">
                        <FormControl className="w-100">
                            <InputLabel>Filter</InputLabel>
                            <Select value={statusId} onChange={handleChangeId}>
                                <MenuItem value={""}>All</MenuItem>
                                <MenuItem value={0}>Open</MenuItem>
                                <MenuItem value={2}>Won</MenuItem>
                                <MenuItem value={3}>Lost</MenuItem>
                                <MenuItem value={4}>Cancel</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-1 col-md-1 col-lg-1">
                        <FormControl className="w-100">
                            <InputLabel className="w-100">Paid status</InputLabel>
                            <Select value={isPaid} onChange={handleChangePaidStatus}>
                                <MenuItem value="">All</MenuItem>
                                <MenuItem value="true">Paid</MenuItem>
                                <MenuItem value="false">Not Paid</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-2 col-md-2 col-lg-2">
                        <InputLabel>Start date</InputLabel>
                        <DateTimePickerOutlined showTime value={startDate ? dayjs(startDate) : ""} onChange={handleChangeStartDate}/>
                    </div>
                    <div className="col-2 col-md-2 col-lg-2">
                        <InputLabel>End date</InputLabel>
                        <DateTimePickerOutlined showTime value={endDate ? dayjs(endDate) : ""} onChange={handleChangeEndDate}/>
                    </div>
                    <div className="col-2 col-md-2 col-lg-2">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={hideTestAgents}
                                    onChange={() => setHideTestAgents(prevState => !prevState)}
                                    color="secondary"
                                />
                            }
                            disabled={state.isLoading}
                            label="Test agentləri gizlə"
                            className="mb-0"
                        />
                    </div>
                    <RenderIf condition={state?.data?.reportList?.length}>
                        <div className="col-2 col-md-2 col-lg-2">
                            <Button onClick={getExcel} variant="outline-success">
                                Download excel
                            </Button>
                        </div>
                    </RenderIf>
                </div>
                <RenderIf condition={state.data?.reportList?.length}>
                    <Table
                        data={state.data}
                        isLoading={state.isLoading}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                        sortField={sortField}
                        setSortField={setSortField}
                        take={take}
                        setSkip={setSkip}
                        setTake={setTake}
                    />
                </RenderIf>
                <RenderIf condition={state.isLoading}>
                    <div className="d-flex justify-content-center">
                        <Loading/>
                    </div>
                </RenderIf>
                <RenderIf
                    condition={!state.isLoading && state.data?.reportList?.length === 0}
                >
                    <AlertComponent message="Məlumat tapılmadı" variant="info"/>
                </RenderIf>
                <RenderIf condition={!state.isLoading && state.error}>
                    <AlertComponent message="Xəta baş verdi" variant="danger"/>
                </RenderIf>
            </div>
        </div>
    );
}
