import React, {useState, useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {TextField} from "@material-ui/core";
import {
    FormControlLabel,
    Switch,
} from "@material-ui/core";
import {useSelector} from "react-redux";

import {useFormik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import RenderIf from "app/components/RenderIf";

const initialValues = {
    name: "",
    columnPrice: "",
    isMulti: false,
    firstPlaceRatio: "",
    secondPlaceRatio: "",
    thirdPlaceRatio: "",
    gameCount: ""
};

export default function Create(props) {
    const token = useSelector((state) => state.auth.token);

    const {
        show,
        onHide,
        onGroups,
        skip,
        take,
        orderBy,
        sortField,
        filterInputs,
    } = props;

    const [isError, setIsError] = useState("");

    const CreateSchema = Yup.object().shape({
        name: Yup.string().required("Məhsul adı daxil edin."),
        columnPrice: Yup.number().typeError("Rəqəm daxil edin.").required("Sütunlar üzrə əmsalı daxil edin."),
        gameCount: Yup.number().integer("Tam ədəd daxil edin").typeError("Rəqəm daxil edin.").required("Oyun sayı daxil edin."),
        firstPlaceRatio: Yup.number().typeError("Rəqəm daxil edin."),
        secondPlaceRatio: Yup.number().typeError("Rəqəm daxil edin."),
        thirdPlaceRatio: Yup.number().typeError("Rəqəm daxil edin."),
    });

    const getInputClasses = (filedName) => {
        if (formik.touched[filedName] && formik.errors[filedName]) {
            return true;
        }
        if (formik.touched[filedName] && !formik.errors[filedName]) {
            return false;
        }

        return false;
    };

    const onPostGroup = (values, resetForm, setSubmitting) => {
        const group = {
            name: values.name,
            columnPrice: values.columnPrice,
            gameCount: values.gameCount,
            isMulti: values.isMulti,
            ...(values.isMulti ? {
                firstPlaceRatio: parseFloat(values.firstPlaceRatio),
                secondPlaceRatio: parseFloat(values.secondPlaceRatio),
                thirdPlaceRatio: parseFloat(values.thirdPlaceRatio)
            } : {
                firstPlaceRatio: 0,
                secondPlaceRatio: 0,
                thirdPlaceRatio: 0
            })
        };
        axios({
            method: "post",
            url: `products`,
            data: group,
            headers: {
                Authorization: `bearer ${token}`,
            },
        })
            .then(() => {
                onGroups({...filterInputs, skip, take, orderBy, sortField});
                setSubmitting(false);
                resetForm();
                onHide(false);
            })
            .catch((err) => {
                let message =
                    typeof err.response !== "undefined"
                        ? err.response.data.message
                        : err.message;
                setIsError(message);
            });
    };

    const formik = useFormik({
        initialValues,
        validationSchema: CreateSchema,
        onSubmit: (values, {resetForm, setSubmitting}) => {
            onPostGroup(values, resetForm, setSubmitting);
        },
    });

    const resetForm = () => {
        onHide();
        formik.resetForm();
    };

    useEffect(() => {
        setIsError("");
    }, [show]);

    return (
        <Modal
            size="md"
            aria-labelledby="create"
            centered
            className="modal pr-0"
            show={show}
            onHide={resetForm}
        >
            <form onSubmit={formik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="create">Məhsul yarat</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">
                            <div className="col-6 col-md-6 col-lg-6">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    {isError && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block MuiFormLabel-root Mui-error">
                                                {isError}
                                            </div>
                                        </div>
                                    )}
                                    <TextField
                                        id="name"
                                        name="name"
                                        label="Məhsulun adı"
                                        placeholder="Məhsulun adı"
                                        className="w-100"
                                        error={getInputClasses("name")}
                                        {...formik.getFieldProps("name")}
                                    />
                                </div>
                                {formik.touched.name && formik.errors.name ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.name}</div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-6 col-md-6 col-lg-6">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    {isError && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block MuiFormLabel-root Mui-error">
                                                {isError}
                                            </div>
                                        </div>
                                    )}
                                    <TextField
                                        id="gameCount"
                                        name="gameCount"
                                        label="Oyun sayı"
                                        placeholder="Oyun sayı"
                                        className="w-100"
                                        error={getInputClasses("gameCount")}
                                        {...formik.getFieldProps("gameCount")}
                                    />
                                </div>
                                {formik.touched.gameCount && formik.errors.gameCount ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.gameCount}</div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-6 col-md-6 col-lg-6">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    {isError && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block MuiFormLabel-root Mui-error">
                                                {isError}
                                            </div>
                                        </div>
                                    )}
                                    <TextField
                                        id="columnPrice"
                                        name="columnPrice"
                                        label="Sütun dəyəri"
                                        placeholder="Sütun dəyəri"
                                        className="w-100"
                                        error={getInputClasses("columnPrice")}
                                        {...formik.getFieldProps("columnPrice")}
                                    />
                                </div>
                                {formik.touched.columnPrice && formik.errors.columnPrice ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.columnPrice}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="secondary"
                                            checked={formik.values.isMulti}
                                            onChange={(e, checked) =>
                                                formik.setFieldValue("isMulti", checked)
                                            }
                                        />
                                    }
                                    label={formik.values.isMulti ? "Çoxlu" : "Təkli"}
                                    className="ml-auto mb-0"
                                />
                            </div>
                            <RenderIf condition={formik.values.isMulti}>
                                <div className="col-6 col-md-6 col-lg-6">
                                    <TextField
                                        id="firstPlaceRatio"
                                        name="firstPlaceRatio"
                                        label="Birinci yer nisbəti"
                                        placeholder="First place ratio"
                                        className="w-100"
                                        error={getInputClasses("firstPlaceRatio")}
                                        {...formik.getFieldProps("firstPlaceRatio")}
                                    />
                                    {formik.touched.firstPlaceRatio &&
                                    formik.errors.firstPlaceRatio ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.firstPlaceRatio}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-6 col-md-6 col-lg-6">
                                    <TextField
                                        id="secondPlaceRatio"
                                        name="secondPlaceRatio"
                                        label="İkinci yer nisbəti"
                                        placeholder="Second place ratio"
                                        className="w-100"
                                        error={getInputClasses("secondPlaceRatio")}
                                        {...formik.getFieldProps("secondPlaceRatio")}
                                    />
                                    {formik.touched.secondPlaceRatio &&
                                    formik.errors.secondPlaceRatio ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.secondPlaceRatio}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-6 col-md-6 col-lg-6">
                                    <TextField
                                        id="thirdPlaceRatio"
                                        name="thirdPlaceRatio"
                                        label="Üçüncü yer nisbəti"
                                        placeholder="Third place ratio"
                                        className="w-100"
                                        error={getInputClasses("thirdPlaceRatio")}
                                        {...formik.getFieldProps("thirdPlaceRatio")}
                                    />
                                    {formik.touched.thirdPlaceRatio &&
                                    formik.errors.thirdPlaceRatio ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.thirdPlaceRatio}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </RenderIf>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                            formik.resetForm();
                        }}
                    >
                        Bağla
                    </Button>
                    <Button type="submit">Yarat</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
