import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles/index";
import Drawer from "@material-ui/core/Drawer/index";
import Divider from "@material-ui/core/Divider/index";
import CloseIcon from "@material-ui/icons/Close";
import Card from "@material-ui/core/Card/index";
import CardHeader from "@material-ui/core/CardHeader/index";
import { SportsSoccer } from "@material-ui/icons";
import { format } from "date-fns";
import {
  getTicketByIdAdmin,
} from "../requests";

const useStyles = makeStyles({
  list: {
    width: 400,
    height: "100vh",
  },
  card: {
    margin: "30px",
  },
  pointer: {
    cursor: "pointer",
  },
  progresRoot: {
    width: "100%",
    padding: "32px 16px",
  },
  progress: {
    margin: "auto",
    display: "block",
    color: "#e2241b",
  },
});

export default function View({ open, onClose, ticketId }) {
  const [ticketInformation, setTicketInformation] = useState({});
  const classes = useStyles();

  useEffect(() => {
    Promise.all([getTicketByIdAdmin(ticketId)])
      .then(([ticketRes]) => {
        setTicketInformation(ticketRes.data);
      })
      .catch(() => null);
  }, [ticketId]);

  // useEffect(() => {
  //   getCirculationGames(circulationId)
  //     .then((res) => {
  //       setCirculationData(res.data);
  //     })
  //     .catch(() => null);
  // }, [circulationId]);

  const sideList = () => (
    <div className={classes.list}>
      <div className="col-12 d-flex align-items-center justify-content-between py-4">
        <h1 className="h3 mb-0">Bron Et Kupon məlumatları</h1>
        <CloseIcon className={classes.pointer} onClick={onClose} />
      </div>
      <Divider className="bg-dark" />
      <div>
        <div className="border-bottom d-flex justify-content-between py-2 px-4">
          <span>Kupon nömrəsi:</span>
          <span>{ticketInformation.ticketNumber}</span>
        </div>
        <div className="border-bottom d-flex justify-content-between py-2 px-4">
          <span>Yaradılma tarixi:</span>
          <span>
            {ticketInformation.createdDate &&
              format(
                new Date(ticketInformation.createdDate),
                "dd.MM.yyyy HH:mm"
              )}
          </span>
        </div>
        {/* <div className="border-bottom d-flex justify-content-between py-2 px-4">
          <span>Type:</span>
          <span>
            {ticketInformation?.ticketGames &&
              findTicketBetType(ticketInformation?.ticketGames)}
          </span>
        </div> */}
        <div className="border-bottom d-flex justify-content-between py-2 px-4">
          <span>Tur nömrəsi:</span>
          {<span>{ticketInformation.circulationNumber}</span>}
        </div>
        {/* <div className="border-bottom d-flex justify-content-between py-2 px-4">
          <span>Sütun dəyəri:</span>
          <span>{ticketInformation?.columnPrice}</span>
        </div> */}
        <div className="border-bottom d-flex justify-content-between py-2 px-4">
          <span>Misli:</span>
          <span>{ticketInformation.times}</span>
        </div>
        <div className="border-bottom d-flex justify-content-between py-2 px-4">
          <span>Kuponun dəyəri:</span>
          <span>{ticketInformation.price} AZN</span>
        </div>
        <div className="border-bottom d-flex justify-content-between py-2 px-4">
          <span>Kuponun dəyəri:</span>
          <span>
            {ticketInformation.customerId === null ? "Agent" : "Müştəri"}
          </span>
        </div>
        <div className="border-bottom d-flex justify-content-between py-2 px-4">
          <span>Kuponun statusu:</span>
          {
            <span>
              {ticketInformation.isPaid ? "Ödənilmişdir" : "Gözləmədə"}
            </span>
          }
        </div>
      </div>
      <div className="py-2 px-4">
        <span>Seçimlər:</span>
      </div>
      {ticketInformation?.ticketGames?.map((match) => (
        <div key={match.gameId}>
          <Card className={classes.card}>
            <CardHeader
              title={`${match.hostClub} - ${match.guestClub}`}
              className="bg-secondary text-dark h6 p-3"
              disableTypography
            />

            <CardHeader
              avatar={<SportsSoccer />}
              title="Proqnoz:"
              subheader={
                <span>{`${match.options.option1 ? 1 : ""}${
                  match.options.optionX ? "X" : ""
                }${match.options.option2 ? 2 : ""}`}</span>
              }
              className="p-3"
            />
            {/* <CardHeader
              avatar={<BsStopwatch style={{ width: "20px", height: "20px" }} />}
              title="Nəticə:"
              subheader={
                <span>
                  {getCirculationGameResult(
                    circulationData.circulationGames,
                    match.gameId
                  )}
                </span>
              }
              className="p-3"
            /> */}
          </Card>
        </div>
      ))}
    </div>
  );

  return (
    <Drawer
      open={open}
      onClose={() => onClose(false)}
      anchor="right"
      className="drawer--fixed"
    >
      {sideList("right")}
    </Drawer>
  );
}
