import React from "react";
import {DatePicker, ConfigProvider} from "antd";
import {makeStyles} from "@material-ui/core/styles";
import locale from 'antd/locale/az_AZ';
import dayjs from "dayjs";
import 'dayjs/locale/az';

dayjs.locale('az-az');

const useStyles = makeStyles({
    root: {
        padding: '14px',
        borderRadius: '4px',
        '&:hover': {
            borderColor: "rgba(0, 0, 0, 0.87)"
        }
    }
})
const DateTimePickerOutlined = ({onChange, showTime = false, ...props}) => {
    const classes = useStyles();
    return (
        <ConfigProvider locale={locale}>
            <DatePicker
                rootClassName={classes.root}
                showTime={showTime}
                onChange={(value, dateString) => {
                    onChange(dateString);
                }}
                {...props}
            />
        </ConfigProvider>
    )
}

export default DateTimePickerOutlined