import React, {useEffect, useState} from "react";
import DataTables from "./components/RolesTable";
import Edit from "./modals/Edit";
import Delete from "./modals/Remove";
import Create from "./modals/Create";
import Divider from "@material-ui/core/Divider/index";
import axios from "axios";
import {useSelector} from "react-redux";
import {updateObject} from "../../../helpers/updateObject";

function Products() {
    const token = useSelector((state) => state.auth.token);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [sortField, setSortField] = useState("");
    const [orderBy, setOrderBy] = useState(false);
    const [groups, setGroups] = useState([]);
    const [chooseGroupId, setChooseGroupId] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [deleteGroupsIds, setDeleteGroupsIds] = useState([]);
    const [showCreate, setShowCreate] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [showEdit, setShowEdit] = useState(false);


    const [filterInputs, setFilterInputs] = useState({
        name: "",
    });

    const onGroups = () => {

        setIsLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}roles`, {
                headers: {Authorization: `bearer ${token}`},
            })
            .then(({data}) => {
                setIsLoading(false);
                setGroups(data);
                setIsError(false);
            })
            .catch(() => {
                setIsLoading(false);
                setGroups([]);
                setIsError(true);
            });
    };


    const onGroupChoose = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}roles/getByIdForUpdate/${chooseGroupId}`, {
            headers: {Authorization: `bearer ${token}`},
        });
    };

    const filterInputsUpdate = (e) => {
        const updateFiltersInput = updateObject(filterInputs, {
            [e.target.name]: e.target.value
        });

        setFilterInputs(updateFiltersInput);
        setSkip(0);
        // setSubmit(true)
    };

    useEffect(() => {
        const getAllTimer = setTimeout(() => {
            onGroups({...filterInputs, skip, take, orderBy, sortField});
        }, 500)

        return () => {
            clearTimeout(getAllTimer)
        }
    }, [filterInputs, skip, take, orderBy, sortField]);

    return (
        <div className="row bg-white rounded">
            <div className="col-12 d-flex align-items-center py-4">
                <h1 className="display-4 mb-0">Rollar</h1>

            </div>
            <Divider className="w-100"/>
            <DataTables
                groups={groups}
                isLoading={isLoading}
                isError={isError}
                setChooseGroupId={setChooseGroupId}
                setShowCreate={setShowCreate}
                setShowRemove={setShowRemove}
                setShowEdit={setShowEdit}
                setDeleteGroupsIds={setDeleteGroupsIds}
                deleteGroupsIds={deleteGroupsIds}
                sortField={sortField}
                setSortField={setSortField}
                setOrderBy={setOrderBy}
                setSkip={setSkip}
                setTake={setTake}
                take={take}
                orderBy={orderBy}
                totalCount={totalCount}
            />
            {showCreate && (
                <Create
                    show={showCreate}
                    onHide={setShowCreate}
                    onGroups={onGroups}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                    filterInputs={filterInputs}
                />
            )}

            {showEdit && (
                <Edit
                    show={showEdit}
                    onHide={setShowEdit}
                    onGroups={onGroups}
                    chooseGroupId={chooseGroupId}
                    onGroupChoose={onGroupChoose}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                    filterInputs={filterInputs}
                    id={chooseGroupId}
                />
            )}

            {showRemove && (
                <Delete
                    id={chooseGroupId}
                    show={showRemove}
                    onHide={setShowRemove}
                    onGroups={onGroups}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                    filterInputs={filterInputs}
                />
            )}
        </div>
    );
}

export default Products;
