import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles/index";
import Drawer from "@material-ui/core/Drawer/index";
import Divider from "@material-ui/core/Divider/index";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import "../../../../_metronic/_assets/plugins/flaticon2/flaticon.css";
import {format} from "date-fns"

const useStyles = makeStyles({
    list: {
        width: 400,
        height: "100vh",
    },
    card: {
        margin: "30px",
    },
    pointer: {
        cursor: "pointer",
    },
    progresRoot: {
        width: "100%",
        padding: "32px 16px",
    },
    progress: {
        margin: "auto",
        display: "block",
        color: "#e2241b",
    },
});

export default function Reasons({open, onClose, reasons = []}) {
    const [values, setValue] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);


    const classes = useStyles();

    const sideList = () => (
        <div className={classes.list} role="presentation">
            <div className="col-12 d-flex align-items-center justify-content-between py-4">
                <h1 className="h3 mb-0">Səbəblər</h1>
                <CloseIcon className={classes.pointer} onClick={onClose}/>
            </div>
            <Divider className="bg-dark"/>
            {reasons.map(item => (
                <div key={item.id} className="py-2 px-4">
                    <div>
                        <span className={`${item.action === 'Activated' ? 'text-info' : 'text-primary'} mr-2`}><InfoIcon/></span>
                        <span><b>{item.action}</b></span>
                    </div>
                    <div>
                        <div><b>İstifadəçi:</b> {item.actionUserName}</div>
                        <div><b>Səbəb:</b> {item.reason}</div>
                        <div className="d-block ml-auto">
                            <b>Tarix:</b> {item.actionDateTime && format(new Date(item.actionDateTime), 'dd.MM.yyyy HH:mm')}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <Drawer
            open={open}
            onClose={onClose}
            anchor="right"
            className="drawer--fixed"
        >
            {sideList("right")}
        </Drawer>
    );
}
