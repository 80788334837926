import React, {useState, useEffect} from "react";
import axios from "axios";
import AlertComponent from "_metronic/_helpers/AlertComponent";
import RenderIf from "app/components/RenderIf";
import Loading from "app/components/Loading";
import Table from "./components/Table";
import {FormControlLabel, InputLabel, Switch, TextField} from "@material-ui/core";
import {Button} from "react-bootstrap";
import {objectToQueryString} from "../../../_metronic/_helpers";

const initialFilter = {
    agentId: "",
    amount: "",
    transactionStatus: ""
}

export default function DebitCreditInterimReport() {
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [orderBy, setOrderBy] = useState(false);
    const [sortField, setSortField] = useState("");
    const [filter, setFilter] = useState(1);
    const [statusId, setStatusId] = useState(0);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [hideTestAgents, setHideTestAgents] = useState(true);
    const [state, setState] = useState({
        isLoading: true,
        error: null,
        data: null,
    });
    const [filterInputs, setFilterInputs] = useState(initialFilter);

    const filterInputsUpdate = (e) => {
        e.persist();
        setFilterInputs(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }));
        setSkip(0);
    }
    async function getData() {
        try {
            setState(prevState => ({
                ...prevState, isLoading: true
            }))
            const query = objectToQueryString({skip, take, sortField, orderBy, hideTestAgents, ...filterInputs})
            const {data} = await axios({
                method: "GET",
                url: `reports/debitcreditinterim?${query}`,
            });
            setState({
                isLoading: false,
                error: null,
                data
            })
        } catch (err) {
            setState({
                isLoading: false,
                error: err?.response?.data,
                data: null
            })
        }

    }

    async function getExcel() {
        try {
            const query = objectToQueryString({sortField, orderBy, hideTestAgents, ...filterInputs})
            const {data} = await axios({
                url: `reports/debitcreditinterim/excel?${query}`,
                method: 'GET',
                responseType: 'blob'
            })
            const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = 'DebitCreditInterimReport.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (err) {

        }
    }

    function handleChange(event) {
        setFilter(event.target.value);
    }

    function handleChangeId(e) {
        setStatusId(e.target.value);
    }

    function handleChangeStartDate(e) {
        setStartDate(e.target.value)
    }

    function handleChangeEndDate(e) {
        setEndDate(e.target.value)
    }

    function handleReset() {
        setFilterInputs(initialFilter)
    }


    useEffect(() => {
        getData();
    }, [orderBy, sortField, skip, take, hideTestAgents, filterInputs])

    return (
        <div className="row bg-white rounded">
            <div className="col-12 py-4 overflow-auto">
                <div className="col-12 d-flex flex-wrap align-items-center py-4">
                    <div className="d-flex flex-column align-items-start mr-2 mb-2">
                        <InputLabel>
                            Agent number
                        </InputLabel>
                        <TextField
                            name="agentId"
                            placeholder="Agent number"
                            value={filterInputs.agentId}
                            onChange={filterInputsUpdate}
                            variant="outlined"
                        />
                    </div>
                    <div className="d-flex flex-column align-items-start mr-2 mb-2">
                        <InputLabel>
                            Amount
                        </InputLabel>
                        <TextField
                            name="amount"
                            placeholder="Amount"
                            value={filterInputs.amount}
                            onChange={filterInputsUpdate}
                            variant="outlined"
                        />
                    </div>
                    <Button onClick={handleReset} className="mx-2 my-2">
                        Sıfırla
                    </Button>
                </div>
                <RenderIf condition={state?.data}>
                    <div className="d-flex justify-content-between">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={hideTestAgents}
                                    onChange={() => setHideTestAgents(prevState => !prevState)}
                                    color="secondary"
                                />
                            }
                            disabled={state.isLoading}
                            label="Test agentləri gizlə"
                            className="mb-0"
                        />
                        <div className="d-flex justify-content-end mb-4">
                            <Button onClick={getExcel} variant="outline-success">Download excel</Button>
                        </div>
                    </div>
                </RenderIf>
                <RenderIf condition={state.data?.data?.length}>
                    <Table
                        data={state.data}
                        isLoading={state.isLoading}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                        sortField={sortField}
                        setSortField={setSortField}
                        take={take}
                        setSkip={setSkip}
                        setTake={setTake}
                    />

                </RenderIf>
                <RenderIf condition={state.isLoading}>
                    <div className="d-flex justify-content-center">
                        <Loading/>
                    </div>
                </RenderIf>
                <RenderIf condition={!state.isLoading && state.data?.reportList?.length === 0}>
                    <AlertComponent message="Məlumat tapılmadı" variant="info"/>
                </RenderIf>
                <RenderIf condition={!state.isLoading && state.error}>
                    <AlertComponent message="Xəta baş verdi" variant="danger"/>
                </RenderIf>
            </div>
        </div>
    )
}