import React from "react";
import { Modal, Button } from "react-bootstrap";

import axios from "axios";
import { useSelector } from "react-redux";

export default function Remove(props) {
  const token = useSelector((state) => state.auth.token);

  const { onHide, show, id, onGroups, skip, take, filterInputs, sortField, orderBy } = props;

  const onDeleteUser = (e) => {
    e.preventDefault();

    axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}circulations?id=${id}`,
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then((res) => {
        onGroups({...filterInputs, skip, take, orderBy});
        onHide();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
      <form onSubmit={onDeleteUser}>
        <Modal.Header closeButton>
          <Modal.Title id="delete">Sil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 pl-4 pr-4">
            <div className="row">Silməyinizə əminsiniz?</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => onHide(false)}>
            Ləğv
          </Button>
          <Button type="submit">Sil</Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
