import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import {getUser} from './authCrud'

export const actionTypes = {
    Login: "[Login] Action",
    Logout: "[Logout] Action",
    LogoutRequest: "[Request Logout] Action",
    Register: "[Register] Action",
    UserRequested: "[Request User] Action",
    UserLoaded: "[Load User] Auth API",
    SetUser: "[Set User] Action",
    RefreshToken: "[Refresh Token] Action",
    Permission: "[Permission] Action"
};

const initialAuthState = {
    token: undefined,
    refreshToken: undefined,
    user: {token: "", refreshToken: "", permissions: undefined},
    permissions: undefined
};

export const reducer = persistReducer(
    {
        storage,
        key: "Admin",
        whitelist: ["token", "refreshToken", "user", "permissions"],
    },
    (state = initialAuthState, action) => {
        switch (action.type) {
            case actionTypes.Login: {
                const {accessToken, refreshToken} = action.payload;
                return {
                    ...state,
                    token: accessToken,
                    refreshToken,
                };
            }

            case actionTypes.Register: {
                const {accessToken} = action.payload;

                return {token: accessToken, user: undefined};
            }

            case actionTypes.Logout: {
                return initialAuthState;
            }

            case actionTypes.UserLoaded: {
                const {user} = action.payload;
                return {...state, user};
            }

            case actionTypes.SetUser: {
                const {user} = action.payload;
                return {...state, user};
            }


            case actionTypes.RefreshToken: {
                const {accessToken, refreshToken, expiredDate} = action.payload;

                return {
                    token: accessToken,
                    refreshToken: refreshToken,
                    user: {
                        ...state.user,
                        accessToken,
                        refreshToken,
                        expiredDate
                    }
                };
            }

            case actionTypes.Permission: {
                const {permissions} = action.payload;

                return {
                    ...state, permissions
                }
            }

            default:
                return state;
        }
    }
);

export const actions = {
    login: (accessToken, refreshToken) => ({
        type: actionTypes.Login,
        payload: {accessToken, refreshToken},
    }),

    register: (accessToken) => ({
        type: actionTypes.Register,
        payload: {accessToken},
    }),
    logout: () => ({
        type: actionTypes.Logout,
    }),

    requestUser: (user) => ({
        type: actionTypes.UserRequested,
        payload: {user},
    }),
    fulfillUser: (user) => ({
        type: actionTypes.UserLoaded,
        payload: {user},
    }),
    setUser: (user) => ({type: actionTypes.SetUser, payload: {user}}),
    setRefreshToken: (accessToken, refreshToken, expiredDate) => ({
        type: actionTypes.RefreshToken,
        payload: {accessToken, refreshToken, expiredDate},
    }),
    setPermission: (permissions) => ({
        type: actionTypes.Permission,
        payload: { permissions },
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.UserRequested, function* () {
        try {
            const {data} = yield getUser();
            yield put(actions.setPermission(data.permissions));
            yield put(actions.fulfillUser(data));
        } catch (err) {

        }
    });
}
