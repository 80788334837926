import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles/index";
import Drawer from "@material-ui/core/Drawer/index";
import Divider from "@material-ui/core/Divider/index";
import CloseIcon from "@material-ui/icons/Close";
import Card from "@material-ui/core/Card/index";
import CardHeader from "@material-ui/core/CardHeader/index";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import "../../../../_metronic/_assets/plugins/flaticon2/flaticon.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Alert} from "react-bootstrap";
import {format} from "date-fns"

const useStyles = makeStyles({
    list: {
        width: 400,
        height: "100vh",
    },
    card: {
        margin: "30px",
    },
    pointer: {
        cursor: "pointer",
    },
    progresRoot: {
        width: "100%",
        padding: "32px 16px",
    },
    progress: {
        margin: "auto",
        display: "block",
        color: "#e2241b",
    },
});

export default function View(props) {
    const {open, onClose, onGroupChoose, chooseGroupId} = props;
    const [values, setValue] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        onGroupChoose(chooseGroupId)
            .then(({data: {games}}) => {
                setValue(games);
                setIsLoading(false);
                setIsError(false);
            })
            .catch(() => {
                setIsLoading(false);
                setIsError(true);
            });
    }, [chooseGroupId]);

    const classes = useStyles();

    const sideList = () => (
        <div className={classes.list} role="presentation">
            <div className="col-12 d-flex align-items-center justify-content-between py-4">
                <h1 className="h3 mb-0">Turun nəticələri</h1>
                <CloseIcon className={classes.pointer} onClick={onClose}/>
            </div>
            <Divider className="bg-dark"/>

            {isLoading ? (
                <div className={classes.progresRoot}>
                    <CircularProgress className={classes.progress}/>
                </div>
            ) : !isLoading && !isError ?
                values.map(game => (
                    <div key={game.id}>
                        <Card className={classes.card}>
                            <CardHeader
                                title={`${game.hostClubName} - ${game.guestClubName}`}
                                className="bg-secondary text-dark h6 p-3"
                                disableTypography
                            />

                            <CardHeader
                                avatar={
                                    <SVG
                                        src={toAbsoluteUrl(
                                            "/media/svg/icons/Code/Time-schedule.svg"
                                        )}
                                        className="icon-primary"
                                    />
                                }
                                title="Oyunun başlama tarixi:"
                                subheader={game.startDate ? format(new Date(game.startDate), 'dd MM yyyy HH:mm') : ""}
                                className="p-3"
                            />

                            <CardHeader
                                avatar={
                                    <SVG
                                        src={toAbsoluteUrl(
                                            "/media/svg/icons/Code/info-circle.svg"
                                        )}
                                        className="icon-primary"
                                    />
                                }
                                title="Oyunun nəticəsi:"
                                subheader={game.gameOptionId === 3 ? "Məlum deyil" : game.gameOptionId === 0 ? game.hostClubName : game.gameOptionId === 2 ? game.guestClubName : game.gameOptionId === 1 ? "Heç-heçə" : game.gameOptionId === 4 && "Təxirə salınıb"}
                                className="p-3"
                            />
                        </Card>
                    </div>
                ))
                : (
                    <div className="p-5">
                        <Alert variant="info">Xəta baş verdi.</Alert>
                    </div>
                )}
        </div>
    );

    return (
        <Drawer
            open={open}
            onClose={onClose}
            anchor="right"
            className="drawer--fixed"
        >
            {sideList("right")}
        </Drawer>
    );
}
