import React, {useState} from "react";
import {Table} from "react-bootstrap";
import {makeStyles, TablePagination} from "@material-ui/core";
import {BiSort, BiSortUp, BiSortDown} from "react-icons/bi";


const useStyles = makeStyles({
    tableHead: {
        opacity: "0.8",
        userSelect: "none",
        cursor: "pointer",
        "&:hover": {
            opacity: "unset",
        }
    },
    tableHeadSelected: {
        opacity: "1",
        userSelect: "none",
        cursor: "pointer"
    },
    tableHeadInner: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
});

export const Icon = ({sortField, orderBy, fieldName}) => {
    if (sortField === fieldName && orderBy) {
        return <BiSortUp/>
    } else if (sortField === fieldName && !orderBy) {
        return <BiSortDown/>
    } else {
        return <BiSort/>
    }
};

const tableHeadList = [
    {
        id: "agentNumber",
        label: "Agent Code"
    },
    {
        id: "agentName",
        label: "Agent Full Name"
    },
    {
        id: "taxationNumber",
        label: "Taxation number"
    },
    {
        id: "dateTime",
        label: "Date time"
    },
    {
        id: "grossSales",
        label: "Gross sales"
    },
    {
        id: "refundableAmount",
        label: "Refundable amount"
    },
    {
        id: "paidRefunds",
        label: "Paid refunds"
    },
    {
        id: "grossAndRefundDifference",
        label: "Gross and refund difference"
    },
    {
        id: "corporateTax",
        label: "Corporate tax"
    },
    {
        id: "netSales",
        label: "Net sales"
    },
    {
        id: "salesCommission",
        label: "Sales commission"
    },
    {
        id: "refundableCommission",
        label: "Refundable commission"
    },
    {
        id: "grossPayments",
        label: "Gross payments"
    },
    {
        id: "winningTax",
        label: "Winning tax"
    },
    {
        id: "netPayments",
        label: "Net payments"
    },
    {
        id: "commissionOnPayments",
        label: "Commission on payments"
    },
    {
        id: "overdueInterest",
        label: "Overdue interest"
    },
    {
        id: "interimPayments",
        label: "InterimPayments"
    },
    {
        id: "debitCreditBalance",
        label: "Debit credit balance"
    }
];

export default function ReportsTable({data, getData, orderBy, setOrderBy, sortField, setSortField, take, setTake, setSkip, totalCount}) {
    const classes = useStyles();

    const [page, setPage] = useState(0);


    function handleSort(fieldName) {
        const order = fieldName === sortField && !orderBy
        setSortField(fieldName);
        setOrderBy(order);
        getData(fieldName, orderBy)
    }

    function handleChangePage(e, page) {
        setPage(page);
        const tempSkip = page > 0 ? page * take : 0;
        setSkip(tempSkip)
    }

    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                {tableHeadList.map(({id, label}) => (
                    <th key={id} onClick={() => handleSort(id)}
                        className={sortField === id ? classes.tableHeadSelected : classes.tableHead}>
                        <div className={classes.tableHeadInner}>
                            {label}
                            <Icon
                                sortField={sortField}
                                fieldName={id}
                                orderBy={orderBy}
                            />
                        </div>
                    </th>
                ))}
            </tr>
            </thead>
            <tbody>
            {data?.map(ticket => (
                <tr key={ticket?.id}>
                    <td>{ticket?.agentNumber}</td>
                    <td>{ticket?.agentName}</td>
                    <td>{ticket?.taxationNumber}</td>
                    <td>{ticket?.dateTime}</td>
                    <td>{ticket?.grossSales}</td>
                    <td>{ticket?.refundableAmount}</td>
                    <td>{ticket?.paidRefunds}</td>
                    <td>{ticket?.grossAndRefundDifference}</td>
                    <td>{ticket?.corporateTax}</td>
                    <td>{ticket?.netSales}</td>
                    <td>{ticket?.salesCommission}</td>
                    <td>{ticket?.refundableCommission}</td>
                    <td>{ticket?.grossPayments}</td>
                    <td>{ticket?.winningTax}</td>
                    <td>{ticket?.netPayments}</td>
                    <td>{ticket?.commissionOnPayments}</td>
                    <td>{ticket?.overdueInterest}</td>
                    <td>{ticket?.interimPayments}</td>
                    <td>{ticket?.debitCreditBalance}</td>

                </tr>
            ))}
            </tbody>
            <tfoot>
            <tr>
                <th>Agent Code</th>
                <th>Agent Full Name</th>
                <th>Settlement date</th>
                <th>Payment date</th>
                <th>Ticket ID</th>
                <th>Gross winning amount</th>
                <th>Net winning amount</th>
                <th>Win tax amount</th>
                <th>Stake amount</th>
                <th>Winning ratio</th>
            </tr>
            <tr>
                <TablePagination
                    count={totalCount}
                    page={page}
                    rowsPerPage={take}
                    onChangeRowsPerPage={(e) => setTake(e.target.value)}
                    onChangePage={handleChangePage}
                />
            </tr>
            </tfoot>
        </Table>
    )
}