import React from "react";
import {Modal, Button} from "react-bootstrap";
import {useFormik} from "formik";
import * as Yup from "yup";
import {TextField} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import {changePassword} from "../requests";

export default function ChangePassword({onHide, show, id}) {
    const formik = useFormik({
        initialValues: {
            id: "",
            password: "",
            showPassword: false
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_-])(?=.{8,})/,
                    "Şifrə böyük hərf, kiçik hərf, rəqəm və xarakterdən(!@#$%^&*()_-) ibarət olmalıdır."
                )
                .required("Şifrə tələb olunur")
        }),
        onSubmit: async (values) => {
            await changePassword({id, password: values.password});
            onHide(false);
        }
    })

    return (
        <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="delete">Şifrəni yenilə</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">
                            <TextField
                                placeholder="Şifrə"
                                type={formik.values.showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                name="showPassword"
                                                aria-label="toggle password visibility"
                                                onClick={() => formik.setFieldValue('showPassword', !formik.values.showPassword)}
                                                edge="end"
                                            >
                                                {formik.values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                name="password"
                                {...formik.getFieldProps("password")}
                            />
                            {formik.touched.password && formik.errors.password ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.password}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => onHide(false)}>
                        Bağla
                    </Button>
                    <Button disabled={formik.isSubmitting} type="submit">Şifrəni yenilə</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
