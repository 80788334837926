import React from "react";

const PaymentIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M17.4406 4.00001L16.7188 3.28126C16.425 2.98751 16.425 2.51251 16.7188 2.22189C17.0125 1.93126 17.4875 1.92814 17.7781 2.22189L19.7781 4.22189C19.9188 4.36251 19.9969 4.55314 19.9969 4.75314C19.9969 4.95314 19.9188 5.14376 19.7781 5.28439L17.7781 7.28439C17.4844 7.57814 17.0094 7.57814 16.7188 7.28439C16.4281 6.99064 16.425 6.51564 16.7188 6.22501L17.4375 5.50626H15.4187L5 5.50001V6.00001C5 7.10314 4.10313 8.00001 3 8.00001H2.5V10.9219L1.58438 11.8344L1 12.4219V6.00001C1 4.89689 1.89688 4.00001 3 4.00001H11.9875H12H17.4406ZM17.5 12V9.07814L18.4156 8.16251L19 7.57814V14C19 15.1031 18.1031 16 17 16H4.57812H2.55938L3.27813 16.7188C3.57188 17.0125 3.57188 17.4875 3.27813 17.7781C2.98438 18.0688 2.50938 18.0719 2.21875 17.7781L0.21875 15.7813C0.078125 15.6406 0 15.45 0 15.25C0 15.05 0.078125 14.8594 0.21875 14.7188L2.21875 12.7188C2.5125 12.425 2.9875 12.425 3.27813 12.7188C3.56875 13.0125 3.57188 13.4875 3.27813 13.7781L2.55938 14.4969L8 14.5C8.00313 14.5 8.00937 14.5 8.0125 14.5H15V14C15 12.8969 15.8969 12 17 12H17.5ZM10 7.00001C10.7956 7.00001 11.5587 7.31608 12.1213 7.87869C12.6839 8.4413 13 9.20436 13 10C13 10.7957 12.6839 11.5587 12.1213 12.1213C11.5587 12.6839 10.7956 13 10 13C9.20435 13 8.44129 12.6839 7.87868 12.1213C7.31607 11.5587 7 10.7957 7 10C7 9.20436 7.31607 8.4413 7.87868 7.87869C8.44129 7.31608 9.20435 7.00001 10 7.00001Z"/>
        </svg>
    )
}

export default PaymentIcon