import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as groups from "../_metronic/_partials/dashboards/_redux/groupsRedux";
import {snackbarReducer} from "../app/components/Snackbar/snackbarReducer";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  groups: groups.reducer,
  snackbar: snackbarReducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
