import axios from "axios";

const REPRINT_URL = `${process.env.REACT_APP_API_URL}tickets/reprint`;
const GET_TICKETS = `${process.env.REACT_APP_API_URL}tickets/filter`;
const GET_TICKET = `${process.env.REACT_APP_API_URL}tickets/byId`;
const GET_TICKET_ADMIN = `${process.env.REACT_APP_API_URL}tickets/byIdForAdmin`;
const GET_PRINTS = `${process.env.REACT_APP_API_URL}tickets/getPrints`;
const GET_CIRCULATION_GAMES = `${process.env.REACT_APP_API_URL}circulations/allGames`;
const CANCEL_TICKET_URL = `${process.env.REACT_APP_API_URL}tickets/cancelTicketAdmin?id=`

const reprint = (body) => axios.put(REPRINT_URL, body);

const getTickets = (query) => axios.get(`${GET_TICKETS}?${query}`);

const getTicketById = (ticketId) => axios.get(`${GET_TICKET}?id=${ticketId}`);
const getTicketByIdAdmin = (ticketId) =>
  axios.get(`${GET_TICKET_ADMIN}?id=${ticketId}`);

const getPrints = (ticketId) => axios.get(`${GET_PRINTS}?ticketId=${ticketId}`);
const getPrintsAdmin = (ticketId) =>
  axios.get(`${GET_PRINTS}?byIdForAdmin=${ticketId}`);

const getCirculationGames = (id) =>
  axios.get(GET_CIRCULATION_GAMES, { params: { id } });

const cancelTicket = (id) => (
    axios.put(`${CANCEL_TICKET_URL}${id}`)
)

export {
  reprint,
  getTickets,
  getTicketById,
  getPrints,
  getCirculationGames,
  getPrintsAdmin,
  getTicketByIdAdmin,
  cancelTicket
};
