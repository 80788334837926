import React, {useState, useEffect} from "react";
import {TextField, InputLabel, FormControlLabel, Switch} from "@material-ui/core";
import {Button} from "react-bootstrap";
import RenderIf from "app/components/RenderIf";
import Loading from "app/components/Loading";
import Table from "./components/Table";
import axios from "axios";
import {objectToQueryString} from "../../../_metronic/_helpers";
import {DateTimePickerOutlined} from "../../components/DatePicker";
import dayjs from "dayjs";

const date = new Date();

const initialStartDate = date.toISOString().split('T')[0];
const initialEndDate = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate() + 1).toISOString().split('T')[0];

const initialFilter = {
    agentId: "",
    ticketId: "",
    netWinningAmount: ""
};

export default function DailyExpiredReport() {
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [orderBy, setOrderBy] = useState(false);
    const [sortField, setSortField] = useState("");
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);
    const [hideTestAgents, setHideTestAgents] = useState(true);
    const [state, setState] = useState({
        isLoading: false,
        error: null,
        data: null,
    });
    const [filterInputs, setFilterInputs] = useState(initialFilter);

    async function getData() {
        if (startDate && endDate) {
            setState({
                isLoading: true,
                error: null,
                data: null
            });
            try {
                const query = objectToQueryString({
                    orderBy,
                    sortField,
                    startDate: `${startDate}T00:00`,
                    endDate: `${endDate}T00:00`,
                    hideTestAgents,
                    skip,
                    take,
                    ...filterInputs
                })
                const {data} = await axios({
                    method: "GET",
                    url: `reports/dailyexpired?${query}`,
                });
                setState({
                    isLoading: false,
                    error: null,
                    data
                })
            } catch (err) {
                setState({
                    isLoading: false,
                    error: err?.response?.data,
                    data: null
                })
            }
        }
    }

    async function getExcel() {
        try {
            const query = objectToQueryString({
                orderBy,
                sortField,
                startDate: `${startDate}T00:00`,
                endDate: `${endDate}T00:00`,
                hideTestAgents,
                ...filterInputs
            })
            const {data} = await axios({
                url: `reports/dailyexpired/excel?${query}`,
                method: 'GET',
                responseType: 'blob'
            })
            const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = 'BigWinReport.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (err) {

        }
    }

    function handleChangeStartDate(e) {
        setStartDate(e)
    }

    function handleChangeEndDate(e) {
        setEndDate(e)
    }

    const filterInputsUpdate = (e) => {
        e.persist();
        setFilterInputs(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }));
        setSkip(0);
    }

    function handleReset() {
        setFilterInputs(initialFilter);
        setStartDate(initialStartDate);
        setEndDate(initialEndDate);
    }

    useEffect(() => {
        getData()
    }, [skip, take, orderBy, sortField, startDate, endDate, hideTestAgents, filterInputs])

    return (
        <div className="row bg-white rounded">
            <div className="col-12 py-4 overflow-auto">
                <div className="col-12 d-flex flex-wrap align-items-center py-4">
                    <div className="d-flex flex-column align-items-start mr-2 mb-2">
                        <InputLabel>
                            Agent number
                        </InputLabel>
                        <TextField
                            name="agentId"
                            placeholder="Agent number"
                            value={filterInputs.agentId}
                            onChange={filterInputsUpdate}
                            variant="outlined"
                        />
                    </div>
                    <div className="d-flex flex-column align-items-start mr-2 mb-2">
                        <InputLabel>
                            Ticket ID
                        </InputLabel>
                        <TextField
                            name="ticketId"
                            placeholder="Ticket ID"
                            value={filterInputs.ticketId}
                            onChange={filterInputsUpdate}
                            variant="outlined"
                        />
                    </div>
                    <div className="d-flex flex-column align-items-start mr-2 mb-2">
                        <InputLabel>
                            Net winning amount
                        </InputLabel>
                        <TextField
                            name="netWinningAmount"
                            placeholder="Net winning amount"
                            value={filterInputs.netWinningAmount}
                            onChange={filterInputsUpdate}
                            variant="outlined"
                        />
                    </div>
                    <div className="d-flex flex-column align-items-start mr-2 mb-2">
                        <InputLabel>
                            Start date
                        </InputLabel>
                        <DateTimePickerOutlined
                            value={startDate ? dayjs(startDate) : ""}
                            onChange={handleChangeStartDate}
                            type="date"
                        />
                    </div>
                    <div className="d-flex flex-column align-items-start mr-2 mb-2">
                        <InputLabel>
                            End date
                        </InputLabel>
                        <DateTimePickerOutlined
                            value={endDate ? dayjs(endDate) : ""}
                            onChange={handleChangeEndDate}
                            type="date"
                        />
                    </div>
                    <Button onClick={handleReset} className="mx-2 my-2">
                        Sıfırla
                    </Button>
                </div>
                <div className="row pb-5">
                    <div className="col-3 col-md-3 col-lg-3">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={hideTestAgents}
                                    onChange={() => setHideTestAgents(prevState => !prevState)}
                                    color="secondary"
                                />
                            }
                            disabled={state.isLoading}
                            label="Test agentləri gizlə"
                            className="mb-0"
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-3 d-flex align-items-end justify-content-between">
                        <RenderIf condition={state?.data?.length}>
                            <Button onClick={getExcel} variant="outline-success">Download excel</Button>
                        </RenderIf>
                    </div>
                </div>
                <RenderIf condition={state?.data?.length && !state?.isLoading}>
                    <Table
                        sortField={sortField}
                        orderBy={orderBy}
                        setSortField={setSortField}
                        setOrderBy={setOrderBy}
                        data={state?.data}
                        getData={getData}
                        take={take}
                        setSkip={setSkip}
                        setTake={setTake}
                    />
                </RenderIf>
                <RenderIf condition={state?.isLoading}>
                    <div className="d-flex justify-content-center">
                        <Loading/>
                    </div>
                </RenderIf>
            </div>
        </div>
    )
}