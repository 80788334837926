import React, {useEffect, useState} from "react";
import DataTables from "./components/CirculationsTables";
import CheckGames from "./modals/CheckGames";
import CheckTickets from "./modals/CheckTickets";
import Results from "./modals/Results";
import Edit from "./modals/Edit";
import Delete from "./modals/Remove";
import Create from "./modals/Create";
import View from "./modals/View";
import Reasons from "./modals/Reasons";
import CirculationActivation from "./modals/CirculationActivation";
import Divider from "@material-ui/core/Divider/index";
import axios from "axios";
import {useSelector} from "react-redux";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Filter from "./modals/Filter";
import {updateObject} from "../../../helpers/updateObject";

function Circulations() {
    const token = useSelector((state) => state.auth.token);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [sortField, setSortField] = useState("record_date_time");
    const [orderBy, setOrderBy] = useState(false);
    const [groups, setGroups] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [chooseGroupId, setChooseGroupId] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [deleteGroupsIds, setDeleteGroupsIds] = useState([]);
    const [showCreate, setShowCreate] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [showCheckGames, setShowCheckGames] = useState(false);
    const [showCheckTickets, setShowTickets] = useState(false);
    const [showCirculationActivation, setShowCirculationActivation] = useState(false);
    const [showReasons, setShowReasons] = useState(false);
    const [reasons, setReasons] = useState([]);
    const [isActivate, setIsActivate] = useState(false);
    const [results, setResults] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [showView, setShowView] = useState(false);
    const [circulationNumber, setCirculationNumber] = useState();
    const [filterInputs, setFilterInputs] = useState({
        code: "",
        resultedDate: "",
        expiredDate: "",
        status: "",
        productName: "",
        totalAmount: "",
        possibleSeeTotalAmount: "",
    });

    const handleReset = () => {
        setFilterInputs({
            code: "",
            resultedDate: "",
            expiredDate: "",
            status: "",
            productName: "",
            totalAmount: "",
            possibleSeeTotalAmount: "",
        })
    }

    const toggleDrawer = (open) => (event) => {
        setShowView(open);
    };

    function handleChange() {
        setShowFilter((key) => !key);
    }

    const onGroups = (filter = {}) => {
        const {
            skip,
            take,
            orderBy,
            sortField,
            code,
            resultedDate,
            expiredDate,
            status,
            productName,
            totalAmount,
            possibleSeeTotalAmount,
        } = filter;
        setIsLoading(true);
        axios
            .get(
                `${
                    process.env.REACT_APP_API_URL
                }circulations/filterForAdmin?Skip=${skip}&Take=${take}&OrderBy=${orderBy}${
                    sortField ? `&SortField=${sortField}` : ""
                }${code ? `&Code=${code}` : ""}${
                    resultedDate ? `&ResultedDate=${resultedDate}` : ""
                }${expiredDate ? `&ExpiredDate=${expiredDate}` : ""}${
                    status === true ? `&Status=${status}` : status === false ? `&Status=${status}` : ""
                }${productName ? `&ProductName=${productName}` : ""}${
                    totalAmount ? `&TotalAmount=${totalAmount}` : ""
                }${
                    possibleSeeTotalAmount === true
                        ? `&PossibleSeeTotalAmount=${possibleSeeTotalAmount}`
                        : possibleSeeTotalAmount === false
                            ? `&PossibleSeeTotalAmount=${possibleSeeTotalAmount}`
                            : ""
                }`,
                {
                    headers: {Authorization: `bearer ${token}`},
                }
            )
            .then(({data: {data, totalCount}}) => {
                setIsLoading(false);
                setGroups(data);
                setTotalCount(totalCount);
                setIsError(false);
            })
            .catch(() => {
                setIsLoading(false);
                setGroups([]);
                setTotalCount(0);
                setIsError(true);
            });
    };

    const onGroupChoose = (id) => {
        return axios.get(
            `${process.env.REACT_APP_API_URL}circulations/circulationGames?id=${id}`,
            {
                headers: {Authorization: `bearer ${token}`},
            }
        );
    };

    const onCirculationChoose = (id) => {
        return axios.get(
            `${process.env.REACT_APP_API_URL}circulations/id?id=${id}`,
            {
                headers: {Authorization: `bearer ${token}`},
            }
        );
    };

    const filterInputsUpdate = (e) => {
        const updateFiltersInput = updateObject(filterInputs, {
            [e.target.name]: e.target.value,
        });

        setFilterInputs(updateFiltersInput);
        setSkip(0);
        // setSubmit(true)
    };

    useEffect(() => {
        const getAllTimer = setTimeout(() => {
            onGroups({...filterInputs, skip, take, orderBy, sortField});
        }, 500);

        return () => {
            clearTimeout(getAllTimer);
        };
    }, [filterInputs, skip, take, orderBy, sortField]);

    return (
        <div className="row bg-white rounded">
            <div className="col-12 d-flex align-items-center py-4">
                <h1 className="display-4 mb-0">Turlar</h1>
                <FormControlLabel
                    control={
                        <Switch
                            checked={showFilter}
                            onChange={handleChange}
                            color="secondary"
                        />
                    }
                    // label={showFilter ? "Filtri gizlət" : "Filtri göstər"}
                    label="Filter"
                    className="ml-auto mb-0"
                />
            </div>
            <Divider className="w-100"/>
            <DataTables
                groups={groups}
                onGroups={onGroups}
                isLoading={isLoading}
                isError={isError}
                setChooseGroupId={setChooseGroupId}
                setShowCreate={setShowCreate}
                setShowRemove={setShowRemove}
                setShowCheckGames={setShowCheckGames}
                setShowEdit={setShowEdit}
                setOpenView={toggleDrawer(true)}
                setDeleteGroupsIds={setDeleteGroupsIds}
                deleteGroupsIds={deleteGroupsIds}
                sortField={sortField}
                setSortField={setSortField}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                totalCount={totalCount}
                skip={skip}
                setSkip={setSkip}
                take={take}
                setTake={setTake}
                setCirculationNumber={setCirculationNumber}
                setShowCirculationActivation={setShowCirculationActivation}
                setIsActivate={setIsActivate}
                setShowReasons={setShowReasons}
                setReasons={setReasons}
            />
            {showCreate && (
                <Create show={showCreate} onHide={setShowCreate} onGroups={onGroups} skip={skip} take={take}
                        orderBy={orderBy} sortField={sortField}/>
            )}

            {showCheckGames && (
                <CheckGames
                    setShowTickets={setShowTickets}
                    circulationId={chooseGroupId}
                    show={showCheckGames}
                    onHide={setShowCheckGames}
                    circulationNumber={circulationNumber}
                />
            )}

            {showCheckTickets && (
                <CheckTickets
                    onGroups={onGroups}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    setShowCheckGames={setShowCheckGames}
                    circulationId={chooseGroupId}
                    show={showCheckTickets}
                    onHide={setShowTickets}
                    setShowResults={setShowResults}
                    setResults={setResults}
                    sortField={sortField}
                />
            )}

            {showResults && (
                <Results groups={results} show={showResults} onHide={setShowResults} isLoading={isLoading}
                         isError={isError}/>
            )}

            {showEdit && (
                <Edit
                    show={showEdit}
                    onHide={setShowEdit}
                    onGroups={onGroups}
                    chooseGroupId={chooseGroupId}
                    onGroupChoose={onCirculationChoose}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                    filterInputs={filterInputs}
                />
            )}

            {showView && (
                <View
                    open={showView}
                    onClose={toggleDrawer(false)}
                    onGroupChoose={onGroupChoose}
                    chooseGroupId={chooseGroupId}
                />
            )}

            {showRemove && (
                <Delete
                    id={chooseGroupId}
                    show={showRemove}
                    onHide={setShowRemove}
                    onGroups={onGroups}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                    filterInputs={filterInputs}
                />
            )}
            {showCirculationActivation && (
                <CirculationActivation
                    show={showCirculationActivation}
                    onHide={setShowCirculationActivation}
                    circulationId={chooseGroupId}
                    isActivate={isActivate}
                    refetch={() => onGroups({skip, take, sortField, orderBy})}
                />
            )}
            {showReasons && (
                <Reasons
                    open={showReasons}
                    onClose={() => setShowReasons(false)}
                    reasons={reasons}
                />
            )}
            <Filter
                show={showFilter}
                onHide={handleChange}
                filterInputsUpdate={filterInputsUpdate}
                filterInputs={filterInputs}
                resetFilter={handleReset}
            />
        </div>
    );
}

export default Circulations;
