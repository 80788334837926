import React, {useState} from "react";
import {Table} from "react-bootstrap";
import {makeStyles, TablePagination} from "@material-ui/core";
import {BiSort, BiSortUp, BiSortDown} from "react-icons/bi";


const useStyles = makeStyles({
    tableHead: {
        opacity: "0.8",
        userSelect: "none",
        cursor: "pointer",
        "&:hover": {
            opacity: "unset",
        }
    },
    tableHeadSelected: {
        opacity: "1",
        userSelect: "none",
        cursor: "pointer"
    },
    tableHeadInner: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
});

export const Icon = ({sortField, orderBy, fieldName}) => {
    if (sortField === fieldName && orderBy) {
        return <BiSortUp/>
    } else if (sortField === fieldName && !orderBy) {
        return <BiSortDown/>
    } else {
        return <BiSort/>
    }
};

const tableHeadList = [
    {
        id: "agentCreatedBet",
        label: "Agent created bet"
    },
    {
        id: "agentPaidBet",
        label: "Agent paid bet"
    },
    {
        id: "betId",
        label: "Bet ID"
    },
    {
        id: "ticketNumber",
        label: "Ticket number"
    },
    {
        id: "drawNumber",
        label: "Round number"
    },
    {
        id: "bookABetId",
        label: "Book a bet ID"
    },
    {
        id: "programType",
        label: "Program type"
    },
    {
        id: "ticketStatus",
        label: "Ticket status"
    },
    {
        id: "betStatus",
        label: "Bet status"
    },
    {
        id: "creationDateTime",
        label: "Creation Date & Time"
    },
    {
        id: "settlementDateTime",
        label: "Settlement Date & Time"
    },
    {
        id: "paymentDateTime",
        label: "Payment Date & Time"
    },
    {
        id: "stakeAmount",
        label: "Stake amount"
    },
    {
        id: "grossPaymentAmount",
        label: "Gross payment amount"
    },
    {
        id: "netPaymentAmount",
        label: "Net payment amount"
    },
    {
        id: "winningTaxAmount",
        label: "Winning tax amount"
    },
    {
        id: "betType",
        label: "Bet type"
    },
    {
        id: "selections",
        label: "Selections"
    },
    {
        id: "times",
        label: "Times"
    },
];

const format = (value) => {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    };
    const formatter = new Intl.DateTimeFormat('az-Az', options);
    if(value) {
        return formatter.format(new Date(value));
    }
    return null
}

export default function ReportsTable({data, orderBy, setOrderBy, sortField, setSortField, take, setSkip, setTake}) {
    const [page, setPage] = useState(0);

    const classes = useStyles();

    function handleSort(fieldName) {
        const order = fieldName === sortField && !orderBy
        setSortField(fieldName);
        setOrderBy(order);
    }

    function handleChangePage(e, page) {
        setPage(page);
        const tempSkip = page > 0 ? page * take : 0;
        setSkip(tempSkip);
    }

    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                {tableHeadList.map(({id, label}) => (
                    <th key={id} onClick={() => handleSort(id)}
                        className={sortField === id ? classes.tableHeadSelected : classes.tableHead}>
                        <div className={classes.tableHeadInner}>
                            {label}
                            <Icon
                                sortField={sortField}
                                fieldName={id}
                                orderBy={orderBy}
                            />
                        </div>
                    </th>
                ))}
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Total</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{data?.stakeTotal}</td>
                <td>{data?.grossPaymentTotal}</td>
                <td>{data?.netPaymentTotal}</td>
                <td>{data?.winningTaxTotal}</td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            {data?.reportList?.map((ticket, index) => (
                <tr key={index}>
                    <td>{ticket?.agentCreatedBet}</td>
                    <td>{ticket?.agentPaidBet}</td>
                    <td>{ticket?.betId}</td>
                    <td>{ticket?.ticketNumber}</td>
                    <td>{ticket?.drawNumber}</td>
                    <td>{ticket?.bookABetId}</td>
                    <td>{ticket?.programType}</td>
                    <td>{ticket?.ticketStatus}</td>
                    <td>{ticket?.betStatus}</td>
                    <td>{format(ticket?.creationDateTime)}</td>
                    <td>{format(ticket?.settlementDateTime)}</td>
                    <td>{format(ticket?.paymentDateTime)}</td>
                    <td>{ticket?.stakeAmount}</td>
                    <td>{ticket?.grossPaymentAmount}</td>
                    <td>{ticket?.netPaymentAmount}</td>
                    <td>{ticket?.winningTaxAmount}</td>
                    <td>{ticket?.betType}</td>
                    <td>{ticket?.selections}</td>
                    <td>{ticket?.times}</td>
                </tr>
            ))}
            </tbody>
            <tfoot>
            <tr>
                {tableHeadList.map(item => (
                    <td key={item.id}>{item.label}</td>
                ))}
            </tr>
            <tr>
                <TablePagination
                    count={data?.count}
                    page={page}
                    rowsPerPage={take}
                    onChangeRowsPerPage={(e) => setTake(e.target.value)}
                    onChangePage={handleChangePage}
                />
            </tr>
            </tfoot>
        </Table>
    )
}