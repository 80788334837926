import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import { FormControlLabel, Switch } from "@material-ui/core";
import { useSelector } from "react-redux";

import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import RenderIf from "app/components/RenderIf";

const initialValues = {
  gameCount: "",
};

export default function Remove(props) {
  const { show, onHide, ticketIdRemove, onGroups, skip, take, orderBy } = props;
  const [isError, setIsError] = useState("");

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/tickets/cancelTicketAdmin?id=${ticketIdRemove}`
        )
        .then(() => {
          onGroups({ skip, take, orderBy });
          onHide(false);
        })
        .catch(
          ({
            response: {
              data: { checks },
            },
          }) => {
            setIsError(true);
          }
        );
    },
  });

  const resetForm = () => {
    onHide();
    formik.resetForm();
  };

  useEffect(() => {
    setIsError("");
  }, [show]);

  return (
    <Modal
      size="md"
      aria-labelledby="create"
      centered
      className="modal pr-0"
      show={show}
      onHide={resetForm}
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Ləvğ etməyə əminsiniz?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
            }}
          >
            Xeyr
          </Button>
          <Button type="submit">Bəli</Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
