import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
// eslint-disable-next-line no-restricted-imports
import AlertComponent from "../../../../_metronic/_helpers/AlertComponent";
import {lighten, makeStyles} from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PlusIcon from "@material-ui/icons/Add";
import {azAZ} from '@material-ui/core/locale';
import {useSelector} from "react-redux";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Paper,
    Tooltip,
    CircularProgress, TableSortLabel,
    createMuiTheme,
    ThemeProvider,
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";

const theme = createMuiTheme(azAZ);

const headRows = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Rol",
    },
    {
        id: "operations",
        numeric: false,
        disablePadding: true,
        label: "Əməliyyatlar",
    },
];

function EnhancedTableHead({onRequestSort, sortField, orderBy}) {
    return (
        <TableHead>
            <TableRow>
                {headRows.map((row, index) => {
                    if (row.id === "operations") {
                        return (
                            <TableCell key={index}
                                       align={row.numeric ? "right" : "left"}>
                                <div>
                                    {row.label}
                                </div>
                            </TableCell>
                        )
                    } else {
                        return (
                            <TableCell sortDirection={sortField === row.id ? "asc" : false} key={index}
                                       align={row.numeric ? "right" : "left"}>
                                <TableSortLabel active={sortField === row.id} direction={orderBy ? "desc" : "asc"}
                                                onClick={() => {
                                                    onRequestSort(row.id)
                                                }}>
                                    {row.label}
                                </TableSortLabel>
                            </TableCell>
                        )
                    }
                })}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        justifyContent: "flex-end",
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: "0 0 auto",
    },
    dense: {
        margin: theme.spacing(2),
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const {numSelected, setShowCreate, Role_Create} = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >

            <div className={classes.actions}>
                {Role_Create && (
                    <Tooltip title="Yarat" placement="top">
                        <button
                            className="flex-shrink-0 btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary mr-2 h5 rounded-circle flex-shrink-0"
                            onClick={() => setShowCreate(true)}
                        >
                            <PlusIcon/>
                        </button>
                    </Tooltip>
                )}
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3),
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: "auto",
    },
    progresRoot: {
        width: "100%",
        padding: "32px 16px",
    },
    progress: {
        margin: "auto",
        display: "block",
        color: "#e2241b",
    },
}));

export default function EnhancedTable({
                                          groups,
                                          isLoading,
                                          isError,
                                          setText,
                                          setChooseGroupId,
                                          setShowCreate,
                                          setShowRemove,
                                          setShowRemoveGroups,
                                          setShowEdit,
                                          deleteGroupsIds,
                                          setDeleteGroupsIds,
                                          sortField,
                                          setSortField,
                                          orderBy,
                                          setOrderBy,
                                          setSkip,
                                          setTake,
                                          take,
                                          totalCount
                                      }) {
    const classes = useStyles();

    const {Role_Create, Role_Update, Role_Delete} = useSelector(state => state.auth.user.permissions)

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = groups.map((n) => n.id);
            setDeleteGroupsIds(newSelecteds);
            return;
        }
        setDeleteGroupsIds([]);
    }

    const isSelected = (name) => deleteGroupsIds.indexOf(name) !== -1;

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleRequestSort = (field) => {

        const isOrder = (field === sortField && !orderBy)

        setSortField(field);
        setOrderBy(isOrder);
        setTake(10);
        setRowsPerPage(10);
        setSkip(0);
        setPage(0);

    };


    function handleChangePage(event, newPage) {
        setPage(newPage);
        setSkip(take * newPage);
    };

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setTake(+event.target.value);
        setPage(0);
        setSkip(0);
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    numSelected={deleteGroupsIds.length}
                    setText={setText}
                    setShowCreate={setShowCreate}
                    setShowRemoveGroups={setShowRemoveGroups}
                    Role_Create={Role_Create}
                />
                {isLoading && (
                    <div className={classes.progresRoot}>
                        <CircularProgress className={classes.progress}/>
                    </div>
                )}
                <div className={classes.tableWrapper}>
                    {groups.length ? (
                        <Table className={classes.table} aria-labelledby="tableTitle">
                            <EnhancedTableHead
                                numSelected={deleteGroupsIds.length}
                                onSelectAllClick={handleSelectAllClick}
                                rowCount={groups.length}
                                onRequestSort={handleRequestSort}
                                sortField={sortField}
                                orderBy={orderBy}
                            />
                            <TableBody>
                                {groups.map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={index}
                                            selected={isItemSelected}
                                        >
                                            <TableCell component="th" id={labelId} scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {Role_Update && row.id !== "1" && (
                                                    <Tooltip title="Düzəliş et" placement="top">
                                                        <button
                                                            aria-label="edit"
                                                            className="btn btn-sm btn-icon btn-bg-light btn-text-dark btn-hover-dark mr-2"
                                                            onClick={() => {
                                                                setChooseGroupId(row.id);
                                                                setShowEdit(true);
                                                            }}
                                                        >
                                                            <EditIcon/>
                                                        </button>
                                                    </Tooltip>
                                                )}

                                                {Role_Delete && row.id !== "1" && (
                                                    <Tooltip title="Sil" placement="top">
                                                        <button
                                                            aria-label="Delete"
                                                            className="btn btn-sm btn-icon btn-bg-light btn-text-danger btn-hover-danger"
                                                            onClick={() => {
                                                                setChooseGroupId(row.id);
                                                                setShowRemove(true);
                                                            }}
                                                        >
                                                            <DeleteIcon/>
                                                        </button>
                                                    </Tooltip>
                                                )}
                                                {!Role_Update && !Role_Delete ? <div className="p-5"><span>-</span></div> : ""}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    ) : !groups.length && !isLoading && !isError ? (
                        <AlertComponent variant="info" message="Məlumat tapılmadı."/>
                    ) : (!isLoading && isError && (!isLoading && isError && (<AlertComponent
                        variant="danger"
                        message="Şəbəkəylə bağlantı kəsildi."
                    />)))}
                </div>
                {totalCount > 10 && (
                    <ThemeProvider theme={theme}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                "aria-label": "Previous Page",
                            }}
                            nextIconButtonProps={{
                                "aria-label": "Next Page",
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </ThemeProvider>
                )}
            </Paper>
        </div>
    );
}
