import React from "react";
import {Modal, Button} from "react-bootstrap";
import TextField from "@material-ui/core/TextField/index";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import dayjs from "dayjs";
import {DateTimePickerOutlined} from "../../../components/DatePicker";

export default function Filter({onHide, show, filterInputsUpdate, filterInputs, resetFilter, setFilterInputs}) {
    const [isWinningPaid, setIsWinningPaid] = React.useState(" ");
    const [isPaid, setIsPaid] = React.useState(" ");
    const [isPending, setIsPending] = React.useState(" ");
    const [ticketStatus, setTicketStatus] = React.useState(" ")
    const [open, setOpen] = React.useState(false);
    const [openPaid, setOpenPaid] = React.useState(false);
    const [openPendingStatus, setOpenPendingStatus] = React.useState(false);
    const [openTicketStatus, setOpenTicketStatus] = React.useState(false);

    const handleReset = () => {
        resetFilter();
        setIsWinningPaid(" ");
        setIsPaid(" ");
        setIsPending(" ");
        setTicketStatus(" ");
    }

    function handleChange(event, handlerFunc) {
        filterInputsUpdate(event);
        handlerFunc(event.target.value)
    }

    function handleClose(selectHandler) {
        selectHandler(false);
    }

    function handleOpen(selectHandler) {
        selectHandler(true);
    }

    return (
        <Modal
            size="sm"
            show={show}
            onHide={onHide}
            aria-labelledby="create"
            centered
            className="modal-right pr-0"
        >
            <Modal.Header closeButton>
                <Modal.Title id="create">Biletlər filteri</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-12 pl-4 pr-4">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="ticketNumber"
                                    name="ticketNumber"
                                    label="Bilet nömrəsi"
                                    placeholder="Bilet nömrəsi"
                                    className="w-100"
                                    value={filterInputs.ticketNumber}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <FormControl className="w-100">
                                <InputLabel htmlFor="role">Uduşun ödənilib/ödənməməsi</InputLabel>
                                <Select
                                    open={open}
                                    onClose={() => handleClose(setOpen)}
                                    onOpen={() => handleOpen(setOpen)}
                                    value={isWinningPaid}
                                    onChange={(e) => handleChange(e, setIsWinningPaid)}
                                    inputProps={{
                                        name: "winningPaidStatus",
                                        id: "winningPaidStatus",
                                    }}
                                >
                                    <MenuItem value="">Hamısı</MenuItem>
                                    <MenuItem value={true}>Ödənilmiş</MenuItem>
                                    <MenuItem value={false}>Ödənilməmiş</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="correctCount"
                                    name="correctCount"
                                    label="Dəqiq say"
                                    placeholder="Dəqiq say"
                                    className="w-100"
                                    value={filterInputs.correctCount}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <FormControl className="w-100">
                                <InputLabel htmlFor="role">Müştərinin ödənişi təsdiqi</InputLabel>
                                <Select
                                    open={openPendingStatus}
                                    onClose={() => handleClose(setOpenPendingStatus)}
                                    onOpen={() => handleOpen(setOpenPendingStatus)}
                                    value={isPending}
                                    onChange={(e) => handleChange(e, setIsPending)}
                                    inputProps={{
                                        name: "pendingStatusId",
                                        id: "pendingStatusId",
                                    }}
                                >
                                    <MenuItem value="">Hamısı</MenuItem>
                                    <MenuItem value={0}>Gözləmədə</MenuItem>
                                    <MenuItem value={2}>Ödənilmiş</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <InputLabel htmlFor="resultedDate">Yaradılma tarixi</InputLabel>
                                <DateTimePickerOutlined
                                    showTime
                                    onChange={value => setFilterInputs(prevState => ({
                                        ...prevState, createdDate: value.replace(" ", "T")
                                    }))}
                                    value={filterInputs.createdDate ? dayjs(filterInputs.createdDate) : ""}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="winningAmount"
                                    name="winningAmount"
                                    label="Uduş məbləği"
                                    placeholder="Uduş məbləği"
                                    className="w-100"
                                    value={filterInputs.winningAmount}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="circulationNumber"
                                    name="circulationNumber"
                                    label="Turun nömrəsi"
                                    placeholder="Turun nömrəsi"
                                    className="w-100"
                                    value={filterInputs.circulationNumber}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <FormControl className="w-100">
                                <InputLabel htmlFor="role">Biletin statusu</InputLabel>
                                <Select
                                    open={openTicketStatus}
                                    onClose={() => handleClose(setOpenTicketStatus)}
                                    onOpen={() => handleOpen(setOpenTicketStatus)}
                                    value={ticketStatus}
                                    onChange={(e) => handleChange(e, setTicketStatus)}
                                    inputProps={{
                                        name: "statusId",
                                        id: "statusId",
                                    }}
                                >
                                    <MenuItem value="">Hamısı</MenuItem>
                                    <MenuItem value={0}>Gözləmədə</MenuItem>
                                    <MenuItem value={2}>Udulan</MenuItem>
                                    <MenuItem value={3}>Uduzulan</MenuItem>
                                    <MenuItem value={4}>Ləğv edilmiş</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <FormControl className="w-100">
                                <InputLabel htmlFor="role">Biletin ödənilib/ödənməməsi</InputLabel>
                                <Select
                                    open={openPaid}
                                    onClose={() => handleClose(setOpenPaid)}
                                    onOpen={() => handleOpen(setOpenPaid)}
                                    value={isPaid}
                                    onChange={(e) => handleChange(e, setIsPaid)}
                                    inputProps={{
                                        name: "paid",
                                        id: "paid",
                                    }}
                                >
                                    <MenuItem value="">Hamısı</MenuItem>
                                    <MenuItem value={true}>Ödənilmiş</MenuItem>
                                    <MenuItem value={false}>Ödənilməmiş</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="price"
                                    name="price"
                                    label="Biletin qiyməti"
                                    placeholder="Biletin qiyməti"
                                    className="w-100"
                                    value={filterInputs.price}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="agentNumber"
                                    name="agentNumber"
                                    label="Agent nömrəsi"
                                    placeholder="Agent nömrəsi"
                                    className="w-100"
                                    value={filterInputs.agentNumber}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="customerNumber"
                                    name="customerNumber"
                                    label="Müştəri ID-si"
                                    placeholder="Müştəri ID-si"
                                    className="w-100"
                                    value={filterInputs.customerNumber}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <FormControl className="w-100">
                                    <InputLabel id="statusId">Status</InputLabel>
                                    <Select
                                        labelId="statusId"
                                        value={filterInputs.statusId}
                                        name="statusId"
                                        onChange={filterInputsUpdate}
                                    >
                                        <MenuItem value={0}>Açıq</MenuItem>
                                        <MenuItem value={4}>Ləğv edilib</MenuItem>
                                        <MenuItem value={2}>Uduşlu</MenuItem>
                                        <MenuItem value={3}>Uduşsuz</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <FormControl className="w-100">
                                <InputLabel variant="outlined" id="statusId">
                                    Misli
                                </InputLabel>
                                <Select
                                    label="Misli"
                                    placeholder="Misli"
                                    variant="outlined"
                                    id="times"
                                    labelId="times"
                                    value={filterInputs.times}
                                    name="times"
                                    onChange={filterInputsUpdate}
                                >
                                    <MenuItem value={null}>Hamısı</MenuItem>
                                    <MenuItem value={1}>1X</MenuItem>
                                    <MenuItem value={2}>2X</MenuItem>
                                    <MenuItem value={3}>3X</MenuItem>
                                    <MenuItem value={5}>5X</MenuItem>
                                    <MenuItem value={6}>6X</MenuItem>
                                    <MenuItem value={7}>7X</MenuItem>
                                    <MenuItem value={8}>8X</MenuItem>
                                    <MenuItem value={9}>9X</MenuItem>
                                    <MenuItem value={10}>10X</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleReset}>
                    Sıfırla
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
