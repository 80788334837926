/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {useEffect} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import {Layout} from "../_metronic/layout";
import BasePage from "./BasePage";
import {Logout, AuthPage} from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import * as auth from "./modules/Auth/_redux/authRedux";


export function Routes() {
    const {requestUser} = auth.actions;
    const dispatch = useDispatch();

    const {isAuthorized, isUserLoaded} = useSelector(
        ({auth}) => ({
            isAuthorized: auth?.token,
            isUserLoaded: Boolean(auth?.user?.name)
        }),
        shallowEqual
    );
    useEffect(() => {
        if (isAuthorized) {
            dispatch(requestUser())
        }
    }, [isAuthorized])

    return (
        <Switch>
            {!isUserLoaded ? (
                <Route>
                    <AuthPage/>
                </Route>
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from="/auth" to="/"/>
            )}

            <Route path="/error" component={ErrorsPage}/>
            <Route path="/logout" component={Logout}/>

            {isUserLoaded && (
                <Layout>
                    <BasePage/>
                </Layout>
            )}
        </Switch>
    );
}
