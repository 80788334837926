import React from "react";
import { Modal, Button } from "react-bootstrap";

import axios from "axios";
import { useDispatch } from "react-redux";
import {actions} from "app/components/Snackbar/snackbarReducer";

export default function UpdatePayment({ onHide, show, onGroups, skip, take, orderBy, sortField, filterInputs, isPaymentBlocked }) {
    const dispatch = useDispatch();

    const endpoint = isPaymentBlocked ? "startPayments" : "stopPayments"
    const onUpdate = (e) => {
        e.preventDefault();

        axios({
            method: "put",
            url: `agent/${endpoint}`,
        })
            .then(() => {
                onGroups({...filterInputs, skip, take, orderBy, sortField});
                dispatch(actions.setSnackbar({message: isPaymentBlocked ? "Uduş ödənişi aktivdir." : "Uduş ödənişi dayandırılmışdır."}))
                onHide();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <form onSubmit={onUpdate}>
                <Modal.Header closeButton>
                    <Modal.Title id="delete">{isPaymentBlocked ? "Uduş ödənişini aktivləşdir" : "Uduş ödənişini dayandır"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">{isPaymentBlocked ? "Uduş ödənişini aktivləşdirməyə əminsiniz?" : "Uduş ödənişini dayandırmağa əminsiniz?"}</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => onHide(false)}>
                        Ləğv
                    </Button>
                    <Button type="submit">Təsdiqlə</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
