import React, {useEffect, useState} from "react";
import DataTables from "./components/AgentsTable";
import Edit from "./modals/Edit";
import ChangePassword from "./modals/ChangePassword";
import Delete from "./modals/Remove";
import Create from "./modals/Create";
import Filter from "./modals/Filter";
import UpdateSales from "./modals/UpdateSales";
import UpdatePayment from "./modals/UpdatePayment";
import Divider from "@material-ui/core/Divider/index";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {updateObject} from "../../../helpers/updateObject";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import {Button} from "react-bootstrap";
import {objectToQueryString} from "../../../_metronic/_helpers";
import RenderIf from "../../components/RenderIf";
import {makeStyles} from "@material-ui/core/styles";
import {PaymentIcon, SalesIcon} from "../../components/Icons";

const useStyles = makeStyles({
    redButton: {
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #E2241B",
        marginInline: "4px",
        padding: "6px 12px",
        color: "#E2241B",
        fontWeight: 500,
        fontSize: "14px",
        '& svg': {
            fill: "#E2241B",
            marginRight: "6px"
        }
    },
    greenButton: {
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #40C4BF",
        marginInline: "4px",
        padding: "6px 12px",
        color: "#40C4BF",
        fontWeight: 500,
        fontSize: "14px",
        '& svg': {
            fill: "#40C4BF",
            marginRight: "6px"
        }
    }
})

function Agents() {
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [orderBy, setOrderBy] = useState(false);
    const [sortField, setSortField] = useState("record_date_time");
    const [groups, setGroups] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [chooseGroupId, setChooseGroupId] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(true);
    const [deleteGroupsIds, setDeleteGroupsIds] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showUpdateSales, setShowUpdateSales] = useState(false);
    const [showUpdatePayment, setShowUpdatePayment] = useState(false);
    const [cities, setCities] = useState([]);
    const [regions, setRegions] = useState([])
    const [filterInputs, setFilterInputs] = useState({
        name: "",
        number: "",
        deposit: "",
        debt: "",
        isActive: "all",
    });

    const isFrozenBySystem = groups[0]?.isFrozenBySystem;
    const isPaymentBlocked = groups[0]?.isPaymentBlocked;

    const handleReset = () => {
        setFilterInputs({
            name: "",
            number: "",
            deposit: "",
            debt: "",
            isActive: "",
        })
    }

    function handleChange() {
        setShowFilter((key) => !key);
    }

    const onGroups = (filter = {}) => {
        setIsLoading(true);

        const {isActive, ...rest} = filter;

        const query = objectToQueryString({...rest, isActive: isActive === 'all' ? "" : isActive});

        axios
            .get(`agent/filter?${query}`)
            .then(({data: {data, totalCount}}) => {
                setIsLoading(false);
                setGroups(data);
                setTotalCount(totalCount);
                setIsError(false);
            })
            .catch(() => {
                setIsLoading(false);
                setGroups([]);
                setIsError(true);
            });
    };

    const onGroupChoose = (id) => {
        return axios.get(`agent/byId?id=${id}`);
    };

    const getCities = () => {
        setIsLoading(true)
        axios.get(`cities/filter`)
            .then(({data: {data}}) => {
                setCities(data)
                setIsLoading(false)
            })
            .catch(() => {
                console.log("error")
            })
    };

    const getRegions = (cityId) => {
        if (cityId !== "") {
            axios.get(`regions/filter?CityId=${cityId}`)
                .then(({data: {data}}) => {

                    setRegions(data)
                })
                .catch(() => {
                    console.log("error")
                })
        } else {
            setRegions([])
        }
    }

    const filterInputsUpdate = (e) => {
        const updateFiltersInput = updateObject(filterInputs, {
            [e.target.name]: e.target.value
        });

        setFilterInputs(updateFiltersInput);
        setSkip(0);
    };

    useEffect(() => {
        const getAllTimer = setTimeout(() => {
            onGroups({...filterInputs, skip, take, orderBy, sortField});
        }, 500)

        return () => {
            clearTimeout(getAllTimer)
        }
    }, [filterInputs, skip, take, orderBy, sortField]);

    const classes = useStyles();

    return (
        <div className="row bg-white rounded">
            <div className="col-12 d-flex align-items-center py-4">
                <h1 className="display-4 mb-0">Agentlər</h1>
                <FormControlLabel
                    control={
                        <Switch
                            checked={showFilter}
                            onChange={handleChange}
                            color="secondary"
                        />
                    }
                    label="Filter"
                    className="ml-auto mb-0"
                />
            </div>
            <Divider className="w-100"/>
            <div className="col-12 d-flex flex-wrap align-items-center py-4">
                <TextField
                    name="number"
                    value={filterInputs.number}
                    onChange={filterInputsUpdate}
                    label="Agent ID"
                    placeholder="Agent ID"
                    variant="outlined"
                    className="mx-2 my-2"
                />
                <TextField
                    name="name"
                    value={filterInputs.name}
                    onChange={filterInputsUpdate}
                    label="Ad"
                    placeholder="Ad"
                    variant="outlined"
                    className="mx-2 my-2"
                />
                <TextField
                    name="deposit"
                    value={filterInputs.deposit}
                    onChange={filterInputsUpdate}
                    label="Depozit"
                    placeholder="Depozit"
                    variant="outlined"
                    className="mx-2 my-2"
                />
                <FormControl className="w-100px mx-2 my-2">
                    <InputLabel variant="outlined" id="statusId">Status</InputLabel>
                    <Select
                        label="Status"
                        placeholder="Status"
                        variant="outlined"
                        id="isActive"
                        labelId="isActive"
                        value={filterInputs.isActive}
                        name="isActive"
                        onChange={filterInputsUpdate}
                    >
                        <MenuItem value="all">Hamısı</MenuItem>
                        <MenuItem value="true">Aktiv</MenuItem>
                        <MenuItem value="false">Deaktiv</MenuItem>
                    </Select>
                </FormControl>
                <Button onClick={handleReset} className="mx-2 my-2">Sıfırla</Button>
            </div>
            <Divider className="w-100"/>
            <div className="w-100 d-flex justify-content-end py-4">
                <RenderIf condition={typeof isFrozenBySystem !== 'undefined'}>
                    <button
                        onClick={() => setShowUpdateSales(true)}
                        className={isFrozenBySystem ? classes.greenButton : classes.redButton}
                    >
                        <SalesIcon/>{isFrozenBySystem ? "Satışı aktivləşdir" : "Satışı dayandır"}
                    </button>
                </RenderIf>
                <RenderIf condition={typeof isPaymentBlocked !== 'undefined'}>
                    <button
                        onClick={() => setShowUpdatePayment(true)}
                        className={isPaymentBlocked ? classes.greenButton : classes.redButton}
                    >
                        <PaymentIcon/>
                        {isPaymentBlocked ? "Uduş ödənişini aktivləşdir" : "Uduş ödənişini dayandır"}
                    </button>
                </RenderIf>
            </div>
            <DataTables
                groups={groups}
                isLoading={isLoading}
                isError={isError}
                setChooseGroupId={setChooseGroupId}
                setShowCreate={setShowCreate}
                setShowRemove={setShowRemove}
                setShowEdit={setShowEdit}
                setDeleteGroupsIds={setDeleteGroupsIds}
                deleteGroupsIds={deleteGroupsIds}
                onGroups={onGroups}
                skip={skip}
                setSkip={setSkip}
                setTake={setTake}
                setOrderBy={setOrderBy}
                setSortField={setSortField}
                take={take}
                orderBy={orderBy}
                sortField={sortField}
                totalCount={totalCount}
                setShowChangePassword={setShowChangePassword}
                filterInputs={filterInputs}
            />
            {showCreate && (
                <Create
                    show={showCreate}
                    onHide={setShowCreate}
                    onGroups={onGroups}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                    filterInputs={filterInputs}
                    filterInputsUpdate={filterInputsUpdate}
                    cities={cities}
                    regions={regions}
                    setRegions={setRegions}
                    setCities={setCities}
                    getCities={getCities}
                    getRegions={getRegions}
                />
            )}

            {showEdit && (
                <Edit
                    show={showEdit}
                    onHide={setShowEdit}
                    onGroups={onGroups}
                    chooseGroupId={chooseGroupId}
                    onGroupChoose={onGroupChoose}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                    filterInputs={filterInputs}
                />
            )}
            {showChangePassword && (
                <ChangePassword
                    id={chooseGroupId}
                    show={showChangePassword}
                    onHide={setShowChangePassword}
                />
            )}
            {showRemove && (
                <Delete
                    id={chooseGroupId}
                    show={showRemove}
                    onHide={setShowRemove}
                    onGroups={onGroups}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                    filterInputs={filterInputs}
                />
            )}
            <Filter
                show={showFilter}
                onHide={handleChange}
                filterInputsUpdate={filterInputsUpdate}
                filterInputs={filterInputs}
                resetFilter={handleReset}
            />
            {
                showUpdateSales && (
                    <UpdateSales
                        filterInputs={filterInputs}
                        skip={skip}
                        take={take}
                        show={showUpdateSales}
                        onHide={setShowUpdateSales}
                        onGroups={onGroups}
                        orderBy={orderBy}
                        sortField={sortField}
                        isFrozenBySystem={isFrozenBySystem}
                    />
                )
            }
            {
                showUpdatePayment && (
                    <UpdatePayment
                        filterInputs={filterInputs}
                        skip={skip}
                        take={take}
                        show={showUpdatePayment}
                        onHide={setShowUpdatePayment}
                        onGroups={onGroups}
                        orderBy={orderBy}
                        sortField={sortField}
                        isPaymentBlocked={isPaymentBlocked}
                    />
                )
            }
        </div>
    );
}

export default Agents;
