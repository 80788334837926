import React, {useState} from "react";
import {Table} from "react-bootstrap";
import {makeStyles, TablePagination} from "@material-ui/core";
import {BiSort, BiSortUp, BiSortDown} from "react-icons/bi";


const useStyles = makeStyles({
    tableHead: {
        opacity: "0.8",
        userSelect: "none",
        cursor: "pointer",
        "&:hover": {
            opacity: "unset",
        }
    },
    tableHeadSelected: {
        opacity: "1",
        userSelect: "none",
        cursor: "pointer"
    },
    tableHeadInner: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
});

export const Icon = ({sortField, orderBy, fieldName}) => {
    if (sortField === fieldName && orderBy) {
        return <BiSortUp/>
    } else if (sortField === fieldName && !orderBy) {
        return <BiSortDown/>
    } else {
        return <BiSort/>
    }
};

const tableHeadList = [
    {
        id: "agentName",
        label: "Agent name"
    },
    {
        id: "agentNumber",
        label: "Agent number"
    },
    {
        id: "transactionDateTime",
        label: "Transaction date time"
    },
    {
        id: "amount",
        label: "Amount"
    },
    {
        id: "transactionType",
        label: "Transaction type"
    },
    {
        id: "transactionOnBank",
        label: "Transaction on bank"
    },
    {
        id: "source",
        label: "Source"
    },
    {
        id: "transactionStatus",
        label: "Transaction status"
    }
];

const format = (value) => {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    };
    const formatter = new Intl.DateTimeFormat('az-Az', options);
    if(value) {
        return formatter.format(new Date(value));
    }
    return null
}

export default function ReportsTable({data, orderBy, setOrderBy, sortField, setSortField, take, setSkip, setTake}) {
    const [page, setPage] = useState(0);
    const classes = useStyles();

    function handleSort(fieldName) {
        const order = fieldName === sortField && !orderBy
        setSortField(fieldName);
        setOrderBy(order);
    }

    function handleChangePage(e, page) {
        setPage(page);
        const tempSkip = page > 0 ? page * take : 0;
        setSkip(tempSkip)
    }

    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                {tableHeadList.map(({id, label}) => (
                    <th key={id} onClick={() => handleSort(id)}
                        className={sortField === id ? classes.tableHeadSelected : classes.tableHead}>
                        <div className={classes.tableHeadInner}>
                            {label}
                            <Icon
                                sortField={sortField}
                                fieldName={id}
                                orderBy={orderBy}
                            />
                        </div>
                    </th>
                ))}
            </tr>
            </thead>
            <tbody>
            {data?.data?.map((row, index) => (
                <tr key={index}>
                    <td>{row?.agentName}</td>
                    <td>{row?.agentNumber}</td>
                    <td>{row?.transactionDateTime && format(row?.transactionDateTime)}</td>
                    <td>{row?.amount}</td>
                    <td>{row?.transactionTypeString}</td>
                    <td>{row?.transactionOnBank}</td>
                    <td>{row?.source}</td>
                    <td>{row?.transactionStatusString}</td>
                </tr>
            ))}
            </tbody>
            <tfoot>
            <tr>
                {tableHeadList.map(item => (
                    <td key={item.id}>{item.label}</td>
                ))}
            </tr>
            <tr>
                <TablePagination
                    count={data?.count}
                    page={page}
                    rowsPerPage={take}
                    onChangeRowsPerPage={(e) => setTake(e.target.value)}
                    onChangePage={handleChangePage}
                />
            </tr>
            </tfoot>
        </Table>
    )
}