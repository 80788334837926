import React, {useState} from "react";
import {Table} from "react-bootstrap";
import {makeStyles, TablePagination} from "@material-ui/core";
import {BiSort, BiSortUp, BiSortDown} from "react-icons/bi";


const useStyles = makeStyles({
    tableHead: {
        opacity: "0.8",
        userSelect: "none",
        cursor: "pointer",
        "&:hover": {
            opacity: "unset",
        }
    },
    tableHeadSelected: {
        opacity: "1",
        userSelect: "none",
        cursor: "pointer"
    },
    tableHeadInner: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
});

export const Icon = ({sortField, orderBy, fieldName}) => {
    if (sortField === fieldName && orderBy) {
        return <BiSortUp/>
    } else if (sortField === fieldName && !orderBy) {
        return <BiSortDown/>
    } else {
        return <BiSort/>
    }
};

const tableHeadList = [
    {
        id: "agentNumber",
        label: "Agent code"
    },
    {
        id: "agentName",
        label: "Agent full name"
    },
    {
        id: "agentPermanentLimit",
        label: "Agent permanent limit"
    },
    {
        id: "remainingDebit",
        label: "Remaining debit"
    },
    {
        id: "weekStartLimit",
        label: "Week start limit"
    },
    {
        id: "interimCollections",
        label: "Interim collections"
    },
    {
        id: "weekStartAndInterim",
        label: "Week start and interim"
    },
    {
        id: "salesPerAgency",
        label: "Sales per agency"
    },
    {
        id: "paymentsPerAgency",
        label: "Payments per agency"
    },
    {
        id: "currentLimit",
        label: "Current limit"
    },
];

export default function ReportsTable({data, orderBy, setOrderBy, sortField, setSortField, setTake, take, setSkip}) {
    const [page, setPage] = useState(0);

    const classes = useStyles();

    function handleSort(fieldName) {
        const order = fieldName === sortField && !orderBy
        setSortField(fieldName);
        setOrderBy(order);
    }

    function handleChangePage(e, page) {
        setPage(page);
        const tempSkip = page > 0 ? page * take : 0;
        setSkip(tempSkip);
    }

    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                {tableHeadList.map(({id, label}) => (
                    <th key={id} onClick={() => handleSort(id)}
                        className={sortField === id ? classes.tableHeadSelected : classes.tableHead}>
                        <div className={classes.tableHeadInner}>
                            {label}
                            <Icon
                                sortField={sortField}
                                fieldName={id}
                                orderBy={orderBy}
                            />
                        </div>
                    </th>
                ))}
            </tr>
            </thead>
            <tbody>
            {data?.data?.map(ticket => (
                <tr key={ticket?.agentId}>
                    <td>{ticket?.agentNumber}</td>
                    <td>{ticket?.agentName}</td>
                    <td>{ticket?.agentPermanentLimit}</td>
                    <td>{ticket?.remainingDebit}</td>
                    <td>{ticket?.weekStartLimit}</td>
                    <td>{ticket?.interimCollections}</td>
                    <td>{ticket?.weekStartAndInterim}</td>
                    <td>{ticket?.salesPerAgency}</td>
                    <td>{ticket?.paymentsPerAgency}</td>
                    <td>{ticket?.currentLimit}</td>
                </tr>
            ))}

            </tbody>
            <tfoot>
            <tr>
                {tableHeadList.map(item => (
                    <th key={item.id}>{item.label}</th>
                ))}
            </tr>
            <tr>
                <TablePagination
                    count={data?.count}
                    page={page}
                    rowsPerPage={take}
                    onChangeRowsPerPage={(e) => setTake(e.target.value)}
                    onChangePage={handleChangePage}
                />
            </tr>
            </tfoot>
        </Table>
    )
}