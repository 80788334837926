import React from "react";
import {DatePicker, ConfigProvider} from "antd";
import locale from 'antd/locale/az_AZ';
import dayjs from "dayjs";
import 'dayjs/locale/az';

dayjs.locale('az-az');

const DateTimePicker = ({onChange, showTime = false, ...rest}) => {
    return (
        <ConfigProvider locale={locale}>
            <DatePicker
                showTime={showTime}
                onChange={(value, dateString) => {
                    onChange(dateString);
                }}
                {...rest}
            />
        </ConfigProvider>
    )
}

export default DateTimePicker