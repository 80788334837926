import React, {useEffect} from "react";
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import {Redirect} from "react-router-dom";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import {actions} from "app/modules/Auth/_redux/authRedux";

function Logout() {
    const hasAuthToken = useSelector(state => Boolean(state.auth.token), shallowEqual);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.logout())
    }, [])

    return hasAuthToken ? (
        <LayoutSplashScreen/>
    ) : (
        <Redirect to="/auth/login"/>
    )
}

export default Logout
