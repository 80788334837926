import React, {useState, useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {TextField} from "@material-ui/core";
import {useSelector} from "react-redux";

import {useFormik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import "../styles/Roles.css";

const initialValues = {
    name: "",
};

export default function Create(props) {
    const token = useSelector((state) => state.auth.token);

    const {show, onHide, onGroups, skip, take, orderBy, sortField, filterInputs} = props;

    const [isError, setIsError] = useState("");

    const CreateSchema = Yup.object().shape({
        name: Yup.string().required("Rol adı daxil edin."),
    });

    const getInputClasses = (filedName) => {
        if (formik.touched[filedName] && formik.errors[filedName]) {
            return true;
        }
        if (formik.touched[filedName] && !formik.errors[filedName]) {
            return false;
        }

        return false;
    };
    const [isDisabled, setIsDisabled] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [data, setData] = useState([]);

    const onPostGroup = (values, resetForm, setSubmitting) => {
        const group = {
            name: values.name,
            permissionIds: permissions
        };
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}roles/create`,
            data: group,
            headers: {
                Authorization: `bearer ${token}`,
            },
        })
            .then(() => {
                onGroups({...filterInputs, skip, take, orderBy, sortField});
                setSubmitting(false);
                resetForm();
                onHide(false);
            })
            .catch((err) => {
                let message =
                    typeof err.response !== "undefined"
                        ? err.response.data.message
                        : err.message;
                setIsError(message);
            });
    };


    const formik = useFormik({
        initialValues,
        validationSchema: CreateSchema,
        onSubmit: (values, {resetForm, setSubmitting}) => {
            onPostGroup(values, resetForm, setSubmitting);
        },
    });

    const resetForm = () => {
        onHide();
        formik.resetForm();
    };

    const getPermissions = () => {
        axios.get(`${process.env.REACT_APP_API_URL}roles/getPermissions`)
            .then(({data}) => {
                const separateIt = (arr, size) => {
                    var newArr = [];
                    for (var i = 0; i < arr.length; i += size) {
                        var sliceIt = arr.slice(i, i + size
                            )
                        ;
                        newArr.push(sliceIt);
                    }
                    return newArr;
                }
                console.log(separateIt(data, 3));
                setData(separateIt(data, 3))
            })
            .catch(() => {
                console.log("error")
            })
    }


    const handleChange = (e, id) => {
        let tempState = [...permissions];
        let updatedState = {}
        if (e.target.checked === true) {
            setPermissions([...tempState, id])
        } else {
            updatedState = tempState.filter(item => item !== id);
            setPermissions(updatedState !== undefined ? updatedState : [])
        }

    };

    const CanBeSubmit = () => {
        if (permissions.length) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }

    useEffect(() => {
        CanBeSubmit();
        console.log(permissions)
    }, [permissions]);

    useEffect(() => {
        getPermissions()
    }, [])

    return (
        <Modal
            size="lg"
            aria-labelledby="create"
            centered
            className="modal pr-0"
            show={show}
            onHide={resetForm}
        >
            <form onSubmit={formik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="create">Rol yarat</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    {isError && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block MuiFormLabel-root Mui-error">
                                                {isError}
                                            </div>
                                        </div>
                                    )}
                                    <TextField
                                        id="name"
                                        name="name"
                                        label="Rol adı"
                                        placeholder="Rol adı"
                                        className="w-100"
                                        error={getInputClasses("name")}
                                        {...formik.getFieldProps("name")}
                                    />
                                </div>
                                {formik.touched.name &&
                                formik.errors.name ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.name}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            {data.map((perm, index) => (
                                <div className="col-12 col-md-12 col-lg-12 d-flex flex-wrap mb-2" key={index}>
                                    {perm.map((innerPerm) => (
                                        <div className="col-4 col-md-4 col-lg-4" key={innerPerm.id}>
                                            <div className="permission px-2 h-100">
                                                <input id={innerPerm.id} type="checkbox" onChange={(e) => handleChange(e, innerPerm.id)}/>
                                                <label htmlFor={innerPerm.id}>{innerPerm.description}</label>
                                            </div>
                                        </div>
                                        )
                                        )}
                                        </div>
                                        ))}


                                </div>
                                </div>
                                </Modal.Body>
                                <Modal.Footer>
                                <Button
                                variant="light"
                                onClick={() => {
                                onHide(false);
                                formik.resetForm();
                            }}
                                >
                                Bağla
                                </Button>
                                <Button disabled={isDisabled} type="submit">Yarat</Button>
                                </Modal.Footer>
                                </form>
                                </Modal>
                                );
                            }
