import React, {Suspense} from "react";
import {shallowEqual, useSelector} from "react-redux";
import {Redirect, Switch} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import DashboardPage from "./pages/DashboardPage";
import CirculationsPage from "./pages/Circulations";
import ProductsPage from "./pages/Products";
import TicketsPage from "./pages/TicketsPage";
import AgentsPage from "./pages/AgentsPage";
import CustomersPage from "./pages/CustomersPage";
import RolesPage from "./pages/RolesPage";
import TaxAndCommissionsPage from "./pages/TaxAndCommissionsPage";
import TotalRemainingReportPage from "./pages/TotalRemainingReportPage";
import BigWinReportPage from "./pages/BigWinReportPage";
import DailyExpiredReportPage from "./pages/DailyExpiredReportPage";
import DailyReportPage from "./pages/DailyReportPage";
import TicketReportPage from "./pages/TicketReportPage";
import CurrentAvailableReportPage from "./pages/CurrentAvailableReportPage";
import DebitCreditInterimReportPage from "./pages/DebitCreditInterimReportPage";
import BronTicketsPage from "./pages/BronTickets";
import DebitBalanceInfoReportPage from "./pages/DebitBalanceInfoReportPage";

export default function BasePage() {
    const {
        Admin_View,
        Product_View,
        Circulation_View,
        Agent_View,
        Ticket_View,
        Role_View,
        Customer_View,
        View_Void_Ticket,
        View_Reports
    } = useSelector(({auth}) => ({
        Admin_View: Boolean(auth?.permissions?.Admin_View),
        Product_View: Boolean(auth?.permissions?.Product_View),
        Circulation_View: Boolean(auth?.permissions?.Circulation_View),
        Agent_View: Boolean(auth?.permissions?.Agent_View),
        Ticket_View: Boolean(auth?.permissions?.Ticket_View),
        Role_View: Boolean(auth?.permissions?.Role_View),
        Customer_View: Boolean(auth?.permissions?.Customer_View),
        View_Void_Ticket: Boolean(auth?.permissions?.View_Void_Ticket),
        View_Reports:  Boolean(auth?.permissions?.View_Reports)
    }), shallowEqual);
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {Admin_View && <Redirect exact from="/" to="/dashboard"/>}
                {Product_View && <Redirect exact from="/" to="/products"/>}
                {Circulation_View && <Redirect exact from="/" to="/circulations"/>}
                {Admin_View && <Redirect exact from="/" to="/dashboard"/>}
                {Agent_View && <Redirect exact from="/" to="/agents"/>}
                {Ticket_View && <Redirect exact from="/" to="/tickets"/>}
                {View_Void_Ticket && <Redirect exact from="/" to="/bron-tickets"/>}
                {Customer_View && <Redirect exact from="/" to="/customers"/>}
                {Role_View && <Redirect exact from="/" to="/roles"/>}
                {View_Reports && <Redirect exact from="/" to="/total-remaining-report"/>}

                <ContentRoute path="/dashboard" component={DashboardPage}/>
                <ContentRoute path="/products" component={ProductsPage}/>
                <ContentRoute path="/circulations" component={CirculationsPage}/>
                <ContentRoute path="/agents" component={AgentsPage}/>
                <ContentRoute path="/tickets" component={TicketsPage}/>
                <ContentRoute path="/bron-tickets" component={BronTicketsPage}/>
                <ContentRoute path="/customers" component={CustomersPage}/>
                <ContentRoute path="/roles" component={RolesPage}/>
                <ContentRoute
                    path="/tax-and-commissions"
                    component={TaxAndCommissionsPage}
                />
                <ContentRoute
                    path="/total-remaining-report"
                    component={TotalRemainingReportPage}
                />
                <ContentRoute path="/big-win-report" component={BigWinReportPage}/>
                <ContentRoute
                    path="/daily-expired-report"
                    component={DailyExpiredReportPage}
                />
                <ContentRoute path="/daily-report" component={DailyReportPage}/>
                <ContentRoute path="/ticket-report" component={TicketReportPage}/>
                <ContentRoute
                    path="/current-available-report"
                    component={CurrentAvailableReportPage}
                />
                <ContentRoute
                    path="/debit-credit-interim-report"
                    component={DebitCreditInterimReportPage}
                />
                <ContentRoute
                    path="/debit-balance-info-report"
                    component={DebitBalanceInfoReportPage}
                />

                <Redirect to="/logout"/>
            </Switch>
        </Suspense>
    );
}
