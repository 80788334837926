import React, {useState, useEffect} from "react";
import {InputLabel, FormControlLabel, Switch, TextField} from "@material-ui/core";
import axios from "axios";
import {Button} from "react-bootstrap";
import RenderIf from "app/components/RenderIf";
import Loading from "app/components/Loading";
import {DateTimePicker} from "app/components/DatePicker";
import Table from "./components/Table";
import {objectToQueryString} from "../../../_metronic/_helpers";
import dayjs from "dayjs";

const date = new Date();

const initialStartDate = new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString().split('T')[0];

const initialFilter = {
    agentId: "",
    permanentLimit: "",
    weekStartLimit: "",
    currentLimit: "",
}

export default function CurrentAvailableReport() {
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [orderBy, setOrderBy] = useState(false);
    const [sortField, setSortField] = useState("");
    const [date, setDate] = useState(initialStartDate);
    const [hideTestAgents, setHideTestAgents] = useState(true);
    const [state, setState] = useState({
        isLoading: false,
        error: null,
        data: null,
    });
    const [filterInputs, setFilterInputs] = useState(initialFilter);

    async function getData() {
        setState(prevState => (
            {
                isLoading: true,
                error: null,
                data: prevState.data
            }
        ));
        try {
            const query = objectToQueryString({
                skip,
                take,
                sortField,
                orderBy,
                date: date ? `${date}T12:00:00` : "",
                hideTestAgents,
                ...filterInputs
            })

            const {data} = await axios({
                method: "GET",
                url: `reports/currentAvailable?${query}`,
            });

            setState({
                isLoading: false,
                error: null,
                data
            })
        } catch (err) {
            setState({
                isLoading: false,
                error: err?.response?.data,
                data: null
            })
        }
    }

    async function getExcel() {
        const query = objectToQueryString({orderBy, sortField, date: date ? `${date}T12:00:00` : "", hideTestAgents, ...filterInputs})

        try {
            const {data} = await axios({
                url: `reports/currentAvailable/excel?${query}`,
                method: 'GET',
                responseType: 'blob'
            })
            const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = 'CurrentAvailableReport.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (err) {

        }
    }

    function handleChangeDate(e) {
        setDate(e)
    }

    function handleReset() {
        setFilterInputs(initialFilter)
        setDate(initialStartDate);
    }

    const filterInputsUpdate = (e) => {
        e.persist();
        setFilterInputs(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }));
        setSkip(0);
    }

    useEffect(() => {
        getData()
    }, [skip, take, orderBy, sortField, date, hideTestAgents, filterInputs])

    return (
        <div className="row bg-white rounded">
            <div className="col-12 py-4 overflow-auto">
                <div className="col-12 d-flex flex-wrap align-items-center py-4">
                    <div className="d-flex flex-column align-items-start mr-2 mb-2">
                        <InputLabel>
                            Agent number
                        </InputLabel>
                        <TextField
                            name="agentId"
                            placeholder="Agent number"
                            value={filterInputs.agentId}
                            onChange={filterInputsUpdate}
                            variant="outlined"
                        />
                    </div>
                    <div className="d-flex flex-column align-items-start mr-2 mb-2">
                        <InputLabel>
                            Current limit
                        </InputLabel>
                        <TextField
                            name="currentLimit"
                            placeholder="Current limit"
                            value={filterInputs.currentLimit}
                            onChange={filterInputsUpdate}
                            variant="outlined"
                        />
                    </div>
                    <div className="d-flex flex-column align-items-start mr-2 mb-2">
                        <InputLabel>
                            Week start limit
                        </InputLabel>
                        <TextField
                            name="weekStartLimit"
                            placeholder="Week start limit"
                            value={filterInputs.weekStartLimit}
                            onChange={filterInputsUpdate}
                            variant="outlined"
                        />
                    </div>
                    <div className="d-flex flex-column align-items-start mr-2 mb-2">
                        <InputLabel>
                            Permanent limit
                        </InputLabel>
                        <TextField
                            name="permanentLimit"
                            placeholder="Permanent limit"
                            value={filterInputs.permanentLimit}
                            onChange={filterInputsUpdate}
                            variant="outlined"
                        />
                    </div>
                    <Button onClick={handleReset} className="mx-2 my-2">
                        Sıfırla
                    </Button>
                </div>
                <div className="row pb-5">
                    <div className="col-3 col-md-3 col-lg-3">
                        <InputLabel>
                            Tarix
                        </InputLabel>
                        <DateTimePicker onChange={handleChangeDate} value={date && dayjs(date)}/>
                    </div>
                    <div className="col-3 col-md-3 col-lg-3 d-flex align-items-end">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={hideTestAgents}
                                    onChange={() => setHideTestAgents(prevState => !prevState)}
                                    color="secondary"
                                />
                            }
                            disabled={state.isLoading}
                            label="Test agentləri gizlə"
                            className="mb-0"
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-3 d-flex align-items-end justify-content-between">
                        <RenderIf condition={state?.data}>
                            <Button onClick={getExcel} variant="outline-success">Download excel</Button>
                        </RenderIf>
                    </div>
                </div>
                <RenderIf condition={state?.data}>
                    <Table
                        sortField={sortField}
                        orderBy={orderBy}
                        setSortField={setSortField}
                        setOrderBy={setOrderBy}
                        data={state?.data}
                        take={take}
                        skip={skip}
                        setTake={setTake}
                        setSkip={setSkip}
                    />
                </RenderIf>
                <RenderIf condition={state?.isLoading}>
                    <div className="d-flex justify-content-center">
                        <Loading/>
                    </div>
                </RenderIf>
            </div>
        </div>
    )
}