import React, { useEffect } from "react";
import RenderIf from "app/components/RenderIf";
import { Modal, Button } from "react-bootstrap";
import {useSelector, shallowEqual} from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField/index";
import Select from "@material-ui/core/Select";
import { getRoles } from "../_redux/usersCrud";

//Role_View
export default function Filter({ onHide, show, filterInputsUpdate, filterInputs, resetFilter }) {
  const [roles, setRoles] = React.useState([])
  const [role, setRole] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const {Role_View} = useSelector(state => state.auth.permissions, shallowEqual);



  useEffect(() => {
    if(Role_View) {
      getRoles().then(({data}) => {
        const arr = [...data]
        setRoles(arr)
      }).catch(() => {

      })
    }
  }, [Role_View])

  function handleChange(event) {
    setRole(event.target.value);
    filterInputsUpdate(event);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }
  return (
    <Modal
      size="sm"
      show={show}
      onHide={onHide}
      aria-labelledby="create"
      centered
      className="modal-right pr-0"
    >
      <Modal.Header closeButton>
        <Modal.Title id="create">İstifadəçilər filteri</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12 pl-4 pr-4">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                    id="name"
                    name="name"
                    label="Ad"
                    placeholder="Ad"
                    className="w-100"
                    value={filterInputs.name}
                    onChange={(e) => filterInputsUpdate(e)}
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                  id="surname"
                  name="surname"
                  label="Soyad"
                  placeholder="Soyad"
                  className="w-100"
                  value={filterInputs.surname}
                  onChange={(e) => filterInputsUpdate(e)}
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                    id="username"
                    name="username"
                    label="İstifadəçi adı"
                    placeholder="İstifadəçi adı"
                    className="w-100"
                    value={filterInputs.username}
                    onChange={(e) => filterInputsUpdate(e)}
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                    id="email"
                    name="email"
                    label="E-poçt"
                    placeholder="E-poçt"
                    className="w-100"
                    value={filterInputs.email}
                    onChange={(e) => filterInputsUpdate(e)}
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                    id="phonenumber"
                    name="phonenumber"
                    label="Telefon nömrəsi"
                    placeholder="Telefon nömrəsi"
                    className="w-100"
                    value={filterInputs.phonenumber}
                    onChange={(e) => filterInputsUpdate(e)}
                />
              </div>
            </div>
            <RenderIf condition={Role_View}>
              <div className="col-12 col-md-12 col-lg-12">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <FormControl className="w-100">
                    <InputLabel htmlFor="role">Role</InputLabel>
                    <Select
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        value={role}
                        onChange={handleChange}
                        inputProps={{
                          name: "roleId",
                          id: "roleId",
                        }}
                    >
                      {roles.map((role, index) => (
                          <MenuItem key={index} value={role.id}>{role.name}</MenuItem>
                      ))}

                    </Select>
                  </FormControl>
                </div>
              </div>
            </RenderIf>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={resetFilter}>
          Sıfırla
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
