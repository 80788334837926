export const findTicketBetType = (matches = []) => {
    const isMulti = matches.some(match => Object.values(match.options).reduce((acc, value) => value ? acc + 1 : acc, 0) > 1);
    return isMulti ? "Sistem" : "Tək variant"
}

export const getCirculationGameResult = (circulationGames = [], gameId) => {
    const resultNames = {
        0: "1",
        1: "X",
        2: "2",
        3: "Məlum deyil",
        4: "Ləğv"
    }

    return resultNames[circulationGames.find(game => game.id === gameId)?.gameOptionId ?? 3]
}