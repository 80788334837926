import React, {useState, useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {TextareaAutosize, InputLabel} from "@material-ui/core";
import {useFormik} from "formik";
import * as Yup from "yup";
import axios from "axios";

const initialValues = {
    reason: "",
};

export default function CirculationActivation({show, onHide, isActivate, circulationId, refetch}) {
    const [error, setError] = useState("");


    const CreateSchema = Yup.object().shape({
        reason: Yup.string().required("Səbəb daxil edin."),
    });

    const getInputClasses = (filedName) => {
        if (formik.touched[filedName] && formik.errors[filedName]) {
            return true;
        }
        if (formik.touched[filedName] && !formik.errors[filedName]) {
            return false;
        }

        return false;
    };


    const formik = useFormik({
        initialValues,
        validationSchema: CreateSchema,
        onSubmit: async (values, {resetForm, setSubmitting}) => {
            try {
                await axios.put(isActivate ? '/circulations/activateCirculation' : '/circulations/deactivateCirculation', {
                    circulationId,
                    reason: values.reason
                });
                refetch();
                onHide();
            } catch (err) {

            }
        },
    });

    const resetForm = () => {
        onHide();
        formik.resetForm();
    };


    return (
        <Modal
            size="md"
            aria-labelledby="create"
            centered
            show={show}
            onHide={resetForm}
        >
            <form onSubmit={formik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="create">Turu {isActivate ? "aktiv et" : "deaktiv et"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-12 pr-12">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    {error && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block MuiFormLabel-root Mui-error">
                                                {error}
                                            </div>
                                        </div>
                                    )}
                                    <InputLabel>Səbəb</InputLabel>
                                    <TextareaAutosize
                                        id="reason"
                                        name="reason"
                                        className="w-100"
                                        {...formik.getFieldProps("reason")}
                                    />
                                </div>
                                {formik.touched.reason &&
                                formik.errors.reason ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.reason}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                            formik.resetForm();
                        }}
                    >
                        Bağla
                    </Button>
                    <Button type="submit">{isActivate ? "Aktivləşdir" : "Deaktiv et"}</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );

}
