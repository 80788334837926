import React, {useState, useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {TextField} from "@material-ui/core";
import {Select, MenuItem, FormControl, InputLabel} from "@material-ui/core";
import {useFormik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RenderIf from "app/components/RenderIf";
import {DateTimePicker} from "app/components/DatePicker";
import dayjs from "dayjs";

const initialValues = {
    code: "",
    resultedDate: "",
    status: true,
    productId: "",
    circulationGames: [],
    isJackpot: true,
    jackpotAmount: 0
};

export default function Create(props) {
    const {show, onHide, onGroups, skip, take, filterInputs, orderBy, sortField} = props;

    const [isError, setIsError] = useState("");
    const [products, setProducts] = useState([]);
    const [gameCount, setGameCount] = useState(0);

    const CreateSchema = Yup.object().shape({
        code: Yup.number().typeError("Yalnız rəqəm daxil edilə bilər.").required("Kod tələb olunur.").test('length', "1 - 6 simvol aralığında tur nömrəsi daxil edin.", (value) => {
            const regex = /\b[1-9]\d{0,5}\b/;
            return regex.test(value)
        }),
        resultedDate: Yup.string().required("Bitmə tarixi daxil edin."),
        productId: Yup.number().required("Məhsul seçin."),
        jackpotAmount: Yup.number().typeError("Yalnız rəqəm daxil edilə bilər.").required("Cekpot məbləği daxil edin.")
    });

    const getInputClasses = (filedName) => {
        if (formik.touched[filedName] && formik.errors[filedName]) {
            return true;
        }
        if (formik.touched[filedName] && !formik.errors[filedName]) {
            return false;
        }

        return false;
    };


    const formik = useFormik({
        initialValues,
        validationSchema: CreateSchema,
        onSubmit: (values, {resetForm, setSubmitting}) => {
            onPostCirculation(values, resetForm, setSubmitting);
        },
    });

    const resetForm = () => {
        onHide();
        formik.resetForm();
    };

    useEffect(() => {
        setIsError("");
    }, [show]);

    const onPostCirculation = (values, resetForm, setSubmitting) => {
        const circulation = {
            ...values,
            jackpotAmount: +values.jackpotAmount,
            resultedDate: values.resultedDate ? `${values.resultedDate}` : "",
            circulationGames: values.circulationGames.map(game => ({
                ...game, startDate:  game.startDate ? `${game.startDate}` : ""
            }))
        };

        axios
            .post(
                `circulations`,
                circulation
            )
            .then(() => {
                onGroups({...filterInputs, skip, take, orderBy, sortField});
                setSubmitting(false);
                resetForm();
                onHide();
            })
            .catch((err) => {
                let message =
                    typeof err.response !== "undefined"
                        ? err.response.data.message
                        : err.message;
                setIsError(message);
            });
    };

    const onGetProducts = () => {
        axios.get(`products`)
            .then(({data: {data}}) => {
                setProducts(data)
            })
            .catch(() => {
                console.log("error")
            })
    }

    const handleChangeCirculationGames = (e, index) => {
        const circulationGames = [...formik.values.circulationGames];
        circulationGames[index] = {
            ...circulationGames[index],
            [e.target.name]: e.target.value
        };
        formik.setFieldValue("circulationGames", circulationGames);
    }

    const handleChangeGameDate = (value, index) => {
        const circulationGames = [...formik.values.circulationGames];
        circulationGames[index] = {
            ...circulationGames[index],
            startDate: value ? value.replace(" ", "T") : ""
        };
        formik.setFieldValue("circulationGames", circulationGames);
    }

    useEffect(() => {
        onGetProducts()
    }, [])

    useEffect(() => {
        const product = products.find(product => product.id === formik.values.productId);
        if (Boolean(product)) {
            setGameCount(product.gameCount);
        }
    }, [formik.values.productId, products])

    return (
        <Modal
            size="xl"
            aria-labelledby="create"
            centered
            show={show}
            onHide={resetForm}
        >
            <form onSubmit={formik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="create">Tur yarat</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    {isError && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block MuiFormLabel-root Mui-error">
                                                {isError}
                                            </div>
                                        </div>
                                    )}
                                    <TextField
                                        id="code"
                                        name="code"
                                        label="Kod"
                                        placeholder="Kod"
                                        className="w-100"
                                        error={getInputClasses("code")}
                                        {...formik.getFieldProps("code")}
                                    />
                                </div>
                                {formik.touched.code &&
                                formik.errors.code ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.code}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-6 col-md-6 col-lg-6">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    {isError && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block MuiFormLabel-root Mui-error">
                                                {isError}
                                            </div>
                                        </div>
                                    )}
                                    <InputLabel className="w-100">Turun bitmə tarixi</InputLabel>
                                    <DateTimePicker
                                        showTime
                                        value={formik.values.resultedDate ? dayjs(formik.values.resultedDate) : ""}
                                        onChange={value => formik.setFieldValue("resultedDate", value ? value.replace(" ", "T") : "")}
                                    />
                                </div>
                                {formik.touched.resultedDate &&
                                formik.errors.resultedDate ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.resultedDate}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    {isError && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block MuiFormLabel-root Mui-error">
                                                {isError}
                                            </div>
                                        </div>
                                    )}
                                    <FormControl
                                        error={getInputClasses("productId")}
                                        className="w-100"
                                        placeholder="productId"
                                    >
                                        <InputLabel id="status">Məhsul</InputLabel>
                                        <Select
                                            {...formik.getFieldProps("productId")}
                                            labelId="productId"
                                            id="productId"
                                        >
                                            <MenuItem value="">
                                                <em>Hamısı</em>
                                            </MenuItem>
                                            {products.map((product, index) => <MenuItem key={index}
                                                                                        value={product.id}>{product.name}</MenuItem>)}
                                        </Select>
                                        {formik.touched.productId && formik.errors.productId ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.productId}
                                                </div>
                                            </div>
                                        ) : null}
                                    </FormControl>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap flex-column">
                                    <InputLabel id="status">Cekpot</InputLabel>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={formik.values.isJackpot}
                                                onChange={formik.handleChange}
                                                color="secondary"
                                                name="isJackpot"
                                            />
                                        }
                                        label={formik.values.isJackpot ? "Aktivdir" : "Deaktivdir"}
                                    />
                                </div>
                            </div>
                            <RenderIf condition={formik.values.isJackpot}>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        {isError && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block MuiFormLabel-root Mui-error">
                                                    {isError}
                                                </div>
                                            </div>
                                        )}
                                        <TextField
                                            id="jackpotAmount"
                                            name="jackpotAmount"
                                            label="Cekpot məbləği"
                                            placeholder="Cekpot məbləği"
                                            className="w-100"
                                            error={getInputClasses("jackpotAmount")}
                                            {...formik.getFieldProps("jackpotAmount")}
                                        />
                                        {formik.touched.jackpotAmount && formik.errors.jackpotAmount ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.jackpotAmount}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </RenderIf>
                            {Array.from({length: gameCount}).map((item, index) => {
                                return (
                                    <div className="col-12 col-md-12 col-lg-12" key={index}>
                                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                            <TextField
                                                name="hostClubName"
                                                data-index-number={index}
                                                value={formik.values.circulationGames[index]?.hostClubName}
                                                label="Ev sahibi komanda"
                                                onChange={(e) => handleChangeCirculationGames(e, index)}
                                                className="w-100"
                                            />
                                            <TextField
                                                name="guestClubName"
                                                value={formik.values.circulationGames[index]?.guestClubName}
                                                label="Qonaq komanda"
                                                onChange={(e) => handleChangeCirculationGames(e, index)}
                                                className="w-100"
                                            />
                                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                                <DateTimePicker
                                                    showTime
                                                    value={formik.values.circulationGames[index]?.startDate ? dayjs(formik.values.circulationGames[index]?.startDate) : ""}
                                                    onChange={value => handleChangeGameDate(value, index)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            <span
                                className="d-flex align-items-center">{gameCount > 0 && `Oyunların sayı: ${gameCount}`}</span>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                            formik.resetForm();
                        }}
                    >
                        Bağla
                    </Button>
                    <Button type="submit">Yarat</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );

}
