import axios from "axios";

export const LOGIN_URL = `users/login`;


export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = `users/profile`;

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function getUser() {
  return axios.get(ME_URL)
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}
