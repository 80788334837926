import React, {useState} from "react";
import {Modal, Button} from "react-bootstrap";
import {TextField} from "@material-ui/core";

import {useFormik} from "formik";
import * as Yup from "yup";
import axios from "axios";

export default function ChangeBirthDate({
                                            show,
                                            onHide,
                                            onGroups,
                                            skip,
                                            take,
                                            orderBy,
                                            sortField,
                                            customerId,
                                            birthDate,
                                            filterInputs
                                        }) {

    const [error, setError] = useState("");

    const CreateSchema = Yup.object().shape({
        birthDate: ""
    });

    const getInputClasses = (filedName) => {
        if (formik.touched[filedName] && formik.errors[filedName]) {
            return true;
        }
        if (formik.touched[filedName] && !formik.errors[filedName]) {
            return false;
        }

        return false;
    };

    const formik = useFormik({
        initialValues: {
            birthDate: birthDate ? new Date(birthDate).toISOString().split('T')[0] : ''
        },
        validationSchema: CreateSchema,
        onSubmit: (values) => {
            setError('')
            axios({
                method: "put",
                url: `customers/changeProfileInfo`,
                data: {
                    ...values, customerId
                }
            }).then(() => {
                onGroups({skip, take, orderBy, sortField, ...filterInputs});
                onHide(false);
            }).catch((err) => {
                setError(err.response?.data?.message ?? "Xəta baş verdi.")
            });
        },
    });

    return (
        <Modal
            size="md"
            aria-labelledby="create"
            centered
            className="modal pr-0"
            show={show}
            onHide={onHide}
        >
            <form onSubmit={formik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="create">Doğum tarixi seçin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        type="date"
                                        id="birthDate"
                                        name="birthDate"
                                        label="Doğum tarixi"
                                        placeholder="Doğum tarixi"
                                        className="w-100"
                                        error={getInputClasses("birthDate")}
                                        {...formik.getFieldProps("birthDate")}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                {formik.touched.birthDate && formik.errors.birthDate ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.birthDate}
                                        </div>
                                    </div>
                                ) : null}
                                {error && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block MuiFormLabel-root Mui-error">
                                            {error}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                            formik.resetForm();
                        }}
                    >
                        Bağla
                    </Button>
                    <Button type="submit">Yenilə</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
