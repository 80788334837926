import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {shallowEqual, useSelector, useDispatch} from "react-redux";

import {actions} from "./snackbarReducer";

const SnackbarComponent = () => {
    const dispatch = useDispatch();
    const {open, message, variant} = useSelector(state => state.snackbar, shallowEqual);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(actions.setOpen(false))
    };

    return (
        <Snackbar anchorOrigin={{vertical: "top", horizontal: "right"}} open={open} autoHideDuration={6000} onClose={handleClose}>
            <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={variant}>
                {message}
            </MuiAlert>
        </Snackbar>
    )
}

export default SnackbarComponent