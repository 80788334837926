import React from "react";
import PropTypes from "prop-types";
import AlertComponent from "../../../../_metronic/_helpers/AlertComponent";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TableSortLabel,
  createMuiTheme,
  ThemeProvider,
  Tooltip,
} from "@material-ui/core";
import { Badge } from "react-bootstrap";
import { azAZ } from "@material-ui/core/locale";
import TablePagination from "@material-ui/core/TablePagination";
import { format } from "date-fns";
import EyeIcon from "@material-ui/icons/RemoveRedEye";
const theme = createMuiTheme(azAZ);

const headRows = [
  // {
  //   id: "id",
  //   numeric: false,
  //   disablePadding: true,
  //   disableSorting: false,
  //   label: "Bilet ID",
  // },
  {
    id: "circulation.code",
    numeric: false,
    disablePadding: true,
    disableSorting: false,
    label: "Tur nömrəsi",
  },

  {
    id: "ticketNumber",
    numeric: false,
    disablePadding: true,
    disableSorting: false,
    label: "Kupon nömrəsi",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: true,
    disableSorting: false,
    label: "Kuponun dəyəri",
  },
  {
    id: "times",
    numeric: false,
    disablePadding: true,
    disableSorting: false,
    label: "Misli",
  },
  {
    id: "winningPaidStatus",
    numeric: false,
    disablePadding: true,
    disableSorting: false,
    label: "Kuponun statusu",
  },
  {
    id: "customerId",
    numeric: false,
    disablePadding: true,
    disableSorting: false,
    label: "Yaradılma növü",
  },
  {
    id: "typeString",
    numeric: false,
    disablePadding: true,
    disableSorting: false,
    label: "Seçim növü",
  },
  {
    id: "isMulti",
    numeric: false,
    disablePadding: true,
    disableSorting: false,
    label: "Bilet tipi",
  },
  {
    id: "createdDate",
    numeric: false,
    disablePadding: true,
    disableSorting: false,
    label: "Yaradılma tarixi",
  },
  // {
  //   id: "winningAmount",
  //   numeric: false,
  //   disablePadding: true,
  //   disableSorting: false,
  //   label: "Uduş məbləği",
  // },
  {
    id: "customerNumber",
    numeric: false,
    disablePadding: true,
    disableSorting: false,
    label: "Müştəri Nömrəsi",
  },
  {
    id: "operations",
    numeric: false,
    disablePadding: true,
    disableSorting: true,
    label: "Əməliyyatlar",
  },
];

function EnhancedTableHead({ onRequestSort, sortField, orderBy }) {
  return (
    <TableHead>
      <TableRow>
        {headRows.map((row, index) => (
          <TableCell
            sortDirection={sortField === row.id ? "asc" : false}
            key={index}
            align={row.numeric ? "right" : "left"}
          >
            <TableSortLabel
              hideSortIcon
              active={sortField === row.id}
              direction={orderBy ? "desc" : "asc"}
              onClick={() => {
                !row.disableSorting && onRequestSort(row.id);
              }}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  progresRoot: {
    width: "100%",
    padding: "32px 16px",
  },
  progress: {
    margin: "auto",
    display: "block",
    color: "#e2241b",
  },
}));

export default function EnhancedTable({
  groups,
  sortField,
  setSortField,
  setOrderBy,
  setTake,
  take,
  setSkip,
  isLoading,
  isError,
  orderBy,
  totalCount,
  setShowView,
  onSetTicketInformation,
  setCirculationId,
  setTicketId,
}) {
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function handleChangePage(event, newPage) {
    setPage(newPage);
    setSkip(take * newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setTake(+event.target.value);
    setPage(0);
    setSkip(0);
  }

  const handleRequestSort = (field) => {
    const isOrder = field === sortField && !orderBy;

    setSortField(field);
    setOrderBy(isOrder);
    setTake(10);
    setRowsPerPage(10);
    setSkip(0);
    setPage(0);
  };
  const STATUS_TYPE = {
    0: "Manual",
    1: "Populyar",
    2: "İdeal",
    3: "Təsadüfi oyun",
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {isLoading && (
          <div className={classes.progresRoot}>
            <CircularProgress className={classes.progress} />
          </div>
        )}
        <div className={classes.tableWrapper}>
          {groups.length ? (
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                onRequestSort={handleRequestSort}
                orderBy={orderBy}
                sortField={sortField}
                rowCount={groups.length}
              />
              <TableBody>
                {groups.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {/* <TableCell component="th" id={labelId} scope="row">
                        {row.agentNumber}
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row">
                        {row.statusId === 2 ? (
                          <Badge variant="success">Uduşlu</Badge>
                        ) : row.statusId === 3 ? (
                          <Badge variant="danger">Uduşsuz</Badge>
                        ) : row.statusId === 4 ? (
                          <Badge variant="warning">Ləğv edilib</Badge>
                        ) : (
                          row.statusId === 0 && (
                            <Badge variant="info">Açıq</Badge>
                          )
                        )}
                      </TableCell> */}
                      {/* <TableCell component="th" id={labelId} scope="row">
                        {row.id}
                      </TableCell> */}
                      <TableCell component="th" id={labelId} scope="row">
                        {row.circulationNumber}
                      </TableCell>

                      <TableCell component="th" id={labelId} scope="row">
                        {row.ticketNumber}
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row">
                        <b className="d-flex justify-content-center font-size-h6">
                          {row.price} AZN
                        </b>
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row">
                        {row.times}
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row">
                        {row.isPaid ? (
                          <Badge variant="success">Ödənilmişdir</Badge>
                        ) : row.isPaid === false ? (
                          <Badge variant="info">Gözləmədə</Badge>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row">
                        <b className="d-flex justify-content-center ">
                          {row.customerId === null ? "Agent" : "Müştəri"}
                        </b>
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row">
                        <b className="d-flex justify-content-center ">
                          {STATUS_TYPE[row.typeId] ?? "-"}
                        </b>
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row">
                        <b className="d-flex justify-content-center ">
                          {row.isMulti ? "Çox variantlı" : "Tək variantlı"}
                        </b>
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row">
                        {format(
                          new Date(row.createdDate),
                          "dd MM yyyy HH:mm:ss"
                        )}
                      </TableCell>
                      {/* <TableCell component="th" id={labelId} scope="row">
                        {row.winningAmount === 0 ? (
                          "-"
                        ) : (
                          <Badge variant="success">
                            {row.winningAmount} AZN
                          </Badge>
                        )}
                      </TableCell> */}
                      <TableCell component="th" id={labelId} scope="row">
                        {row.customerNumber ?? "-"}
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row">
                        <Tooltip title="Biletə bax" placement="top">
                          <button
                            aria-label="view"
                            className="btn btn-sm btn-icon btn-bg-light btn-text-info btn-hover-info mr-2"
                            onClick={() => {
                              setCirculationId(row.circulationId);
                              setTicketId(row.id);
                              onSetTicketInformation(row);
                              setShowView(true);
                            }}
                          >
                            <EyeIcon />
                          </button>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      ></TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : !groups.length && !isLoading && !isError ? (
            <AlertComponent variant="info" message="Məlumat tapılmadı." />
          ) : (
            !isLoading &&
            isError &&
            !isLoading &&
            isError && (
              <AlertComponent
                variant="danger"
                message="Şəbəkəylə bağlantı kəsildi."
              />
            )
          )}
        </div>
          <ThemeProvider theme={theme}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </ThemeProvider>
      </Paper>
    </div>
  );
}
