import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles/index";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";

const useStyles = makeStyles({
  list: {
    width: 400,
    height: "100vh",
  },
  card: {
    margin: "30px",
  },
  pointer: {
    cursor: "pointer",
  },
  progresRoot: {
    width: "100%",
    padding: "32px 16px",
  },
  progress: {
    margin: "auto",
    display: "block",
    color: "#e2241b",
  },
});

const initialValues = {
  password: "",
  confirmPassword: "",
};

export default function Edit(props) {
  const classes = useStyles();
  const { onHide, show, onGroups, chooseGroupId, skip, take, orderBy } = props;

  const onPutGroup = (values, resetForm, setSubmitting) => {
    const group = {
      id: chooseGroupId,
      password: values.password,
    };
    axios
      .put(`${process.env.REACT_APP_API_URL}users/updateUserPassword`, group)
      .then(() => {
        onHide(false);
        resetForm();
        setSubmitting(false);
        onGroups(null, skip, take, orderBy);
      })
      .catch(
        ({
          response: {
            data: { checks },
          },
        }) => {
          setIsError(true);
          setErrorMessage(checks);
        }
      );
  };

  const EditSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_-])(?=.{8,})/,
        "Şifrə ən az bir böyük hərfdən, kiçik hərfdən, rəqəm və simvoldan ibarət olmalıdır."
      )
      .required("Şifrə tələb olunur"),
    confirmPassword: Yup.string()
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Hər iki şifrə eyni olmalıdır."
        ),
      })
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_-])(?=.{8,})/,
        "Şifrə ən az bir böyük hərfdən, kiçik hərfdən, rəqəm və simvoldan ibarət olmalıdır."
      )
      .required("Yeni şifrənin təsdiqi tələb olunur."),
  });

  const getInputClasses = (filedName) => {
    if (formik.touched[filedName] && formik.errors[filedName]) {
      return true;
    }
    if (formik.touched[filedName] && !formik.errors[filedName]) {
      return false;
    }

    return false;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: EditSchema,
    onSubmit: (values, { resetForm, setSubmitting }, e) => {
      onPutGroup(values, resetForm, setSubmitting);
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});

  return (
    <Modal
      size="sm"
      onHide={onHide}
      show={show}
      aria-labelledby="edit"
      className="modal-right pr-0"
    >
      {isLoading ? (
        <div className={classes.progresRoot}>
          <CircularProgress className={classes.progress} />
        </div>
      ) : !isLoading ? (
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="edit">İstifadəçinin şifrəsini dəyiş.</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-12 pl-4 pr-4">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                    {isError && errorMessage ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block MuiFormLabel-root Mui-error">
                          {errorMessage.password}
                        </div>
                      </div>
                    ) : null}
                    <TextField
                      type="password"
                      id="password"
                      name="password"
                      label="Şifrə"
                      placeholder="Şifrə"
                      className="w-100"
                      error={getInputClasses("password")}
                      {...formik.getFieldProps("password")}
                    />
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.password}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                    {isError && errorMessage ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block MuiFormLabel-root Mui-error">
                          {errorMessage.confirmPassword}
                        </div>
                      </div>
                    ) : null}
                    <TextField
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      label="Şifrə təkrar"
                      placeholder="Şifrə təkrar"
                      className="w-100"
                      error={getInputClasses("confirmPassword")}
                      {...formik.getFieldProps("confirmPassword")}
                    />
                  </div>
                  {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.confirmPassword}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              onClick={() => {
                onHide(false);
                formik.resetForm();
              }}
            >
              Bağla
            </Button>
            <Button type="submit">Yadda saxla</Button>
          </Modal.Footer>
        </form>
      ) : null

      // <div className="p-5">
      //   <Alert variant="info">Xəta baş verdi.</Alert>
      // </div>
      }
    </Modal>
  );
}
