export const actionTypes = {
  GetAll: "[GetAll] Action",
  IsLoading: "[IsLoading] Action",
  FilterGroups: "[Filter Groups] Action",
};

const initailGroupsState = {
  groups: [],
  totalCount: 0,
  isLoading: false,
  isError: false,
  filterWord: "",
};

export const reducer = (state = initailGroupsState, action) => {
  switch (action.type) {
    case actionTypes.GetAll:
      const { groups, totalCount } = action.payload;
      return { groups, totalCount };

    case actionTypes.IsLoading:
      const { loading } = action.payload;
      return { ...state, isLoading: loading };

    case actionTypes.FilterGroups:
      const { word } = action.payload;
      return { ...state, filterWord: word };

    default:
      return state;
  }
};

export const actions = {
  getAllGroups: (groups, totalCount) => ({
    type: actionTypes.GetAll,
    payload: { groups, totalCount },
  }),
  isLoading: (loading) => ({
    type: actionTypes.IsLoading,
    payload: { loading },
  }),
  getFilter: (word) => ({
    type: actionTypes.FilterGroups,
    payload: { word },
  }),
};
