import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles/index";
import { Modal, Button } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from "@material-ui/core";
import axios from "axios";

const useStyles = makeStyles({
  list: {
    width: 400,
    height: "100vh",
  },
  card: {
    margin: "30px",
  },
  pointer: {
    cursor: "pointer",
  },
  progresRoot: {
    width: "100%",
    padding: "32px 16px",
  },
  progress: {
    margin: "auto",
    display: "block",
    color: "#e2241b",
  },
});

export default function CheckGames(props) {
  const classes = useStyles();
  const {
    setShowTickets,
    circulationId,
    show,
    onHide,
    circulationNumber,
  } = props;

  const [games, setGames] = useState([]);
  const [data, setData] = useState([]);

  const onGetCirculation = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}circulations/id?id=${circulationId}`
      )
      .then(({ data: { circulationGames } }) => {
        const arr = circulationGames.map((game) => ({
          id: game.id,
          gameOptionId: game.gameOptionId,
          hostClubName: game.hostClubName,
          guestClubName: game.guestClubName,
          score: game.score,
        }));
        setGames(arr);
        setData(circulationGames);
        setIsLoading(false);
      })
      .catch((err) => {
        const message = err.response?.data?.message ?? "Xəta baş verdi.";
        setError(message);
        setIsLoading(false);
      });
  };

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    onGetCirculation();
  }, [circulationId]);

  const handleChangeGame = (e, gameOptionId, index) => {
    const game = [...games];
    game[index][gameOptionId] = e.target.value;
    return setGames(game);
  };

  const onSubmitResult = (index) => {
    const game = [...games];
    const gameOptionId = game[index]["gameOptionId"];
    const score = game[index]["score"];
    const id = game[index]["id"];

    const result = {
      gameOptionId,
      id,
      score,
    };
    setIsLoading(true);
    axios
      .put(`circulations/updateGameOption`, result)
      .then((res) => {
        onGetCirculation();
      })
      .catch((err) => {
        setError(err?.response?.data?.message ?? "Xəta baş verdi.");
      });
  };

  const onSubmitAllResults = async () => {
    try {
      await Promise.all(
        games.map(({ gameOptionId, id, score }) =>
          axios.put("circulations/updateGameOption", {
            gameOptionId,
            id,
            score,
          })
        )
      );
      await onGetCirculation();
    } catch {}
  };

  return (
    <Modal
      size="xl"
      onHide={onHide}
      show={show}
      aria-labelledby="edit"
      className="modal pr-0"
    >
      {isLoading ? (
        <div className={classes.progresRoot}>
          <CircularProgress className={classes.progress} />
        </div>
      ) : !isLoading ? (
        <div>
          <Modal.Header closeButton>
            <Modal.Title id="create">
              Nəticələri təsdiqlə - Tur nömrəsi : {circulationNumber}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-12 pl-4 pr-4">
              <div className="row">
                {games.map((game, index) => (
                  <div
                    key={index}
                    className="col-12 col-md-12 col-lg-12 d-flex pb-2"
                  >
                    <div className="col-4 col-md-4 col-lg-4 d-flex align-items-center">
                      {game.hostClubName} - {game.guestClubName}
                    </div>
                    <div className="col-3 col-md-3 col-lg-3">
                      <div>
                        <FormControl className="w-100" placeholder="Nəticə">
                          <InputLabel id="result">Status</InputLabel>
                          <Select
                            labelId="result"
                            id="result"
                            value={game.gameOptionId}
                            onChange={(e) =>
                              handleChangeGame(e, "gameOptionId", index)
                            }
                          >
                            <MenuItem value={3}>
                              <em>Məlum deyil</em>
                            </MenuItem>
                            <MenuItem value={0}>Ev sahibi komanda</MenuItem>
                            <MenuItem value={1}>Heç-heçə</MenuItem>
                            <MenuItem value={2}>Qonaq komanda</MenuItem>
                            <MenuItem value={4}>Ləğv edilib</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-2 col-md-2 col-lg-2">
                      <TextField
                        id={game.score}
                        name={game.score}
                        value={game.score}
                        label="Score"
                        placeholder="Score"
                        className="w-100"
                        onChange={(e) => handleChangeGame(e, "score", index)}
                      />
                    </div>
                    {error && (
                      <div className="fv-help-block MuiFormLabel-root Mui-error">
                        {error}
                      </div>
                    )}
                    <div className="col-4 col-md-4 col-lg-4 text-nowrap">
                      <Button onClick={() => onSubmitResult(index)}>
                        Nəticəni göndər
                      </Button>
                    </div>
                  </div>
                ))}
                <div className="col-12 col-md-12 col-lg-12 d-flex pt-10 justify-content-center">
                  <Button type="button" onClick={onSubmitAllResults}>
                    Bütün nəticələri göndər
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {data.some((game) => game.gameOptionId === 3) ? (
              ""
            ) : (
              <Button
                variant="danger"
                onClick={() => {
                  setShowTickets(true);
                }}
              >
                Təsdiqlə
              </Button>
            )}
            <Button
              variant="light"
              onClick={() => {
                onHide(false);
              }}
            >
              Bağla
            </Button>
          </Modal.Footer>
        </div>
      ) : null}
    </Modal>
  );
}
