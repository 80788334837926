import React, {useState, useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField/index";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {useSelector, useDispatch} from "react-redux";
import {actions} from "../../../../../../app/modules/Auth/_redux/authRedux";

import {v4 as uuid} from "uuid";

import {useFormik} from "formik";
import * as Yup from "yup";
import axios from "axios";

const initialValues = {
    newPassword: "",
    confirmPassword: "",
};

export default function ChangePassword(props) {
    const {onHide, show} = props;
    const [isError, setIsError] = useState("");
    const [values, setValues] = useState({
        newPassword: "",
        confirmPassword: "",
        showCurrentPassword: false,
        showNewPassword: false,
        showRePassword: false,
    });
    const handleClickShowPassword = (key) => {
        setValues({...values, [key]: !values[key]});
    };

    const ChangeSchema = Yup.object().shape({
        newPassword: Yup.string()
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_-])(?=.{8,})/,
                "Şifrə ən az bir böyük hərfdən, kiçik hərfdən, rəqəm və simvoldan ibarət olmalıdır."

        )
            .required("Yeni şifrə tələb olunur"),
        confirmPassword: Yup.string()
            .when("newPassword", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("newPassword")],
                    "Hər iki şifrə eyni olmalıdır."
                ),
            })
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_-])(?=.{8,})/,
                "Şifrə ən az bir böyük hərfdən, kiçik hərfdən, rəqəm və simvoldan ibarət olmalıdır."
            )
            .required("Yeni şifrənin təsdiqi tələb olunur."),
    });

    const getInputClasses = (filedName) => {
        if (formik.touched[filedName] && formik.errors[filedName]) {
            return true;
        }
        if (formik.touched[filedName] && !formik.errors[filedName]) {
            return false;
        }

        return false;
    };

    const onPutPassword = (values, resetForm, setSubmitting, onHide) => {
        const password = values.newPassword
        axios
            .put(`${process.env.REACT_APP_API_URL}users/updatePassword?password=${password}`,
            )
            .then(({data}) => {
                setSubmitting(false);
                resetForm();
                onHide(false);
            })
            .catch(
                ({
                     response: {
                         data: {message},
                     },
                 }) => {
                    message === "User is not authenticated" ? setIsError("İstifadəçinin autentifikasiyası yoxdur.") :
                        setIsError(message);
                }
            );
    };

    const formik = useFormik({
        initialValues,
        validationSchema: ChangeSchema,
        onSubmit: (values, {resetForm, setSubmitting}) => {
            onPutPassword(values, resetForm, setSubmitting, onHide);
        },
    });

    useEffect(() => {
        setIsError("");
    }, [show]);

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="sm"
            aria-labelledby="edit"
            centered
            className="modal-right pr-0"
        >
            <form onSubmit={formik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="changePassword">Şifrəni dəyiş</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="newPassword"
                                        className="w-100"
                                        type={values.showNewPassword ? "text" : "password"}
                                        label="Yeni şifrə"
                                        error={getInputClasses("newPassword")}
                                        {...formik.getFieldProps("newPassword")}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="Toggle password visibility"
                                                        onClick={() =>
                                                            handleClickShowPassword("showNewPassword")
                                                        }
                                                    >
                                                        {values.showNewPassword ? (
                                                            <VisibilityOff/>
                                                        ) : (
                                                            <Visibility/>
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                {formik.touched.newPassword && formik.errors.newPassword ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.newPassword}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="confirmPassword"
                                        className="w-100"
                                        type={values.showRePassword ? "text" : "password"}
                                        label="Yeni şifrəni təsdiqlə"
                                        error={getInputClasses("confirmPassword")}
                                        {...formik.getFieldProps("confirmPassword")}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="Toggle password visibility"
                                                        onClick={() =>
                                                            handleClickShowPassword("showRePassword")
                                                        }
                                                    >
                                                        {values.showRePassword ? (
                                                            <VisibilityOff/>
                                                        ) : (
                                                            <Visibility/>
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                {formik.touched.confirmPassword &&
                                formik.errors.confirmPassword ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.confirmPassword}
                                        </div>
                                    </div>
                                ) : null}
                                {isError && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block MuiFormLabel-root Mui-error">
                                            {isError}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                            formik.resetForm();
                        }}
                    >
                        Bağla
                    </Button>
                    <Button type="submit">Yadda saxla</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
