import React, {useEffect, useState} from "react";
import DataTables from "./components/ProductsTable";
import Edit from "./modals/Edit";
import Delete from "./modals/Remove";
import Create from "./modals/Create";
import Filter from "./modals/Filter"
import Divider from "@material-ui/core/Divider/index";
import axios from "axios";
import {useSelector} from "react-redux";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {updateObject} from "../../../helpers/updateObject";

function Products() {
    const token = useSelector((state) => state.auth.token);

    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [totalCount, setTotalCount] = useState(0)
    const [sortField, setSortField] = useState("");
    const [orderBy, setOrderBy] = useState(false);
    const [groups, setGroups] = useState([]);
    const [chooseGroupId, setChooseGroupId] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [deleteGroupsIds, setDeleteGroupsIds] = useState([]);

    const [showCreate, setShowCreate] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showFilter, setShowFilter] = useState(false);

    const [filterInputs, setFilterInputs] = useState({
        name: "",
        columnPrice: "",
        isDeleted: "false",
    });

    const handleReset = () => {
        setFilterInputs({
            name: "",
            columnPrice: "",
            isDeleted: "false",
        })
    }

    function handleChange() {
        setShowFilter((key) => !key);
    }


    const onGroups = (filter = {}) => {
        const {
            skip,
            take,
            orderBy,
            sortField,
            name,
            columnPrice,
            isDeleted
        } = filter
        setIsLoading(true);
        axios
            .get(`products/filter?Skip=${skip}&Take=${take}&OrderBy=${orderBy}${sortField ? `&SortField=${sortField}` : ""}${name ? `&Name=${name}` : ""}${columnPrice ? `&ColumnPrice=${columnPrice}` : ""}${isDeleted === 'true' ? `&IsDeleted=${isDeleted}` : isDeleted === 'false' ? `&IsDeleted=${isDeleted}` : ""}`, {
                headers: {Authorization: `bearer ${token}`},
            })
            .then(({data: {data, totalCount}}) => {
                setIsLoading(false);
                setGroups(data);
                setIsError(false);
                setTotalCount(totalCount)
            })
            .catch(() => {
                setIsLoading(false);
                setGroups([]);
                setIsError(true);
            });
    };

    const onGroupChoose = (id) => {
        return axios.get(`${process.env.REACT_APP_API_URL}products/id?id=${id}`, {
            headers: {Authorization: `bearer ${token}`},
        });
    };

    const filterInputsUpdate = (e) => {
        const updateFiltersInput = updateObject(filterInputs, {
            [e.target.name]: e.target.value
        });

        setFilterInputs(updateFiltersInput);
        setSkip(0);
        // setSubmit(true)
    };

    useEffect(() => {
        const getAllTimer = setTimeout(() => {
            onGroups({...filterInputs, skip, take, orderBy, sortField});
        }, 500)

        return () => {
            clearTimeout(getAllTimer)
        }
    }, [filterInputs, skip, take, orderBy, sortField]);

    return (
        <div className="row bg-white rounded">
            <div className="col-12 d-flex align-items-center py-4">
                <h1 className="display-4 mb-0">Məhsullar</h1>
                <FormControlLabel
                    control={
                        <Switch
                            checked={showFilter}
                            onChange={handleChange}
                            color="secondary"
                        />
                    }
                    // label={showFilter ? "Filtri gizlət" : "Filtri göstər"}
                    label="Filter"
                    className="ml-auto mb-0"
                />
            </div>
            <Divider className="w-100"/>
            <DataTables
                groups={groups}
                isLoading={isLoading}
                isError={isError}
                setChooseGroupId={setChooseGroupId}
                setShowCreate={setShowCreate}
                setShowRemove={setShowRemove}
                setShowEdit={setShowEdit}
                setDeleteGroupsIds={setDeleteGroupsIds}
                deleteGroupsIds={deleteGroupsIds}
                sortField={sortField}
                setSortField={setSortField}
                setOrderBy={setOrderBy}
                setSkip={setSkip}
                setTake={setTake}
                take={take}
                orderBy={orderBy}
                totalCount={totalCount}
            />
            {showCreate && (
                <Create
                    show={showCreate}
                    onHide={setShowCreate}
                    onGroups={onGroups}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                    filterInputs={filterInputs}
                />
            )}

            {showEdit && (
                <Edit
                    show={showEdit}
                    onHide={setShowEdit}
                    onGroups={onGroups}
                    chooseGroupId={chooseGroupId}
                    onGroupChoose={onGroupChoose}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                />
            )}

            {showRemove && (
                <Delete
                    id={chooseGroupId}
                    show={showRemove}
                    onHide={setShowRemove}
                    onGroups={onGroups}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                    filterInputs={filterInputs}
                />
            )}
            <Filter
                show={showFilter}
                onHide={handleChange}
                filterInputsUpdate={filterInputsUpdate}
                filterInputs={filterInputs}
                resetFilter={handleReset}
            />
        </div>
    );
}

export default Products;
