import React, { useEffect, useState } from "react";
import DataTables from "./components/CustomersTable";
import Filter from "./modals/Filter";
import Divider from "@material-ui/core/Divider/index";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { objectToQueryString } from "../../../_metronic/_helpers";
import { updateObject } from "../../../helpers/updateObject";
import CountPermissions from "./modals/CountPermissions";
import ChangeBirthDate from "./modals/ChangeBirthDate";
import RenderIf from "app/components/RenderIf";


function Products() {
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [sortField, setSortField] = useState("registerDate");
  const [orderBy, setOrderBy] = useState(false);
  const [groups, setGroups] = useState([]);
  const [chooseGroupId, setChooseGroupId] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [deleteGroupsIds, setDeleteGroupsIds] = useState([]);

  const [showCreate, setShowCreate] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showChangeBirthDate, setShowChangeBirthDate] = useState(false);
  const [birthDate, setBirthDate] = useState("");

  const [filterInputs, setFilterInputs] = useState({
    phoneNumber: "",
    lastLoginDate: "",
    registerDate: "",
    isActive: "",
    confirmed: "",
    birthDate: ""
  });

  const handleReset = () => {
    setFilterInputs({
      phoneNumber: "",
      lastLoginDate: "",
      registerDate: "",
      isActive: "",
      confirmed: "",
      birthDate: ""
    });
  };

  function handleChange() {
    setShowFilter((key) => !key);
  }
  function handleChangeCount() {
    setShowCreate((key) => !key);
  }

  const onGroups = (filter = {}) => {

   const query = objectToQueryString(filter);
    setIsLoading(true);
    axios
      .get(`customers/getAll?${query}`)
      .then(({ data: { data, count } }) => {
        setIsLoading(false);
        setGroups(data);
        setIsError(false);
        setTotalCount(count);
      })
      .catch(() => {
        setIsLoading(false);
        setGroups([]);
        setIsError(true);
      });
  };

  const filterInputsUpdate = (e) => {
    const updateFiltersInput = updateObject(filterInputs, {
      [e.target.name]: e.target.value,
    });

    setFilterInputs(updateFiltersInput);
    setSkip(0);
  };

  useEffect(() => {
    const getAllTimer = setTimeout(() => {
      onGroups({ ...filterInputs, skip, take, orderBy, sortField });
    }, 500);

    return () => {
      clearTimeout(getAllTimer);
    };
  }, [filterInputs, skip, take, orderBy, sortField]);

  return (
    <div className="row bg-white rounded">
      <div className="col-12 d-flex align-items-center py-4">
        <h1 className="display-4 mb-0">Müştərilər</h1>
        <FormControlLabel
          control={
            <Switch
              checked={showFilter}
              onChange={handleChange}
              color="secondary"
            />
          }
          label="Filter"
          className="ml-auto mb-0"
        />
      </div>
      <Divider className="w-100" />
      <DataTables
        groups={groups}
        onGroups={onGroups}
        isLoading={isLoading}
        isError={isError}
        setChooseGroupId={setChooseGroupId}
        setShowCreate={setShowCreate}
        setShowChangeBirthDate={setShowChangeBirthDate}
        setDeleteGroupsIds={setDeleteGroupsIds}
        deleteGroupsIds={deleteGroupsIds}
        sortField={sortField}
        setSortField={setSortField}
        setOrderBy={setOrderBy}
        setSkip={setSkip}
        skip={skip}
        setTake={setTake}
        take={take}
        orderBy={orderBy}
        totalCount={totalCount}
        setBirthDate={setBirthDate}
      />

      <Filter
        show={showFilter}
        onHide={handleChange}
        filterInputsUpdate={filterInputsUpdate}
        filterInputs={filterInputs}
        resetFilter={handleReset}
        setFilterInputs={setFilterInputs}
      />
      <RenderIf condition={showCreate}>
        <CountPermissions
          chooseGroupId={chooseGroupId}
          show={showCreate}
          onHide={handleChangeCount}
          onGroups={onGroups}
          filterInputsUpdate={filterInputsUpdate}
          filterInputs={filterInputs}
          resetFilter={handleReset}
          skip={skip}
          take={take}
          orderBy={orderBy}
        />
      </RenderIf>
      <RenderIf condition={showChangeBirthDate}>
        <ChangeBirthDate
          show={showChangeBirthDate}
          onHide={setShowChangeBirthDate}
          customerId={chooseGroupId}
          birthDate={birthDate}
          skip={skip}
          take={take}
          orderBy={orderBy}
          sortField={sortField}
          onGroups={onGroups}
          filterInputs={filterInputs}
        />
      </RenderIf>
    </div>
  );
}

export default Products;
