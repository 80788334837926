import React, {useState} from "react";
import axios from "axios";
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import {Tooltip} from "@material-ui/core";

export default function Toggle({status, onChangeFrozenStatus}) {
    const [isFrozen, setIsFrozen] = useState(status);
    const [isLoading, setIsLoading] = useState(false);

    async function handleChangeFrozenStatus() {
        setIsLoading(true);
        try {
            await onChangeFrozenStatus();
            setIsFrozen(val => !val);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
            setIsLoading(false);
        }
    }

    return (
        <Tooltip title={isFrozen ? "Bərpa et" : "Dondur"} placement="top" id="frozenStatus">
            <button disabled={isLoading} onClick={handleChangeFrozenStatus} className="btn btn-sm btn-icon btn-bg-light btn-text-dark btn-hover-secondary mr-2">
                {isFrozen ? <PlayCircleFilledIcon/> : <PauseCircleFilledIcon/>}
            </button>
        </Tooltip>
    )
}