import React, {useState, useEffect, useCallback} from "react";
import DataTables from "./components/TicketsTables";
import Filter from "./modals/Filter";
import View from "./modals/View";
import Reprint from "./modals/Reprint";
import Divider from "@material-ui/core/Divider/index";
import {objectToQueryString} from "../../../_metronic/_helpers";
import RenderIf from "app/components/RenderIf";
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";
import {Button} from "react-bootstrap";
import {getTickets} from "./requests";
import axios from "axios";
import Remove from "./modals/Remove";

const initialFilter = {
    TicketNumber: "",
    ticketNumber: "",
    winningPaidStatus: "",
    correctCount: "",
    pendingStatusId: "",
    createdDate: "",
    winningAmount: "",
    circulationNumber: "",
    statusId: "",
    IsPaid: null,
    paid: "",
    price: "",
    agentNumber: "",
    customerNumber: "",
    typeId: "",
    IsMulti: "",
    productName: "",
    times: ""
};

function BronTickets() {
    const [groups, setGroups] = useState([]);
    const [totalCount, setTotalCount] = useState("");
    const [wonCount] = useState("");
    const [sortField, setSortField] = useState("createdDate");
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [circulationId, setCirculationId] = useState("");
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [orderBy, setOrderBy] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [showView, setShowView] = useState(false);
    const [showReprint, setShowReprint] = useState(false);
    const [ticketId, setTicketId] = useState();
    const [ticketIdRemove, setTicketIdRemove] = useState();
    const [ticketInformation, setTicketInformation] = useState({});
    const [filterInputs, setFilterInputs] = useState(initialFilter);

    const handleReset = () => {
        setFilterInputs(initialFilter);
    };

    function handleChange() {
        setShowFilter((key) => !key);
    }

    function handleChangeRomve() {
        setShowRemove((key) => !key);
    }

    const filterInputsUpdate = useCallback(
        (e) => {
            const {name, value} = e.target;

            setFilterInputs((prevFilterInputs) => ({
                ...prevFilterInputs,
                [name]: value,
            }));
            setSkip(0);
        },
        [setFilterInputs, setSkip]
    );

    const onSetTicketInformation = useCallback((ticketInformation) => {
        setTicketInformation(ticketInformation);
    }, []);

    const onGroups = (filter = {}) => {
        const {
            ticketId,
            ticketNumber,
            winningPaidStatus,
            correctCount,
            pendingStatusId,
            createdDate,
            winningAmount,
            circulationNumber,
            statusId,
            paid,
            price,
            agentNumber,
            customerNumber,
            skip,
            take,
            orderBy,
            sortField,
            IsPaid,
            typeId,
            IsMulti,
            productName,
            times
        } = filter;

        setIsLoading(true);
        const query = objectToQueryString({
            skip,
            take,
            orderBy,
            ticketNumber,
            ticketId,
            winningPaidStatus,
            correctCount,
            pendingStatusId,
            createdDate,
            winningAmount,
            circulationCode: circulationNumber,
            statusId,
            IsPaid,
            paid,
            price,
            agentNumber,
            customerNumber,
            sortField,
            type: typeId,
            IsMulti,
            productName,
            times
        });
        getTickets(query)
            .then(({data: {data, totalCount}}) => {
                setIsLoading(false);
                setGroups(data);
                setTotalCount(totalCount);
                setIsError(false);
            })
            .catch(() => {
                setIsLoading(false);
                setGroups([]);
                setIsError(true);
            });
    };

    const onCirculation = (event) => {
        setCirculationId(event.target.value);
    };

    async function getExcel() {
        const {
            ticketNumber,
            ticketId,
            winningPaidStatus,
            correctCount,
            pendingStatusId,
            createdDate,
            winningAmount,
            circulationNumber,
            statusId,
            paid,
            price,
            agentNumber,
            customerNumber,
            IsPaid,
            sortField,
            typeId,
            IsMulti,
            productName,
            times
        } = filterInputs;
        const query = objectToQueryString({
            ticketNumber,
            ticketId,
            winningPaidStatus,
            correctCount,
            pendingStatusId,
            createdDate,
            winningAmount,
            circulationCode: circulationNumber,
            statusId,
            IsPaid,
            paid,
            price,
            agentNumber,
            customerNumber,
            sortField,
            type: typeId,
            IsMulti,
            productName,
            times
        });
        try {
            const {data} = await axios({
                url: `voidTickets/filterExcel?${query}`,
                method: "GET",
                responseType: "blob",
            });
            const blob = new Blob([data], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "BronTickets.xlsx";
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (err) {
        }
    }

    useEffect(() => {
        const getAllTimer = setTimeout(() => {
            onGroups({...filterInputs, skip, take, orderBy, sortField});
        }, 500);

        return () => {
            clearTimeout(getAllTimer);
        };
    }, [skip, take, filterInputs, orderBy, sortField]);

    return (
        <div className="row bg-white rounded">
            <div className="col-12 d-flex align-items-center py-4">
                <h1 className="display-4 mb-0">Bron Et Kuponu</h1>
            </div>
            <Divider className="w-100"/>
            <div className="col-12 d-flex flex-wrap align-items-center py-4">
                <TextField
                    name="ticketNumber"
                    value={filterInputs.ticketNumber}
                    onChange={filterInputsUpdate}
                    label="Kupon nömrəsi"
                    placeholder="Kupon nömrəsi"
                    variant="outlined"
                    className="mx-2 my-2"
                />
                <TextField
                    name="price"
                    value={filterInputs.price}
                    onChange={filterInputsUpdate}
                    label="Kupon dəyəri"
                    placeholder="Kupon dəyəri"
                    variant="outlined"
                    className="mx-2 my-2"
                />
                <TextField
                    name="productName"
                    value={filterInputs.productName}
                    onChange={filterInputsUpdate}
                    label="Məhsul"
                    placeholder="Məhsul"
                    variant="outlined"
                    className="mx-2 my-2"
                />
                <TextField
                    name="circulationNumber"
                    value={filterInputs.circulationNumber}
                    onChange={filterInputsUpdate}
                    label="Tur nömrəsi"
                    placeholder="Tur nömrəsi"
                    variant="outlined"
                    className="mx-2 my-2"
                />
                <TextField
                    name="customerNumber"
                    value={filterInputs.customerNumber}
                    onChange={filterInputsUpdate}
                    label="Müştəri Nömrəsi"
                    placeholder="Müştəri Nömrəsi"
                    variant="outlined"
                    className="mx-2 my-2"
                />
                <TextField
                    type="date"
                    name="createdDate"
                    value={filterInputs.createdDate}
                    onChange={filterInputsUpdate}
                    label="Yaradılma tarixi"
                    placeholder="Yaradılma tarixi"
                    variant="outlined"
                    className="mx-2 my-2"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <FormControl className="w-150px  mx-2 my-2">
                    <InputLabel variant="outlined" id="Bilet Tipi">
                        Bilet Tipi
                    </InputLabel>
                    <Select
                        label="Bilet Tipi"
                        placeholder="Bilet Tipi"
                        variant="outlined"
                        id="IsMulti"
                        labelId="IsMulti"
                        value={filterInputs.IsMulti}
                        name="IsMulti"
                        onChange={filterInputsUpdate}
                    >
                        <MenuItem value={true}>Çox variantlı</MenuItem>
                        <MenuItem value={false}>Tək variantlı</MenuItem>
                    </Select>
                </FormControl>
                <FormControl className="w-150px  mx-2 my-2">
                    <InputLabel variant="outlined" id="Status">
                        Status
                    </InputLabel>
                    <Select
                        label="Status"
                        placeholder="Status"
                        variant="outlined"
                        id="IsPaid"
                        labelId="IsPaid"
                        value={filterInputs.IsPaid}
                        name="IsPaid"
                        onChange={filterInputsUpdate}
                    >
                        <MenuItem value={true}>Ödənilmişdir</MenuItem>
                        <MenuItem value={false}>Gözləmədə</MenuItem>
                    </Select>
                </FormControl>
                <FormControl className="w-100px mx-2 my-2">
                    <InputLabel variant="outlined" id="statusId">
                        Misli
                    </InputLabel>
                    <Select
                        label="Misli"
                        placeholder="Misli"
                        variant="outlined"
                        id="times"
                        labelId="times"
                        value={filterInputs.times}
                        name="times"
                        onChange={filterInputsUpdate}
                    >
                        <MenuItem value="">Hamısı</MenuItem>
                        <MenuItem value={1}>1X</MenuItem>
                        <MenuItem value={2}>2X</MenuItem>
                        <MenuItem value={3}>3X</MenuItem>
                        <MenuItem value={5}>5X</MenuItem>
                        <MenuItem value={6}>6X</MenuItem>
                        <MenuItem value={7}>7X</MenuItem>
                        <MenuItem value={8}>8X</MenuItem>
                        <MenuItem value={9}>9X</MenuItem>
                        <MenuItem value={10}>10X</MenuItem>
                    </Select>
                </FormControl>
                <FormControl className="w-150px  mx-2 my-2">
                    <InputLabel variant="outlined" id="typeId">
                        Seçim növü
                    </InputLabel>
                    <Select
                        label="Seçim növü"
                        placeholder="Seçim növü"
                        variant="outlined"
                        id="typeId"
                        labelId="typeId"
                        value={filterInputs.typeId}
                        name="typeId"
                        onChange={filterInputsUpdate}
                    >
                        <MenuItem value={0}>Manual</MenuItem>
                        <MenuItem value={1}>Popluyar</MenuItem>
                        <MenuItem value={2}>İdeal</MenuItem>
                        <MenuItem value={3}>Təsadüfi oyun</MenuItem>
                    </Select>
                </FormControl>

                <Button onClick={handleReset} className="mx-2 my-2">
                    Sıfırla
                </Button>
                <Button onClick={getExcel} variant="outline-success">
                    Download excel
                </Button>
            </div>
            <DataTables
                groups={groups}
                totalCount={totalCount}
                wonCount={wonCount}
                onGroups={onGroups}
                isLoading={isLoading}
                isError={isError}
                circulationId={circulationId}
                onCirculation={onCirculation}
                skip={skip}
                setSkip={setSkip}
                take={take}
                setTake={setTake}
                sortField={sortField}
                setOrderBy={setOrderBy}
                orderBy={orderBy}
                setSortField={setSortField}
                setShowView={setShowView}
                onSetTicketInformation={onSetTicketInformation}
                setShowReprint={setShowReprint}
                setTicketId={setTicketId}
                setCirculationId={setCirculationId}
                setShowRemove={setShowRemove}
                setTicketIdRemove={setTicketIdRemove}
            />
            <RenderIf condition={showFilter}>
                <Filter
                    show={showFilter}
                    onHide={handleChange}
                    filterInputsUpdate={filterInputsUpdate}
                    filterInputs={filterInputs}
                    resetFilter={handleReset}
                />
            </RenderIf>
            <RenderIf condition={showRemove}>
                <Remove
                    show={showRemove}
                    onHide={handleChangeRomve}
                    filterInputsUpdate={filterInputsUpdate}
                    filterInputs={filterInputs}
                    resetFilter={handleReset}
                    ticketIdRemove={ticketIdRemove}
                    skip={skip}
                    take={take}
                    onGroups={onGroups}
                    orderBy={orderBy}
                />
            </RenderIf>
            <RenderIf condition={showView}>
                <View
                    open={showView}
                    onClose={() => setShowView(false)}
                    ticketInformation={ticketInformation}
                    ticketId={ticketId}
                    circulationId={circulationId}
                />
            </RenderIf>
            <RenderIf condition={showReprint}>
                <Reprint
                    show={showReprint}
                    onHide={setShowReprint}
                    printType={ticketInformation.printType}
                    ticketId={ticketInformation.id}
                />
            </RenderIf>
        </div>
    );
}

export default BronTickets;
