import React from "react";
import {useDispatch} from "react-redux";
import { Modal, Button } from "react-bootstrap";
import {useFormik} from "formik";
import {reprint} from "../requests";
import {actions} from "../../../components/Snackbar/snackbarReducer";

export default function Reprint({onHide, show, printType, ticketId}) {
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            printType,
            ticketId
        },
        onSubmit: async (values) => {
            try {
                await reprint(values);
                dispatch(actions.setSnackbar({
                    variant: "success",
                    message: "İcazə verildi!"
                }))
                onHide(false);
            } catch {
                dispatch(actions.setSnackbar({
                    variant: "error",
                    message: "Xəta baş verdi!"
                }))
            }
        }
    })

    return (
        <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="delete">Təkrar çap icazəsi</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">Təkrar çap icazəsinin verilməsini təsdiqləyirsiniz?</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => onHide(false)}>
                        Xeyr
                    </Button>
                    <Button disabled={formik.isSubmitting} type="submit">Bəli</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
