const objectToQueryString = (obj = {}) => {
    const arr = [];
    for (const key in obj) {
        if (obj[key] || ['number', 'boolean'].includes(typeof obj[key])) {
            arr.push(`${key}=${obj[key]}`)
        }
    }
    return arr.join('&')
}

export {objectToQueryString}