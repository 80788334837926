import React from "react";
import {Modal, Button} from "react-bootstrap";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField/index";
import Select from "@material-ui/core/Select";

export default function Filter(props) {
    const {onHide, show, filterInputsUpdate, filterInputs, resetFilter} = props;
    const [role, setRole] = React.useState("");
    const [seeAmount, setSeeAmount] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [openAmount, setOpenAmount] = React.useState(false);

    const handleReset = () => {
        resetFilter();
        setRole("");
        setSeeAmount("");
    }

    function handleChange(event) {
        setRole(event.target.value);
        filterInputsUpdate(event);
    }

    function handleTotalAmount(event) {
        setSeeAmount(event.target.value);
        filterInputsUpdate(event);
    }

    function handleOpenAmount() {
        setOpenAmount(true)
    }

    function handleCloseAmount() {
        setOpenAmount(false)
    }

    function handleClose() {
        setOpen(false);
    }

    function handleOpen() {
        setOpen(true);
    }

    return (
        <Modal
            size="sm"
            show={show}
            onHide={onHide}
            aria-labelledby="create"
            centered
            className="modal-right pr-0"
        >
            <Modal.Header closeButton>
                <Modal.Title id="create">Tur filteri</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-12 pl-4 pr-4">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="code"
                                    name="code"
                                    label="Tur kodu"
                                    placeholder="Tur kodu"
                                    className="w-100"
                                    value={filterInputs.code}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <InputLabel>Turun başlama tarixi</InputLabel>
                                <TextField
                                    type="datetime-local"
                                    id="resultedDate"
                                    name="resultedDate"


                                    className="w-100"
                                    value={filterInputs.resultedDate}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <InputLabel>Turun bitmə tarixi</InputLabel>
                                <TextField
                                    type="datetime-local"
                                    id="expiredDate"
                                    name="expiredDate"
                                    className="w-100"
                                    value={filterInputs.expiredDate}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <FormControl className="w-100">
                                    <InputLabel htmlFor="role">Status</InputLabel>
                                    <Select
                                        open={open}
                                        onClose={handleClose}
                                        onOpen={handleOpen}
                                        value={role}
                                        onChange={handleChange}
                                        inputProps={{
                                            name: "status",
                                            id: "status",
                                        }}
                                    >
                                        <MenuItem value="">Hamısı</MenuItem>
                                        <MenuItem value={true}>Bitmiş</MenuItem>
                                        <MenuItem value={false}>Davam edən</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="productName"
                                    name="productName"
                                    label="Məhsul adı"
                                    placeholder="Məhsul adı"
                                    className="w-100"
                                    value={filterInputs.productName}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="totalAmount"
                                    name="totalAmount"
                                    label="Məbləğ"
                                    placeholder="Məbləğ"
                                    className="w-100"
                                    value={filterInputs.totalAmount}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <FormControl className="w-100">
                                    <InputLabel htmlFor="role">Tur məbləğinin görünməsi</InputLabel>
                                    <Select
                                        open={openAmount}
                                        onClose={handleCloseAmount}
                                        onOpen={handleOpenAmount}
                                        value={seeAmount}
                                        onChange={handleTotalAmount}
                                        inputProps={{
                                            name: "possibleSeeTotalAmount",
                                            id: "possibleSeeTotalAmount",
                                        }}
                                    >
                                        <MenuItem value="">Hamısı</MenuItem>
                                        <MenuItem value={true}>Mümkündür</MenuItem>
                                        <MenuItem value={false}>Mümkün deyil</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleReset}>
                    Sıfırla
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
